import { apiUrl } from '../helpers/urlHelper' ;
import { userService } from './userService';

class ProposalService {

  async fetch(id, handleError){
    const requestOptions = {
      headers: userService.getAuthHeader()
    }

    let result = await fetch(`${apiUrl()}/api/dcrses/${id}?projection=DcrsHomeProjection`, requestOptions)
        .then(response => response.json())
        .catch(error => handleError("Error", error));
    return result;
  }

  async errors(id, handleError){
    const requestOptions = {
      headers: userService.getAuthHeader()
    }

    let result = await fetch(`${apiUrl()}/api/dcrs/${id}/errors`, requestOptions)
        .then(response => response.json())
        .catch(error => handleError("Error", error));
    return result;
  }

  async fetchRelated(id, handleError){
    const requestOptions = {
      headers: userService.getAuthHeader()
    }

    let result = await fetch(`${apiUrl()}/api/dcrs/${id}/relatedProposals`, requestOptions)
        .then(response => response.json())
        .catch(error => handleError("Error", error));
    return result;
  }

  async fetchAccount(id, handleError){
    const requestOptions = {
      headers: userService.getAuthHeader()
    }
    let result = await fetch(`${apiUrl()}/api/accounts/${id}`, requestOptions)
        .then(response => response.json())
        .catch(error => handleError("Error", error));
    return result;
  }

  async fetchRestructuringProposal(id, handleError){
    const requestOptions = {
      headers: userService.getAuthHeader()
    }

    let result = await fetch(`${apiUrl()}/api/dcrses/${id}?projection=DcrsRestructuringProposalProjection`, requestOptions)
        .then(response => response.json())
        .catch(error => handleError("Error", error));
    return result;
  }

  async fetchRepaymentSchedule(id, handleError){
    const requestOptions = {
      headers: userService.getAuthHeader()
    }

    let result = await fetch(`${apiUrl()}/api/dcrses/${id}?projection=DcrsRepaymentScheduleProjection`, requestOptions)
        .then(response => response.json())
        .catch(error => handleError("Error", error));
    return result;
  }

  async fetchAssessment(id, handleError){
    const requestOptions = {
      headers: userService.getAuthHeader()
    }

    let result = await fetch(`${apiUrl()}/api/dcrses/${id}?projection=DcrsAssessmentProjection`, requestOptions)
        .then(response => response.json())
        .catch(error => handleError("Error", error));
    return result;
  }

  async search(idNumber, handleError) {
    const requestOptions = {
      headers: userService.getAuthHeader()
    }

    if (idNumber == '') {
      let result = await fetch(`${apiUrl()}/api/dcrses?size=10&sort=createDate,desc&projection=DcrsLightProjection`, requestOptions)
        .then(response => response.json())
        .catch(error => handleError("Error", error));
      return result._embedded.dcrses;
    } else {
      let result = await fetch(`${apiUrl()}/api/dcrses/search/findByCustomerId?searchString=${idNumber}&size=10&projection=DcrsLightProjection`, requestOptions)
        .then(response => response.json())
        .catch(error => handleError("Error", error));
      return result._embedded.dcrses;
    }
    
  }

  async searchResponseId(idNumber, handleError) {
    const requestOptions = {
      headers: userService.getAuthHeader()
    }

  let result = await fetch(`${apiUrl()}/api/dcrses/search/findByResponseId?responseId=${idNumber}&size=10&projection=DcrsLightProjection`, requestOptions)
        .then(response => response.json())
        .catch(error => handleError("Error", error));
    return result._embedded.dcrses;
  }
}

export {ProposalService};