const React = require('react');

import '../style.css'

import { ticketService } from '../services/ticketService';

import { Modal } from '../components/modal.js';

import version from '../../resources/version.json';


function Ticket(props) {
    var keyVal = 'modal_' + Math.floor(Math.random() * 100);

    //Note: Very specific naming conventions are expected as below:
    var actionButtonId = keyVal + '_actionButton';
    var emailInputId   = keyVal + '_email_input';
    var subjectInputId = keyVal + '_subject_input';
    var textAreaId     = keyVal + '_textArea';

    function handleActionButtonSuccess() {
        let actionButtonElement = document.getElementById(actionButtonId);
        if (!actionButtonElement) { return; }

        actionButtonElement.textContent = 'Sent thank you';
        actionButtonElement.classList.remove('basa_button');
        actionButtonElement.classList.add('basa_button_success');
    }

    function handleActionButtonError() {
        let actionButtonElement = document.getElementById(actionButtonId);
        if (!actionButtonElement) { return; }

        actionButtonElement.textContent = 'Service Unavailable';
        actionButtonElement.classList.remove('basa_button');
        actionButtonElement.classList.add('basa_button_error');
    }

    function invokeActionButton() {
        let actionButtonElement = document.getElementById(actionButtonId);
        if (!actionButtonElement) { return; }

        actionButtonElement.disabled = true;
        actionButtonElement.textContent = 'Sending...';

        let userName      = String(JSON.parse(localStorage.getItem('user'))?.username);
        let email         = document.getElementById(emailInputId)?.value;
        let subject       = document.getElementById(subjectInputId)?.value;
        let textArea      = document.getElementById(textAreaId)?.value;
        let urlInfo       = window.location.href;
        let versionNumber = version.version;
        let browserInfo   = navigator.userAgent;

        ticketService.logNew(userName, email, subject, textArea, urlInfo, versionNumber, browserInfo
            , handleActionButtonSuccess, handleActionButtonError);
    }

    function modalOn() {
        const modal = document.getElementById(keyVal);
        if (!modal) { return; }
        modal.classList.remove('opacity-0');
        modal.classList.remove('pointer-events-none');

        const body = document.querySelector('body');
        if (!body) { return; }
        body.classList.remove('modal-active');
    }

    function modalOff() {
        const modal = document.getElementById(keyVal);
        if (!modal) { return; }
        modal.classList.add('opacity-0');
        modal.classList.add('pointer-events-none');

        const body = document.querySelector('body');
        if (!body) { return; }
        body.classList.add('modal-active');
    }

    return (
        <div>
            <div className="basa_button ticket_button"
                 onClick={modalOn}>
            </div>
            <Modal
                key                 = {keyVal}
                modalKey            = {keyVal}
                header              = {'Talk to us:'}
                email               = {''}
                subject             = {'DCRSMod: '}
                content             = {'Please describe the issue below:'}
                textarea            = {''}
                showSessionInfo
                actionButton        = {'Send Message'}
                invokeActionButton  = {invokeActionButton}
                invokeModalOff      = {modalOff}>
            </Modal>
        </div>
    )
}

export { Ticket };
