import React, { useState, useEffect } from 'react';
import { ProposalService } from '../services/proposalService';
import {
    Link,
    useParams,
} from "react-router-dom";
import '../style.css'
import { userService } from '../services/userService';
import { toggleSpinnerOff, toggleSpinnerOn } from '../helpers/spinner';
import { formatCurrency } from '../helpers/numberHelper';

function Account(props) {
    let { id } = useParams();
    const authentication = props.authentication;
    const setAuthentication = props.setAuthentication;
    let totalDebt = 0;
    let totalMonthlyCommitment = 0;
    const [account, setAccount] = useState({});
    const [show, setShow] = useState(false);
    let proposal = props.proposal;

    if (!authentication.authenticated) {
        Promise.resolve(userService.checkSession())
            .then((value) => {
                setAuthentication(value)
                if (!value.authenticated) {
                    props.addNotice("Your session has expired, please login again.");
                    history.push('/');
                }
            }
            )
    } else if (!authentication.permissions.includes("CreditProvider")) {
        props.addNotice("Insufficient privileges.");
        history.push('/');
    }
    
    totalDebt = calculateTotalDebt(proposal);
    totalMonthlyCommitment = calculateTotalMonthlyCommitment(proposal);

    useEffect(() => {
        const fetchAccount = async () => {
            var response = await new ProposalService().fetchAccount(id, props.handleError).then((result) => {toggleSpinnerOff(); return result});
            setAccount(response);
            setShow(true);
        }
        toggleSpinnerOn();
        fetchAccount();
    }, []);

    function calculateTotalDebt(response) {
        return response.debtInfo.account.map((it) => it.originalBalance).reduce(add, 0);
    }

    function calculateTotalMonthlyCommitment(response) {
        var num = response.debtInfo.account.map((it) => it.deductionAmount).reduce(add, 0);
        return Math.round(num * 100) / 100;
    }

    function add(accumulator, a) {
        return accumulator + a;
    }

    return (
        <>
            {show &&
                <div className="flex-grow mx-auto divide-y divide-smoke-light p-2 text-sm">
                    <div>
                        <h1 className="basa_heading">
                            <span className="block text-basa_blue-dark xl:inline">Account Ammortisation Table - {account.accountReference} </span>
                        </h1>
                    </div>
                    <div className="grid grid-cols-3">
                        <div>
                            <div className="p-2">
                                <h1 className="small_basa_heading">
                                    <span className="block text-basa_blue-dark lg:inline">Consumer (Applicant) Details</span>
                                </h1>
                            </div>
                            <div className="p-2">
                                <table className="table-auto mt-4 overflow-scroll">
                                    <tbody>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Name:</strong></td>
                                            <td className="p-1 px-4">{proposal.customer.primaryCustomer.firstName} {proposal.customer.primaryCustomer.surname}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>ID Number:</strong></td>
                                            <td className="p-1 px-4">{proposal.customer.primaryCustomer.idNumber}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div>
                            <div className="p-2">
                                <h1 className="small_basa_heading">
                                    <span className="block text-basa_blue-dark lg:inline">Debt Counsellor</span>
                                </h1>
                            </div>
                            <div className="p-2">
                                <table className="table-auto mt-4 overflow-scroll">
                                    <tbody>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Name:</strong></td>
                                            <td className="p-1 px-4">{proposal.debtCounselorDetails.dcName}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>NCR Number:</strong></td>
                                            <td className="p-1 px-4">{proposal.debtCounselorDetails.dcncrNumber}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Telephone Number:</strong></td>
                                            <td className="p-1 px-4">{proposal.debtCounselorDetails.telAreaCode} {proposal.debtCounselorDetails.telNumber}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Fax Number:</strong></td>
                                            <td className="p-1 px-4">{proposal.debtCounselorDetails.faxAreaCode} {proposal.debtCounselorDetails.faxNumber}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Email Address:</strong></td>
                                            <td className="p-1 px-4">{proposal.debtCounselorDetails.emailAddress}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4 align-text-top"><strong>Postal Address:</strong></td>
                                            <td className="p-1 px-4">
                                                {proposal.debtCounselorDetails.dcAddressLine1}
                                                <br />
                                                {proposal.debtCounselorDetails.dcAddressLine2}
                                                <br />
                                                {proposal.debtCounselorDetails.dcAddressLine3}
                                                <br />
                                                {proposal.debtCounselorDetails.dcPostalCode}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div>
                            <div className="p-2">
                                <h1 className="small_basa_heading">
                                    <span className="block text-basa_blue-dark lg:inline">PDA Details</span>
                                </h1>
                            </div>
                            <div className="p-2">
                                <table className="table-auto mt-4 overflow-scroll">
                                    <tbody>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Name:</strong></td>
                                            <td className="p-1 px-4">{proposal.pdaDetails.pdaName}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Code:</strong></td>
                                            <td className="p-1 px-4">{proposal.pdaDetails.pdaCode}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Email Address:</strong></td>
                                            <td className="p-1 px-4">{proposal.pdaDetails.emailAddress}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-3">
                        <div className="col-span-1">
                            <div className="p-2">
                                <h1 className="small_basa_heading">
                                    <span className="block text-basa_blue-dark lg:inline">Account Details</span>
                                </h1>
                            </div>
                            <div className="p-2">
                                <table className="table-auto mt-4 overflow-scroll">
                                    <tbody>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Name:</strong></td>
                                            <td className="p-1 px-4">{account.creditProviderName}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Code:</strong></td>
                                            <td className="p-1 px-4">{account.creditProviderNCRNumber}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Open Date:</strong></td>
                                            <td className="p-1 px-4">{account.openDate}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Type:</strong></td>
                                            <td className="p-1 px-4">{account.type}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-span-2">
                            <div className="p-2">
                                <h1 className="small_basa_heading">
                                    <span className="block text-basa_blue-dark lg:inline">Debt Summary</span>
                                </h1>
                            </div>
                            <div className="p-2">
                                <table className="table-auto mt-4 overflow-scroll">
                                    <tbody>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Total Debt:</strong></td>
                                            <td className="p-1 px-4 text-right">{formatCurrency(totalDebt)}</td>

                                            <td className="p-1 px-4"><strong>1st Collection Date:</strong></td>
                                            <td className="p-1 px-4 text-right">{proposal.debtInfo.firstCollectionDate}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Total Montly Commitment:</strong></td>
                                            <td className="p-1 px-4 text-right">{formatCurrency(totalMonthlyCommitment)}</td>

                                            <td className="p-1 px-4"><strong>Estimated Date Of Distribution To Preferrential Creditors:</strong></td>
                                            <td className="p-1 px-4 text-right">{proposal.debtInfo.firstDistributionDate}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Affordability After CLI, DC &amp; PDA Fees :</strong></td>
                                            <td className="p-1 px-4 text-right">{formatCurrency(proposal.generalInfo.affordability.assessmentSummary.grossDebtAffordability)}</td>

                                            <td className="p-1 px-4"><strong>Estimated Date Of Distribution To Concurrent Creditors:</strong></td>
                                            <td className="p-1 px-4 text-right">{proposal.debtInfo.firstCPDistributionDate}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Over Indebtness %:</strong></td>
                                            <td className="p-1 px-4 text-right">{Math.round(totalMonthlyCommitment / proposal.generalInfo.affordability.assessmentSummary.grossDebtAffordability * 100) / 100} </td>

                                            <td className="p-1 px-4"><strong>Delay In Distributions :</strong></td>
                                            <td className="p-1 px-4 text-right">{proposal.debtInfo.interimPeriod}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Method of Payment :</strong></td>
                                            <td className="p-1 px-4 text-right">{proposal.debtInfo.methodOfPayment} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-3">
                        <div className="col-span-3">
                            <div className="p-2">
                                <h1 className="small_basa_heading">
                                    <span className="block text-basa_blue-dark lg:inline">Amortisation Table</span>
                                </h1>
                            </div>
                            <div className="p-2">
                                <table className="table-auto overflow-scroll">
                                    <thead>
                                        <tr>
                                            <th className="px-1">Opening Balance</th>
                                            <th className="px-1">Term</th>
                                            <th className="px-1">Start Date</th>
                                            <th className="px-1">End Date</th>
                                            <th className="px-1">Rate</th>
                                            <th className="px-1">Deduction</th>
                                            <th className="px-1">Insurance</th>
                                            <th className="px-1">Service Fees</th>
                                            <th className="px-1">Total Instalments</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {account.restructuredDebt.repaymentSchedule.sort((a, b) => new Date(a.startDate) - new Date(b.startDate)).map((schedule, i) =>
                                            <tr key={`${proposal.id}_schedule_account_dcrs_${i}_proposal`} className="border-b-2 border-gray-300 text-basa_gray">
                                                <td className="pr-1 text-right">{formatCurrency(schedule.startingBalance)}</td>
                                                <td className="pr-1 text-right">{schedule.period}</td>
                                                <td className="pr-1 text-right" >{schedule.startDate}</td>
                                                <td className="pr-1 text-right" >{schedule.endDate}</td>
                                                <td className="pr-1 text-right" >{schedule.rate} %</td>
                                                <td className="pr-1 text-right" >{formatCurrency(schedule.deduction)}</td>
                                                <td className="pr-1 text-right" >{formatCurrency(schedule.monthlyPremium)}</td>
                                                <td className="pr-1 text-right" >{formatCurrency(schedule.fee)}</td>
                                                <td className="pr-1 text-right" >{formatCurrency(Math.round(schedule.deduction * schedule.period * 100) / 100)}</td>
                                            </tr>
                                        )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="p-2">
                        <h1 className="small_basa_heading">
                            <span className="block text-basa_blue-dark lg:inline">Actions</span>
                        </h1>
                        <div className="pt-1 flex">
                            <div className="flex my-2">
                                <Link to={`/dcrs/${proposal.id}/restructuring_proposal`} className="group relative justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-basa_blue hover:bg-basa_gray-light hover:text-basa_blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 mr-1">
                                    Back
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export { Account };