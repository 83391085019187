import { apiUrl } from '../helpers/urlHelper';
import { authHeader } from '../helpers/auth-header';

export const userService = {
    login,
    logout,
    getAll,
    getAuthHeader,
    search,
    create,
    checkSession,
    getUser,
    update,
    requestReset,
    updatePassword
};

function updatePassword(passwordParameters) {
    var headers = {};
    headers['Content-Type'] = 'application/json'
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            "email": passwordParameters.username,
            "newPassword": passwordParameters.password,
            "token": passwordParameters.token
        })
    };
    return fetch(`${apiUrl()}/api/updatePassword`, requestOptions).then(handleResponse);
}

function requestReset(email) {
    var headers = {};
    headers['Content-Type'] = 'application/json'
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            "email": email
        })
    };
    return fetch(`${apiUrl()}/api/passwordReset`, requestOptions).then(handleResponse);
}

function login(username, password) {
    const authHeaderConst = { 'Authorization': `Basic ${window.btoa(username + ':' + password)}`, 'X-Requested-With' : 'XMLHttpRequest' };
    const requestOptions = {
        method: 'POST',
        headers: authHeaderConst,
        body: JSON.stringify({})
    };
    return fetch(`${apiUrl()}/session`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a user in the response
            if (!user) {
                return null;
            }

            // store user details and basic auth credentials in local storage
            // to keep user logged in between page refreshes
            user.authdata = window.btoa(username + ':' + password);
            localStorage.setItem('user', JSON.stringify(user));

            return user;
        });
}

function loginWithAuthData(authdata) {
    const authHeaderConst = { 'Authorization': `Basic ${authdata}` };
    const requestOptions = {
        method: 'POST',
        headers: authHeaderConst,
        body: JSON.stringify({})
    };

    return fetch(`${apiUrl()}/session`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a user in the response
            if (user) {
                // store user details and basic auth credentials in local storage
                // to keep user logged in between page refreshes
                user.authdata = authdata;
                localStorage.setItem('user', JSON.stringify(user));
            }

            return user;
        });
}

function checkSession() {
    var user = JSON.parse(localStorage.getItem('user'));
    if (user) {
        return loginWithAuthData(user.authdata);
    }
    return {};
}

function logout() {
    localStorage.removeItem('user');
}

function getAuthHeader() {
    var basicToken = `Basic ${JSON.parse(localStorage.getItem('user')).authdata}`;
    return { 'Authorization': basicToken };
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${apiUrl()}/api/users`, requestOptions).then(handleResponse);
}

function search(searchTerm) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${apiUrl()}/api/users/search/searchUserByTerm?searchTerm=${searchTerm}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function create(user) {
    const headers = authHeader()
    headers['Content-Type'] = 'application/json'

    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(user)
    }

    var result = fetch(`${apiUrl()}/api/users`, requestOptions)
        .then(response => response.json())
        .catch(error => handleError("Error", error));

    return result;
}

function getUser(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${apiUrl()}/api/users/${id}`, requestOptions).then(handleResponse);
}

function update(id, user, handleError) {
    const headers = authHeader();
    headers['Content-Type'] = 'application/json'

    const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify(user)
    }

    var result = fetch(`${apiUrl()}/api/users/${id}`, requestOptions)
        .then(response => response.json())
        .catch(error => handleError("Error", error));

    return result;
}