import React, { useState, useEffect } from 'react';
import {
    Link,
    useParams,
} from "react-router-dom";
import { userService } from '../services/userService';
import { ProposalService } from '../services/proposalService';
import { fileService } from '../services/fileService';
import '../style.css'
import { toggleSpinner } from '../helpers/spinner';
import { formatCurrency } from '../helpers/numberHelper';


function PaymentPlan(props) {
    let { id } = useParams();
    const [proposal, setProposal] = useState({});
    const [show, setShow] = useState(false);
    const authentication = props.authentication;
    const setAuthentication = props.setAuthentication;
    const [totalDebt, setTotalDebt] = useState(0);
    const [totalMonthlyCommitment, setTotalMonthlyCommitment] = useState(0);
    const [schedule, setSchedule] = useState([]);

    if (!authentication.authenticated) {
        Promise.resolve(userService.checkSession())
            .then((value) => {
                setAuthentication(value)
                if (!value.authenticated) {
                    props.addNotice("Your session has expired, please login again.");
                    history.push('/');
                }
            }
            )
    } else if (!authentication.permissions.includes("CreditProvider")) {
        props.addNotice("Insufficient privileges.");
        history.push('/');
    }

    useEffect(() => {
        const fetchProposal = async () => {
            var response = await new ProposalService().fetchRepaymentSchedule(id, props.handleError).then((result) => {toggleSpinner(); return result});
            setProposal(response);
            setTotalDebt(calculateTotalDebt(response));
            setTotalMonthlyCommitment(calculateTotalMonthlyCommitment(response));
            setSchedule(buildSchedule(response.debtInfo.account));
            setShow(true);
        }
        toggleSpinner();
        fetchProposal();
    }, []);

    function buildSchedule(accounts) {
        return accounts.flatMap((account) => {
            return account.restructuredDebt.repaymentSchedule.sort((a, b) => new Date(a.startDate) - new Date(b.startDate)).flatMap((_it) => {
                _it.creditProviderName = account.creditProviderName;
                _it.accountReference = account.accountReference;
                _it.subAccountReference = account.subAccountReference;
                _it.type = account.type;
                return _it;
            });
        });
    }

    function calculateTotalDebt(response) {
        return response.debtInfo.account.map((account) => account.originalBalance).reduce(add, 0);
    }

    function calculateTotalMonthlyCommitment(response) {
        var num = response.debtInfo.account.map((account) => account.deductionAmount).reduce(add, 0);
        return Math.round(num * 100) / 100;
    }

    function add(accumulator, a) {
        return accumulator + a;
    }

    return (
        <>
            {show &&
                <div className="flex-grow mx-auto divide-y divide-smoke-light p-2 text-sm">
                    <div>
                        <h1 className="basa_heading">
                            <span className="block text-basa_blue-dark xl:inline">Payment Plan</span>
                        </h1>
                    </div>
                    <div className="grid grid-cols-3">
                        <div>
                            <div className="p-2">
                                <h1 className="small_basa_heading">
                                    <span className="block text-basa_blue-dark lg:inline">Consumer (Applicant) Details</span>
                                </h1>
                            </div>
                            <div className="p-2">
                                <table className="table-auto mt-4 overflow-scroll">
                                    <tbody>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Name:</strong></td>
                                            <td className="p-1 px-4">{proposal.customer.primaryCustomer.firstName} {proposal.customer.primaryCustomer.surname}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>ID Number:</strong></td>
                                            <td className="p-1 px-4">{proposal.customer.primaryCustomer.idNumber}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-span-2">
                            <div className="p-2">
                                <h1 className="small_basa_heading">
                                    <span className="block text-basa_blue-dark lg:inline">Debt Summary</span>
                                </h1>
                            </div>
                            <div className="p-2">
                                <table className="table-fixed mt-4 overflow-scroll">
                                    <tbody>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Total Debt:</strong></td>
                                            <td className="p-1 px-4 text-right">{formatCurrency(totalDebt)}</td>

                                            <td className="p-1 px-4"><strong>1st Collection Date:</strong></td>
                                            <td className="p-1 px-4 text-right w-1/5">{proposal.debtInfo.firstCollectionDate}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Total Monthly Commitment:</strong></td>
                                            <td className="p-1 px-4 text-right">{formatCurrency(totalMonthlyCommitment)}</td>

                                            <td className="p-1 px-4"><strong>Estimated Date Of Distribution To Preferrential Creditors:</strong></td>
                                            <td className="p-1 px-4 text-right">{proposal.debtInfo.firstDistributionDate}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Affordability After CLI, DC &amp; PDA Fees :</strong></td>
                                            <td className="p-1 px-4 text-right">{formatCurrency(proposal.generalInfo.affordability.assessmentSummary.grossDebtAffordability)}</td>

                                            <td className="p-1 px-4"><strong>Estimated Date Of Distribution To Concurrent Creditors:</strong></td>
                                            <td className="p-1 px-4 text-right">{proposal.debtInfo.firstCPDistributionDate}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Over Indebtness %:</strong></td>
                                            <td className="p-1 px-4 text-right">{formatCurrency(Math.round(totalMonthlyCommitment / proposal.generalInfo.affordability.assessmentSummary.grossDebtAffordability * 100) / 100)} </td>

                                            <td className="p-1 px-4"><strong>Delay In Distributions</strong></td>
                                            <td className="p-1 px-4 text-right">{proposal.debtInfo.interimPeriod}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Method of Payment</strong></td>
                                            <td className="p-1 px-4 text-right">{proposal.debtInfo.methodOfPayment} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-3">
                        <div>
                            <div className="p-2">
                                <h1 className="small_basa_heading">
                                    <span className="block text-basa_blue-dark lg:inline">Enquiry Details</span>
                                </h1>
                            </div>
                            <div className="p-2">
                                <table className="table-auto mt-4 overflow-scroll">
                                    <tbody>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>DC Reference Number:</strong></td>
                                            <td className="p-1 px-4">{proposal.session.uniqueReference}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Request Type:</strong></td>
                                            <td className="p-1 px-4">{proposal.session.requestType}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Date:</strong></td>
                                            <td className="p-1 px-4">{proposal.createDate.split('T')[0]}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Time:</strong></td>
                                            <td className="p-1 px-4">{proposal.createDate.split('T')[1]}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Legacy Plan:</strong></td>
                                            <td className="p-1 px-4">{`${proposal.migrated}`}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Rule Set Used:</strong></td>
                                            <td className="p-1 px-4">{proposal.session.version}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div>
                            <div className="p-2">
                                <h1 className="small_basa_heading">
                                    <span className="block text-basa_blue-dark lg:inline">Debt Counsellor</span>
                                </h1>
                            </div>
                            <div className="p-2">
                                <table className="table-auto mt-4 overflow-scroll">
                                    <tbody>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Name:</strong></td>
                                            <td className="p-1 px-4">{proposal.debtCounselorDetails.dcName}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>NCR Number:</strong></td>
                                            <td className="p-1 px-4">{proposal.debtCounselorDetails.dcncrNumber}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Telephone Number:</strong></td>
                                            <td className="p-1 px-4">{proposal.debtCounselorDetails.telAreaCode} {proposal.debtCounselorDetails.telNumber}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Fax Number:</strong></td>
                                            <td className="p-1 px-4">{proposal.debtCounselorDetails.faxAreaCode} {proposal.debtCounselorDetails.faxNumber}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Email Address:</strong></td>
                                            <td className="p-1 px-4">{proposal.debtCounselorDetails.emailAddress}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4 align-text-top"><strong>Postal Address:</strong></td>
                                            <td className="p-1 px-4">
                                                {proposal.debtCounselorDetails.dcAddressLine1}
                                                <br />
                                                {proposal.debtCounselorDetails.dcAddressLine2}
                                                <br />
                                                {proposal.debtCounselorDetails.dcAddressLine3}
                                                <br />
                                                {proposal.debtCounselorDetails.dcPostalCode}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div>
                            <div className="p-2">
                                <h1 className="small_basa_heading">
                                    <span className="block text-basa_blue-dark lg:inline">PDA Details</span>
                                </h1>
                            </div>
                            <div className="p-2">
                                <table className="table-auto mt-4 overflow-scroll">
                                    <tbody>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Name:</strong></td>
                                            <td className="p-1 px-4">{proposal.pdaDetails.pdaName}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Code:</strong></td>
                                            <td className="p-1 px-4">{proposal.pdaDetails.pdaCode}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-4"><strong>Email Address:</strong></td>
                                            <td className="p-1 px-4">{proposal.pdaDetails.emailAddress}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-3">
                        <div className="col-span-3">
                            <div className="p-2">
                                <h1 className="small_basa_heading">
                                    <span className="block text-basa_blue-dark lg:inline">Plan</span>
                                </h1>
                            </div>
                            <div className="p-2">
                                <table className="table-fixed overflow-scroll">
                                    <thead>
                                        <tr>
                                            <th className="w-40 px-1">Creditor Name</th>
                                            <th className="w-6 px-1">Account Type</th>
                                            <th className="w-40 px-1">Account Reference</th>
                                            <th className="w-16 px-1">Amount</th>
                                            <th className="w-40 px-1">Creditor Agreement Account Number</th>
                                            <th className="w-6 px-1">Period</th>
                                            <th className="w-24 px-1">From Date</th>
                                            <th className="w-24 px-1">To Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {schedule.map((it, i) =>
                                            <tr key={`schedule_account_dcrs_${i}_proposal`} className="border-b-2 border-gray-300 text-basa_gray">
                                                <td className="border-l border-r px-2">{it.creditProviderName}</td>
                                                <td className="border-l border-r px-2 text-center">{it.type}</td>
                                                <td className="border-l border-r px-2">{it.accountReference}</td>
                                                <td className="border-l border-r px-2 text-right">{formatCurrency(it.deduction + it.monthlyPremium)}</td>
                                                <td className="border-l border-r px-2 text-right">{it.subAccountReference}</td>
                                                <td className="border-l border-r px-2 text-right">{it.period}</td>
                                                <td className="border-l border-r px-2">{it.startDate}</td>
                                                <td className="border-l border-r px-2">{it.endDate}</td>
                                            </tr>
                                        )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="p-2">
                        <h1 className="small_basa_heading">
                            <span className="block text-basa_blue-dark lg:inline">Actions</span>
                        </h1>
                        <div className="pt-1 flex">
                            <div className="flex my-2">
                                <Link to={`/dcrs/${id}/assessment_summary`} className="basa_button">Assessment Summary</Link>
                            </div>
                            <div className="flex my-2">
                                <Link to={`/dcrs/${id}/restructuring_proposal`} className="basa_button">Restructuring Proposal</Link>
                            </div>
                            <div className="flex my-2">
                                <span onClick={() => downloadPaymentPlan(proposal, id)} className="basa_button">
                                    Download
                                </span>
                            </div>
                            <div className="flex my-2">
                                <Link to={`/dcrs/${id}`} className="basa_button">
                                    Back
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </>
    )
}

function downloadPaymentPlan(proposal, id) {
    toggleSpinner();
    fileService.fetchPaymentPlanFile(id)
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `payment_plan_${proposal.session.uniqueReference}.pdf`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);

            toggleSpinner();
        });
}

export { PaymentPlan };