import React, { useState, useEffect, useRef } from 'react';
import { toggleSpinnerOn, toggleSpinnerOff } from '../helpers/spinner';
import { useForm, useFieldArray } from "react-hook-form";
import Zoom from 'react-awesome-reveal';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useParams,
  useRouteMatch
} from "react-router-dom";

import '../style.css'
import { reportService } from '../services/reportService';
import { userService } from '../services/userService';

function CPInvoicingReport(props) {
  const authentication = props.authentication;
  const setAuthentication = props.setAuthentication;
  const [extracts, setExtracts] = useState([]);
  const history = useHistory();
  let { path, url } = useRouteMatch();
  if (!authentication.authenticated) {
    Promise.resolve(userService.checkSession())
      .then((value) => {
        setAuthentication(value);
        if (!value.authenticated) {
          history.push('/');
        }
      })
  } else if (!authentication.permissions.includes("Reports.Extract")) {
    history.push('/app/reports');
  }

  useEffect(() => {
    fetchExtracts()
  }, []);

  return (
    <div>
      <div className='pt-4'>
        <ExtractForm fetchExtracts={fetchExtracts}/>
        {extracts.length > 0 &&
        <>  
          <h1 className="small_basa_heading">
            <span className="block text-basa_blue-dark xl:inline">Past Extracts</span>
          </h1>

          <div className="mx-auto content-center">
            <table className="table mt-4 overflow-scroll">
              <thead>
                  <tr>
                  <th>Creator</th>
                  <th>Creation Date</th>
                  <th>Name</th>
                  <th>Actions</th>
                  </tr>
              </thead>
              <tbody>
                {extracts.sort((a, b) => new Date(b.createDate) - new Date(a.createDate)).map((x, i) =>
                  <Row extract={x} key={i} path={path} url={url} />
                )
                }
              </tbody>
            </table>
          </div>
        </>
        }
      </div>
    </div>
  )

  function fetchExtracts(handleError) {
    toggleSpinnerOn();
    Promise.resolve(reportService.extractRequestsCPInvoicing(handleError))
      .then((value) => {
        setExtracts(value._embedded.extractRequests);
        toggleSpinnerOff();
      })
  }
}

function ExtractForm(props) {
  const [saved, setSaved] = useState(false);
  const [enhanced, setEnhanced] = useState(false);
  const [extractRequest, setExtractRequest] = useState([]);
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  function resetFields(extractRequest){
    reset({
      
    });
  }

  function onSubmit(extractRequest) {
    if(enhanced) {
      reportService.requestExtractCPInvoicingEnhanced();
    } else {
      reportService.requestExtractCPInvoicing();
    }
    setSaved(true);
    setTimeout(function () {
      props.fetchExtracts();
    }, 5000);
  }

  useEffect(() => {
    resetFields(extractRequest);
  },[]);

  return (
    <>
      <h2 className="small_basa_heading">
        <span className="block text-basa_blue-dark xl:inline">Credit Provider Invoicng - New Extract</span>
      </h2>

      <div className="w-2/3 mx-auto">
        {saved == true &&
          <Zoom>
            <div className="mx-auto bg-green-600 text-gray-200 rounded-md text-center items-center">
              <span>Extract Requested - Please allow a few minutes and revist this page to download the report.</span>
            </div>
          </Zoom>
        }
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-3">  
          <div className="pt-3">
          <button 
            type="submit" 
            className="mr-1 group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-basa_blue hover:bg-basa_gray-light hover:text-basa_blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
            Create Extract
          </button>

          
          </div>
          <div className="pt-3">
            <button 
              onClick={() => setEnhanced(true)}
              type="submit" 
              className="mr-1 group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-basa_blue hover:bg-basa_gray-light hover:text-basa_blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
              Create Enhanced Extract
            </button>
          </div>
        </div>
      </form>
    </>
  )
}



function Row(props) {
  return (
    <tr className="border-b-2 border-gray-300 text-basa_gray hover:text-basa_blue-darker">
    <td className="p-1 px-4">{props.extract.creator}</td>
    <td className="p-1 px-4">{props.extract?.createDate?.split('T')[0]}</td>
    <td className="p-1 px-4">{props.extract.reportName}</td>
    <td className="p-1 px-4 ">
      <span onClick={() => downloadExtract(props.extract.id, props.extract.reportName)}>Download</span>
    </td>
    </tr>
  )
}

function downloadExtract(id, name) {
  reportService.downloadExtractCPInvoicing(id)
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${name}.zip`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
  });
}
export { CPInvoicingReport };