import { ticketApiUrl } from '../helpers/urlHelper' ;

export const ticketService = {
    logNew
};

function logNew(userName, email, subject, textArea, urlInfo, versionNumber, browserInfo
                , handleSuccess, handleError) {
    var headers = {};
    headers['Content-Type'] = 'application/json'
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            'reporter':    userName,
            'email':       email,
            'title':       subject,
            'description': textArea,
            'URL':         urlInfo,
            'version':     versionNumber,
            'browser':     browserInfo
        })
    };

    fetch(ticketApiUrl(), requestOptions)
    .then(res => {
        handleSuccess();
    })
    .catch((error) => {
        handleError();
    });
}
