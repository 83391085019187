import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useParams,
  useRouteMatch
} from "react-router-dom";
import { useForm } from "react-hook-form";
import { userService } from '../services/userService';
import { roleService } from '../services/roleService';
import { CreditProviderService } from '../services/creditProviderService';
import '../style.css';
import Zoom from 'react-awesome-reveal';

function Users(props) {

  const authentication = props.authentication;
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [name, setName] = useState('');
  let { path, url } = useRouteMatch();

  if (!authentication.authenticated || !authentication.permissions.includes("User")) {
    Promise.resolve(userService.checkSession())
      .then((value) => {
        if (!value.authenticated) {
          props.addNotice("Your session has expired, please login again.");
          history.push('/');
        }
      })
  }

  useEffect(() => {
    if (name == '') {
      const fetchUsers = async () => {
        var it = await userService.getAll(props.handleError);
        // setUsers(it._embedded.dCRSUsers);
        setUsers({});
      }
      fetchUsers();
    }
  }, []);

  function searchName(searchTerm) {
    setName(searchTerm);
    Promise.resolve(userService.search(searchTerm, props.handleError))
      .then((value) =>
        setUsers(value)
      )
  }

  return (
    <Switch>
      <Route exact path={path}>
        <Search users={users} setUsers={setUsers} searchName={searchName} name={name} path={path} url={url} authentication={authentication}></Search>
      </Route>
      <Route path={`${props.url}/:it/:id`} children={<User handleError={props.handleError} authentication={authentication} />}></Route>
    </Switch >
  )
}

function User(props) {
  let { id } = useParams();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [user, setUser] = useState([]);
  const [roles, setRoles] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [creditProviders, setCreditProviders] = useState([]);
  const [saved, setSaved] = useState(false);
  var authentication = props.authentication;

  function onSubmit(it) {
    if (typeof it.id === 'undefined') {
      Promise.resolve(userService.create(it, props.handleError))
        .then((value) => {
          setSaved(true);
          id = value.id;
          setUser(value);
          resetFields(value);
        })
    } else {
      Promise.resolve(userService.update(id, it, props.handleError))
        .then((value) =>
          setSaved(true)
        )
    }
  }

  function resetFields(it, userRoles, creditProvider) {
    var cpURL = "";
    if (creditProvider.id) {
      cpURL = creditProvider._links.self.href;
    }
    reset({
      id: it.id,
      email: it.email,
      firstName: it.firstName,
      lastName: it.lastName,
      idNumber: it.idNumber,
      locked: it.locked,
      enabled: it.enabled,
      roles: userRoles._embedded.dCRSRoles.map(x => x._links.self.href),
      creditProvider: cpURL
    });
  }

  useEffect(() => {
    const fetchUser = async () => {
      roleService.getAll(props.handleError).then((all) => setAllRoles(all._embedded.dCRSRoles));
      new CreditProviderService().all(props.handleError).then((cps) => {
        setCreditProviders(cps._embedded.creditProviders)
      });
      if (id != 'new') {
        var cUser = await userService.getUser(id, props.handleError);
        setUser(cUser);
        var userRoles = await roleService.userRoles(id, props.handleError);
        setRoles(userRoles)
        var userRoles = await roleService.userRoles(id, props.handleError);
        var creditProviders = await new CreditProviderService().userCreditProvider(id)
        resetFields(cUser, userRoles, creditProviders);
      }
    }
    fetchUser();
  }, []);

  return (

    <div className="flex-grow mx-auto p-5" >
      <h1 className="basa_heading">
        <span className="block text-basa_blue-dark xl:inline">User: {user.email}</span>
      </h1>

      <div className="w-2/3 mx-auto">
        {saved == true &&
          <Zoom>
            <div className="mx-auto bg-green-600 text-gray-200 rounded-md text-center items-center">
              <span>User Saved</span>
            </div>
          </Zoom>
        }
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-3 pt-3">
          <div className="pt-3">
            <label>Email</label>
            {errors.email && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <input {...register("email", { required: true })} type="email" className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" placeholder="Email" />
          </div>

          <div className="pt-3">
            <label>First Name</label>
            {errors.firstName && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <input {...register("firstName", { required: true })} type="text" className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" placeholder="First Name" />
          </div>

          <div className="pt-3">
            <label>Last Name</label>
            {errors.lastName && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <input {...register("lastName", { required: true })} type="text" className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" placeholder="Last Name" />
          </div>

          <div className="pt-3">
            <label>ID Number</label>
            {errors.idNumber && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <input {...register("idNumber", { required: true })} type="text" className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" placeholder="text" />
          </div>

          <div className="pt-3">
            <label>Locked</label><br></br>
            <input {...register("locked", { required: false })} type="checkbox" className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" />
          </div>

          <div className="pt-3">
            <label>Enabled</label><br></br>
            <input {...register("enabled", { required: false })} type="checkbox" className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" />
          </div>
        </div>
        {authentication.authenticated == "true" && authentication.roles.includes('Admin') &&
          <div>
            <div className="pt-3">
              <label>Roles</label><br></br>
              <select {...register("roles")} multiple className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10">
                {allRoles.map((x, i) =>
                  <option value={x._links.self.href} key={x._links.self.href} >{x.name}</option>
                )
                }
              </select>
            </div>
            <div className="pt-3">
              <label>Credit Provider</label><br></br>
              <select {...register("creditProvider")} className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10">
                <option value="" key="1">N/A</option>
                {creditProviders.map((x, i) =>
                  <option value={x._links.self.href} key={x._links.self.href}>{x.name}</option>
                )
                }
              </select>
            </div>
          </div>
        }

        <div className="flex mt-2">
          <button type="submit" className="mr-1 group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-basa_blue hover:bg-basa_gray-light hover:text-basa_blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
            Submit
          </button>
          <Link to="/setup/Users" className="group relative justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-basa_blue hover:bg-basa_gray-light hover:text-basa_blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 mr-1">
            Back
          </Link>
        </div>
      </form>
    </div>
  )

}

function Search(props) {
  var users = props.users;
  var searchName = props.searchName;
  var name = props.name;
  return (
    <div className="flex-grow mx-auto pt-2" >
      <h1 className="text-l tracking-tight font-extrabold text-basa_gray sm:text-xl md:text-4xl text-center mt-3">
        <span className="block text-basa_blue-dark xl:inline">User Setup</span>
      </h1>

      <div className="divide-y divide-smoke-light p-2">
        <div className="mx-auto p-2 w-2/3">
          <input type="search" className="pb-2 mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-basa_blue-light hover:border-gray-700 hover:bg-opacity-40" placeholder="Type search details here..." value={name} onChange={e => searchName(e.target.value)} />
        </div>
        <div className="pt-2 mx-auto w-2/3">
          {users.length > 0 &&
            <div className="mx-auto content-center">
              <table className="table mt-4 overflow-scroll">
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>ID Number</th>
                    <th>Locked</th>
                    <th>Enabled</th>
                    <th>Open</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((x, i) =>
                    <Row user={x} key={i} path={props.path} url={props.url} authentication={props.authentication} />
                  )
                  }
                </tbody>
              </table>
            </div>
          }
          {users.length == 0 &&
            <div>
              <span className="text-lg text-red-800">No Users Found</span>
            </div>
          }

          <div className="flex my-2">
            <Link to="/setup/Users/new" className="group relative justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-basa_blue hover:bg-basa_gray-light hover:text-basa_blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 mr-1">
              New
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

function Row(props) {
  return (
    <tr className="border-b-2 border-gray-300 text-basa_gray hover:text-basa_blue-darker">
      <td className="p-1 px-4">{props.user.email}</td>
      <td className="p-1 px-4">{props.user.firstName}</td>
      <td className="p-1 px-4">{props.user.lastName}</td>
      <td className="p-1 px-4">{props.user.idNumber}</td>
      <td className="p-1 px-4">{props.user.locked ? '✓' : 'X'}</td>
      <td className="p-1 px-4">{props.user.enabled ? '✓' : 'X'}</td>
      <td className="p-1 px-4 ">
        <Link to={`${props.url}/${props.user.id}`}>
          <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </Link>
      </td>
    </tr>
  )
}

export { Users };