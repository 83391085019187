import React, { useState, useEffect, useRef } from 'react';
import '../style.css'
import { userService } from '../services/userService';
import { SimulationService } from '../services/simulationService';
import { toggleSpinnerOn, toggleSpinnerOff } from '../helpers/spinner';
import { useForm, useFieldArray } from "react-hook-form";
import Zoom from 'react-awesome-reveal';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useParams,
  useRouteMatch
} from "react-router-dom";


function Simulator(props) {
  const authentication = props.authentication;
  const setAuthentication = props.setAuthentication;
  let { path, url } = useRouteMatch();
  if (!authentication.authenticated) {
    Promise.resolve(userService.checkSession())
      .then((value) => {
        setAuthentication(value);
        if (!value.authenticated) {
          props.addNotice("Your session has expired, please login again.");
          history.push('/');
        }
      })
  } else if (!authentication.permissions.includes("Tools.Manage")) {
    props.addNotice("Insufficient privileges.");
    history.push('/');
  }

  return (
    <div>
      <div>
        <h1 className="basa_heading">
          <span className="block text-basa_blue-dark xl:inline">DCRS Simulator</span>
        </h1>
        <Switch>
          <Route exact path={path}>
            <SimulatorHome handleError={props.handleError} authentication={props.authentication} setAuthentication={props.setAuthentication}></SimulatorHome>
          </Route>
          <Route path={`${path}/:id`}>
            <Simulation authentication={props.authentication} setAuthentication={props.setAuthentication} addNotice={props.addNotice} handleError={props.handleError} url={url} />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

function SimulatorHome(props) {
  let { path, url } = useRouteMatch();
  const [mineOnly, setMineOnly] = useState('');
  const [simulationName, setSimulationName] = useState('');
  const [simulations, setSimulations] = useState([]);

  useEffect(() => {
    searchSimulationName('', mineOnly)
  }, []);
  
  return (
    <div className="flex-grow mx-auto pt-2" >
      <div className="p-2">
        <div className="mx-auto p-2 w-2/3">
          <input type="search" className="pb-2 mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-basa_blue-light hover:border-gray-700 hover:bg-opacity-40" placeholder="Type search details here..." value={simulationName} onChange={e => searchSimulationName(e.target.value, mineOnly, props.handleError)} />
        </div>
        <div className="mx-auto p-2 w-2/3">
          <label>Show my proposals only?</label>
          <input type="checkbox" className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" value={mineOnly} onChange={e => searchSimulationName(simulationName, e.target.checked, props.handleError)} />
        </div>
        <div className="pt-2 mx-auto w-2/3">
          {simulations.length > 0 &&
            <div className="mx-auto content-center">
              <table className="table mt-4 overflow-scroll">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Creator</th>
                    <th>Creation Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {simulations.map((x, i) =>
                    <Row simulation={x} key={i} path={path} url={url} />
                  )
                  }
                </tbody>
              </table>
            </div>
          }
          {simulations.length == 0 &&
            <div>
              <span className="text-lg text-red-800">No Simulations Found</span>
            </div>
          }

          <div className="flex my-2">
            <Link to="/tools/simulator/new" className="group relative justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-basa_blue hover:bg-basa_gray-light hover:text-basa_blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 mr-1">
              New
            </Link>
          </div>
        </div>
      </div>
    </div>
  )

  function searchSimulationName(searchTerm, mineOnly, handleError) {
    setSimulationName(searchTerm);
    setMineOnly(mineOnly);
    toggleSpinnerOn();
    Promise.resolve(new SimulationService().search(searchTerm, mineOnly, handleError))
      .then((value) => {
        setSimulations(value);
        toggleSpinnerOff();
      })
  }
}

function Row(props) {
  return (
    <tr className="border-b-2 border-gray-300 text-basa_gray hover:text-basa_blue-darker">
      <td className="p-1 px-4">{props.simulation.id}</td>
      <td className="p-1 px-4">{props.simulation.name}</td>
      <td className="p-1 px-4">{props.simulation.description}</td>
      <td className="p-1 px-4">{props.simulation.creator}</td>
      <td className="p-1 px-4">{props.simulation?.createDate?.split('T')[0]}</td>
      <td className="p-1 px-4 ">
        <Link to={`${props.url}/${props.simulation.id}`}>
          <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </Link>
      </td>
    </tr>
  )
}

function Simulation(props) {
  let { id } = useParams();
  const { register, control, handleSubmit, reset, formState: { errors } } = useForm(
    
  );
  const [simulation, setSimulation] = useState([]);
  const [dcrses, setDCRSES] = useState([]);
  const [saved, setSaved] = useState(false);
  const history = useHistory();

  function resetFields(simulation){
    reset({
      id: simulation.id,
      name: simulation.name,
      description: simulation.description,
      affordabilityAmount: simulation.affordabilityAmount,
      grossCollectable: simulation.grossCollectable,
      payday: simulation.payday,
      firstCollectionDate: simulation.firstCollectionDate,
      firstDistributionDate: simulation.firstDistributionDate,
      requestType: simulation.requestType,
      vatEnabled: simulation.vatEnabled,
      dcFee: simulation.dcFee,
      legalFee: simulation.legalFee,
      afterCareFee: simulation.afterCareFee,
      pdaFee: simulation.pdaFee,
      simulationRequestAccounts: simulation.simulationRequestAccounts,
    });
  }

  function simulate() {
    Promise.resolve(handleSubmit(onSubmit)())
      .then((value) => {
        Promise.resolve(new SimulationService().simulate(id, props.handleError))
        .then((value) => {
          history.push(`/dcrs/${value.dcrsId}`);
        })
      });
  }

  function onSubmit(data) {
    if (typeof id === 'undefined' || id == 'new') {
      return Promise.resolve(new SimulationService().create(data, props.handleError))
        .then((value) => {
          setSaved(true);
          id = value.id;
          setSimulation(value);
          resetFields(value);
          window.scrollTo(0, 0);
          return value;
        })
    } else {
      return Promise.resolve(new SimulationService().update(id, data, props.handleError))
        .then((value) => {
          setSaved(true);
          window.scrollTo(0, 0);
          return value;
        })
    }
  }

  const { fields, append, prepend, insert, remove } = useFieldArray({
    control,
    name: "simulationRequestAccounts"
  });

  useEffect(() => {
    const fetchSimulation = async () => {
      if (id != 'new') {
        var simulation = await new SimulationService().fetch(id, props.handleError);
        setSimulation(simulation);
        resetFields(simulation);
      }
    }

    const fetchDCRSES = async () => {
      if (id != 'new') {
        var response = await new SimulationService().dcrses(id, props.handleError);
        setDCRSES(response._embedded.dcrses);
      }
    }
    fetchDCRSES();
    fetchSimulation();
    
  },[]);

  return (
    <div className="flex-grow mx-auto p-5" >
      <h1 className="basa_heading">
        <span className="block text-basa_blue-dark xl:inline"> Simulation: {simulation.name}</span>
      </h1>

      <div className="w-2/3 mx-auto">
        {saved == true &&
          <Zoom>
            <div className="mx-auto bg-green-600 text-gray-200 rounded-md text-center items-center">
              <span>Simulation Saved</span>
            </div>
          </Zoom>
        }
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className='text-2xl font-extrabold'>General</h2>
        <div className="grid grid-cols-3">  
          <div className="px-2">
            <label>Name</label>
            {errors.name && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <input {...register("name", { required: true })} className="w-full appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" placeholder="Name" />
          </div>

          <div className="px-2">
            <label>Request Type</label>
            {errors.requestType && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <select {...register("requestType", { required: true })} className="w-full appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10">
              <option value="PREPARE">PREPARE</option>
              <option value="PROPOSAL">PROPOSAL</option>
              <option value="CONFIRM">CONFIRM</option>
            </select>
          </div>

          <div className="px-2">
            <label>VAT Enabled</label>
            {errors.vatEnabled && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <input type="checkbox" {...register("vatEnabled", { })} className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" />
          </div>

          <div className="px-2 col-span-3">
            <label>Description</label>
            {errors.description && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <textarea rows="4" {...register("description", { required: true })} className="w-full appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" placeholder="Description" />
          </div>
        </div>

        <h2 className='text-2xl font-extrabold pt-2'>Affordability</h2>
        <div className="grid grid-cols-3">
          <div className="px-2">
            <label>Net Affordability</label>
            {errors.affordabilityAmount && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <input {...register("affordabilityAmount", { required: true })} className="w-full appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" placeholder="Nett affordability" />
          </div>

          <div className="px-2">
            <label>Gross Collectable</label>
            {errors.grossCollectable && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <input {...register("grossCollectable", { required: true })} className="w-full appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" placeholder="Gross collectable" />
          </div>

          <div className="px-2">
            <label>Pay Day</label>
            {errors.pdayday && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <input {...register("payday", { required: true })} className="w-full appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" placeholder="Pay day" />
          </div>

          <div className="px-2">
            <label>First Collection Date</label>
            {errors.firstCollectionDate && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <input type="date" {...register("firstCollectionDate", { required: true })} className="w-full appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10"  />
          </div>

          <div className="px-2">
            <label>First Distribution Date</label>
            {errors.firstDistributionDate && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <input type="date" {...register("firstDistributionDate", { required: true })} className="w-full appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" />
          </div>
        </div>

        <h2 className='text-2xl font-extrabold pt-2'>Fees</h2>
        <div className="grid grid-cols-4">
          <div className="px-2">
            <label>DC Fees</label>
            {errors.dcFee && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <input {...register("dcFee", { required: true })} className="w-full appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" placeholder="DC Fees" />
          </div>

          <div className="px-2">
            <label>Legal Fees</label>
            {errors.legalFee && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <input {...register("legalFee", { required: true })} className="w-full appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" placeholder="Legal Fees" />
          </div>

          <div className="px-2">
            <label>PDA Fees</label>
            {errors.pdaFee && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <input {...register("pdaFee", { required: true })} className="w-full appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" placeholder="PDA Fees" />
          </div>

          <div className="px-2">
            <label>Aftercare Fee Percentage</label>
            {errors.afterCareFee && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <input {...register("afterCareFee", { required: true })} className="w-full appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" placeholder="Aftercare Fee Percentage" />
          </div>
        </div>

        <h2 className='text-2xl font-extrabold pt-2'>Accounts</h2>
        <div className='px-2 font-extralight text-sm'>
          <table className="table-auto mt-1">
          <thead>
            <tr>
              <th>Credit Provider Name</th>
              <th>Account Type</th>
              <th>Open Date</th>
              <th>Original Term</th>
              <th>COB Balance</th>
              <th>COB Date</th>
              <th>Deduction Amount</th>
              <th>Rate</th>
              <th>Accepted</th>
              <th>Admin Fee</th>
              <th>Insurance</th>
              <th>Actions</th>
            </tr>
          </thead>
            <tbody>
            {fields.map((item, index) => (
              <tr key={item.id} className="text-xs">
                <td>
                  <input 
                    defaultValue={`${item.creditProviderName}`}
                    placeholder="Name" 
                    name={`simulationRequestAccounts[${index}].creditProviderName`}
                    { ...register(`simulationRequestAccounts[${index}].creditProviderName`)}
                    className="py-2 px-4 appearance-none relative block border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 text-xs" />
                </td>

                <td>
                  <select
                    defaultValue={`${item.type}`}
                    className="py-2 px-4 appearance-none relative block border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 text-xs"
                    name={`simulationRequestAccounts[${index}].type`}
                    { ...register(`simulationRequestAccounts[${index}].type`)}
                    >
                    <option className="text-xs" value="UF">UF</option>
                    <option value="DC">DC</option>
                    <option value="DCA">DCA</option>
                    <option value="SPV">SPV</option>
                    <option value="SCV">SCV</option>
                    <option value="PVB">PVB</option>
                    <option value="CVB">CVB</option>
                    <option value="UT">UT</option>
                    <option value="SM">SM</option>
                    <option value="LEG">LEG</option>
                  </select>
                </td>

                <td>
                  <input 
                    defaultValue={`${item.openDate}`}
                    type="date"
                    name={`simulationRequestAccounts[${index}].openDate`}
                    { ...register(`simulationRequestAccounts[${index}].openDate`)}
                    className="py-2 px-1 appearance-none relative block border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 text-xs" />
                </td>

                <td>
                  <input 
                    defaultValue={`${item.originalTerm}`}
                    type="number"
                    name={`simulationRequestAccounts[${index}].originalTerm`}
                    { ...register(`simulationRequestAccounts[${index}].originalTerm`)}
                    className="py-2 px-1 text-right appearance-none relative block mx-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 text-xs" />
                </td>

                <td>
                  <input 
                    defaultValue={`${item.cobBalance}`}
                    name={`simulationRequestAccounts[${index}].cobBalance`}
                    { ...register(`simulationRequestAccounts[${index}].cobBalance`)}
                    className="py-2 px-1 text-right appearance-none relative block mx-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 text-xs" />
                </td>

                <td>
                  <input 
                    defaultValue={`${item.cobDate}`}
                    name={`simulationRequestAccounts[${index}].cobDate`}
                    { ...register(`simulationRequestAccounts[${index}].cobDate`)}
                    type="date" 
                    className="py-2 px-1 appearance-none relative block mx-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 text-xs" />
                </td>

                <td>
                  <input 
                    defaultValue={`${item.deductionAmount}`}
                    name={`simulationRequestAccounts[${index}].deductionAmount`}
                    { ...register(`simulationRequestAccounts[${index}].deductionAmount`)} 
                    className="py-2 px-1 text-right appearance-none relative block mx-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 text-xs" />
                </td>

                <td>
                  <input 
                    defaultValue={`${item.currentRate}`}
                    name={`simulationRequestAccounts[${index}].currentRate`}
                    { ...register(`simulationRequestAccounts[${index}].currentRate`)}
                    className="py-2 px-1 text-right appearance-none relative block mx-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 text-xs" />
                </td>

                <td>
                  <select
                    defaultValue={`${item.indicator}`}
                    className="py-2 px-4 appearance-none relative block border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 text-xs"
                    name={`simulationRequestAccounts[${index}].indicator`}
                    { ...register(`simulationRequestAccounts[${index}].indicator`)}
                    >
                    <option className="text-xs" value="A">Yes</option>
                    <option value="N">No</option>
                    <option value="P">Pending</option>
                  </select>
                </td>

                <td>
                  <input 
                    defaultValue={`${item.monthlyAdminFees}`}
                    name={`simulationRequestAccounts[${index}].monthlyAdminFees`}
                    { ...register(`simulationRequestAccounts[${index}].monthlyAdminFees`)}
                    className="py-2 px-1 text-right appearance-none relative block mx-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 text-xs" />
                </td>

                <td>
                  <input 
                    defaultValue={`${item.monthlyPremiums}`}
                    name={`simulationRequestAccounts[${index}].monthlyPremiums`}
                    { ...register(`simulationRequestAccounts[${index}].monthlyPremiums`)}
                    className="py-2 px-1 text-right appearance-none relative block mx-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 text-xs" />
                </td>

                <td>
                  <button type="button" onClick={() => remove(index)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
        
        {
            id != "new" &&
            <>
              <h2 className='text-2xl font-extrabold pt-2'>Created Proposals</h2>
              <div className='px-2 font-extralight text-sm'>
              <table className="table-auto mt-4">
                <thead>
                  <tr>
                    <th>Reference Number</th>
                    <th>Creation Time</th>
                    <th>Solved</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {dcrses.sort((a, b) => new Date(a.createDate) - new Date(b.createDate)).map((r, i) =>
                    <tr key={`related_dcrs_${i}`} className="border-b-2 border-gray-300 text-basa_gray hover:text-basa_blue-darker">
                      <td className="p-1 px-4">{r.response.responseId}</td>
                      <td className="p-1 px-4">{r.createDate.split('T')[0]} {r.createDate.split('T')[1].split('+')[0]}</td>
                      <td className="p-1 px-4">{r.response.resolved}</td>
                      <td className="p-1 px-4 " >
                        <Link to={`/dcrs/${r.id}`} >
                          <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                        </Link>
                      </td>
                    </tr>
                  )
                  }
                </tbody>
              </table>
            </div>
            </>
        }
        <div className="flex mt-2 pt-3">
          <Link to="/tools/Simulator" className="group relative justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-basa_blue hover:bg-basa_gray-light hover:text-basa_blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 mr-1">
            Back
          </Link>

          <button 
            type="button"
            className="mr-1 group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-basa_blue hover:bg-basa_gray-light hover:text-basa_blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" 
            onClick={() => {
              insert(0,{ creditProviderName: "", originalTerm: "", currentRate: "", cobBalance: "", deductionAmount: "", monthlyAdminFees: "", monthlyPremiums: "", openDate:"" });
            }}>
            Add account
          </button>

          <button 
            type="button"
            className="mr-1 group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-basa_blue hover:bg-basa_gray-light hover:text-basa_blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" 
            onClick={() => {
              toggleSpinnerOn();
              Promise.resolve(new SimulationService().delete(id, props.handleError))
                .then((value) => {
                  history.push("/tools/Simulator");
                })
            }}>
            Delete
          </button>
          <button type="submit" className="mr-1 group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-basa_blue hover:bg-basa_gray-light hover:text-basa_blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
            Save
          </button>

          <button 
            type="button" 
            onClick={simulate} 
            name="saveAndSimulate" 
            className="mr-1 group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-basa_blue hover:bg-basa_gray-light hover:text-basa_blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
            Save & Simulate
          </button>
        </div>
      </form>
    </div>
  )
}

export { Simulator };