import { apiUrl } from '../helpers/urlHelper' ;
import { userService } from './userService';

export const noticeService = {
    getAll,
    currentNotices,
    create,
    update,
    search,
    fetchNotice
};

function getAll(handleError) {
    const headers = userService.getAuthHeader();
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(`${apiUrl()}/api/notices`, requestOptions)
    .then(response => response.json())
    .catch(error => handleError("Error", error));
}

function currentNotices(handleError) {
    const requestOptions = {
        method: 'GET'
    };
    var now = new Date();
    return fetch(`${apiUrl()}/api/notices/search/current?now=${now.toISOString().split('T')[0]}`, requestOptions)
    .then(response => response.json())
    .catch(error => handleError("Error", error));
}

function search(content, handleError) {
    const headers = userService.getAuthHeader();
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(`${apiUrl()}/api/notices/search/searchNote?content=${content}`, requestOptions)
    .then(response => response.json())
    .catch(error => handleError("Error", error));
}

function fetchNotice(id, handleError) {
    const headers = userService.getAuthHeader();
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(`${apiUrl()}/api/notices/${id}`, requestOptions)
    .then(response => response.json())
    .catch(error => handleError("Error", error));
}

function create(notice, handleError) {
    const headers = userService.getAuthHeader();
    headers['Content-Type'] = 'application/json'

    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(notice)
    }

    var result = fetch(`${apiUrl()}/api/notices`, requestOptions)
    .then(response => response.json())
    .catch(error => handleError("Error", error));

    return result;
}

function update(id, notice, handleError) {
    const headers = userService.getAuthHeader();
    headers['Content-Type'] = 'application/json'

    const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify(notice)
    }

    var result = fetch(`${apiUrl()}/api/notices/${id}`, requestOptions)
        .then(response => response.json())
        .catch(error => handleError("Error", error));

    return result;
}