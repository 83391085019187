import React, { useState, useEffect } from 'react';
import { reportService } from '../services/reportService';
import {
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  DiscreteColorLegend,
  Hint,
  LabelSeries,
  LineSeries,
  FlexibleWidthXYPlot
} from 'react-vis';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useParams,
  useRouteMatch
} from "react-router-dom";

import { userService } from '../services/userService';
import '../style.css'

function MonthlyReports(props) {
  const now = new Date();
  const yearAgo = new Date(new Date().setFullYear(now.getFullYear() - 1));
  const authentication = props.authentication;
  const setAuthentication = props.setAuthentication;
  const [startDate, setStartDate] = useState(yearAgo.toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(now.toISOString().split('T')[0]);
  const history = useHistory();

  if (!authentication.authenticated) {
    Promise.resolve(userService.checkSession())
      .then((value) => {
        setAuthentication(value)
        if (!value.authenticated) {
          history.push('/');
        }
      }
      )
  } else if (!authentication.permissions.includes("Reports.Monthly")) {
    console.log("************");
    console.log(authentication.permissions)
    history.push('/app/reports');
  }
  
  return (
    <div>
      <div className="grid grid-cols-3 pt-3">
        <div className="pt-3">
          <label>From</label><br></br>
          <input key="report_start_date" value={startDate} onChange={e => setStartDate(e.target.value)} type="date" className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" />
        </div>
        <div className="pt-3">
          <label>To</label><br></br>
          <input key="report_end_date" value={endDate} onChange={e => setEndDate(e.target.value)} type="date" className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" />
        </div>
        <div className="pt-3"></div>
      </div>
      <div className="text-xs">
        <div className="flex-1 pt-16 w-9/12 ">
          <ApplicationsAtAccountLevel handleError={props.handleError} startDate={startDate} endDate={endDate}></ApplicationsAtAccountLevel>
        </div>
        <div className="flex-1 pt-16 w-9/12 ">
          <DCRSApplications handleError={props.handleError} startDate={startDate} endDate={endDate} ></DCRSApplications>
        </div>
        <div className="flex-1 pt-16 w-9/12 ">
          <DCSystemVendor handleError={props.handleError} startDate={startDate} endDate={endDate}></DCSystemVendor>
        </div>
        <div className="flex-1 pt-16 w-9/12 ">
          <NotSolved handleError={props.handleError} startDate={startDate} endDate={endDate}></NotSolved>
        </div>
      </div>
    </div>
  )
  }

  function ApplicationsAtAccountLevel(props) {
    const [proposals, setProposals] = useState([]);
    const [confirm, setConfirm] = useState([]);
    const [prepare, setPrepare] = useState([]);
    const [currentValue, setCurrentValue] = useState([]);
  
    useEffect(() => {
      const fetchReportData = async () => {
        reportService.applicationsAtAccountLevel(props.startDate, props.endDate, props.handleError)
          .then((response) => {
            var data = response._embedded.applicationsAtAccountLevelReports;
            var _proposals = data.filter(it => it.requestType == 'PROPOSAL')
              .map(it => {
                let obj = {};
                obj['x'] = it.createMonth;
                obj['y'] = it.total;
                return obj;
              });
            setProposals(_proposals);
  
            var _confirm = data.filter(it => it.requestType == 'CONFIRM')
              .map(it => {
                let obj = {};
                obj['x'] = it.createMonth;
                obj['y'] = it.total;
                return obj;
              });
            setConfirm(_confirm);
  
            var _prepare = data.filter(it => it.requestType == 'PREPARE')
              .map(it => {
                let obj = {};
                obj['x'] = it.createMonth;
                obj['y'] = it.total;
                return obj;
              });
            setPrepare(_prepare);
          });
      }
      fetchReportData();
    }, [props.startDate, props.endDate]);
  
    return (
      <>
      <div>
        <h1 className="small_basa_heading text-center">
          <span className="block text-basa_blue-dark lg:inline">DCRS Requests at Account Level</span>
        </h1>
        <div className="content-center mx-auto p-0 relative">
          <FlexibleWidthXYPlot
            xType="ordinal"
            stackBy="y"
            height={300}
            margin={{ left: 85 }}
            className="relative"
            animation
          >
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis />
            <YAxis />
            <VerticalBarSeries
              color="#12939A"
              data={proposals}
              onValueMouseOver={(val, info) => {
                setCurrentValue(val);
              }}
            />
            <VerticalBarSeries
              color="#79C7E3"
              data={prepare}
              onValueMouseOver={(val, info) => {
                setCurrentValue(val);
              }}
            />
            <VerticalBarSeries
              color="#374152"
              data={confirm}
              onValueMouseOver={(val, info) => {
                setCurrentValue(val);
              }}
            />
            {currentValue.y &&
              <Hint value={currentValue}>
                <div className="border-green-500 border-2 rounded bg-gray-100 p-2">
                  <p>{currentValue.y}</p>
                </div>
              </Hint>
            }
          </FlexibleWidthXYPlot >
        </div>
        
      </div>
      <div className="pl-16">
        <table className="table-auto mt-4">
            <thead>
              <tr>
                <th></th>
                {proposals.map((x, i) =>
                  <th className="px-2">{x.x}</th>
                              )
                }
              </tr>
            </thead>
            <tbody>
              <tr className="border-b-2 border-gray-300 text-basa_gray">
                <th className="proposal_text_color">Proposal</th>
                {proposals.map((x, i) =>
                    <td className="border-l border-r p-1 text-right">{x.y}</td>
                                )
                }
              </tr>
              <tr className="border-b-2 border-gray-300 text-basa_gray">
                <th className="prepare_text_color">Prepare</th>
                {prepare.map((x, i) =>
                    <td className="border-l border-r p-1 text-right">{x.y}</td>
                                )
                }
              </tr>
              <tr className="border-b-2 border-gray-300 text-basa_gray">
                <th className="confirm_text_color">Confirm</th>
                {confirm.map((x, i) =>
                    <td className="border-l border-r p-1 text-right">{x.y}</td>
                                )
                }
              </tr>
            </tbody>
        </table>
      </div>
    </>  
    )
  }
  
  function DCRSApplications(props) {
    const [solved, setSolved] = useState([]);
    const [unSolved, setUnSolved] = useState([]);
    const [currentValue, setCurrentValue] = useState([]);
  
    useEffect(() => {
      const fetchReportData = async () => {
        reportService.dcrsApplications(props.startDate, props.endDate, props.handleError)
          .then((response) => {
            var data = response._embedded.dCRSApplicationsReports;
            var _solved = data.filter(it => it.resolvedFriendly == 'YES')
              .map(it => {
                let obj = {};
                obj['x'] = it.createMonth;
                obj['y'] = it.total;
                return obj;
              });
            setSolved(_solved);
  
            var _unSolved = data.filter(it => it.resolvedFriendly != 'YES')
              .map(it => {
                let obj = {};
                obj['x'] = it.createMonth;
                obj['y'] = it.total;
                return obj;
              });
            setUnSolved(_unSolved);
          });
      }
      fetchReportData();
    }, [props.startDate, props.endDate]);
  
    return (
      <>
      <div>
        <h1 className="small_basa_heading text-center">
          <span className="block text-basa_blue-dark lg:inline">DCRS Proposals Solved vs Not Solved Monthly</span>
        </h1>
        <div className="content-center mx-auto p-0">
          <FlexibleWidthXYPlot
            xType="ordinal"
            stackBy="y"
            height={300}
            margin={{ left: 85 }}
            className="relative"
            animation
          >
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis />
            <YAxis />
            <VerticalBarSeries
              color="#374152"
              data={solved}
              onValueMouseOver={(val, info) => {
                setCurrentValue(val);
              }}
            />
            <VerticalBarSeries
              color="#64C790"
              data={unSolved}
              onValueMouseOver={(val, info) => {
                setCurrentValue(val);
              }}
            />
            {currentValue.y &&
              <Hint value={currentValue}>
                <div className="border-green-500 border-2 rounded bg-gray-100 p-2">
                  <p>{currentValue.y}</p>
                </div>
              </Hint>
            }
          </FlexibleWidthXYPlot>
        </div>
      </div>
      
      <div className="pl-16">
        <table className="table-auto mt-4">
            <thead>
              <tr>
                <th></th>
                {solved.map((x, i) =>
                  <th className="px-2">{x.x}</th>
                              )
                }
              </tr>
            </thead>
            <tbody>
              <tr className="border-b-2 border-gray-300 text-basa_gray">
                <th className={`proposal_text_color text-left`} style={{ color: '#374152' }}>Solved</th>
                {solved.map((x, i) =>
                    <td className="border-l border-r p-1 text-right">{x.y}</td>
                                )
                }
              </tr>
              <tr className="border-b-2 border-gray-300 text-basa_gray">
                <th className={`prepare_text_color text-left`} style={{ color: '#64C790' }}>Unsolved</th>
                {unSolved.map((x, i) =>
                    <td className="border-l border-r p-1 text-right">{x.y}</td>
                                )
                }
              </tr>
            </tbody>
        </table>
      </div>
      </>
    )
  }
  
  function DCSystemVendor(props) {
    const [data, setData] = useState([]);
    const [ready, setReady] = useState(false);
    const [legend, setLegend] = useState([]);
    const [currentValue, setCurrentValue] = useState([]);
  
    useEffect(() => {
      const fetchReportData = async () => {
        reportService.dcSystemVendor(props.startDate, props.endDate, props.handleError)
          .then((response) => {
            var _data = response._embedded.dCSystemVendorReports;
            setData(_data);
            setReady(true);
            setLegend(buildLegend(_data));
          });
      }
      fetchReportData();
    }, [props.startDate, props.endDate]);
  
    return (
      <>
      <div>
        <h1 className="small_basa_heading text-center">
          <span className="block text-basa_blue-dark lg:inline">DC System Vendors throughput on DCRS (number of proposals) Monthly</span>
        </h1>
  
        {ready &&
          <div className="content-center mx-auto p-0">
            <FlexibleWidthXYPlot
              xType="ordinal"
              stackBy="y"
              height={300}
              margin={{ left: 85 }}
              className="relative"
              animation
            >
              {/* <DiscreteColorLegend
                orientation="horizontal"
                items={legend}
              /> */}
              <VerticalGridLines />
              <HorizontalGridLines />
              <XAxis />
              <YAxis />
  
              {data.filter((it) => it.resolvedFriendly == "YES").map((object, i) => {
                let vendor_color = legend.filter((it) => it.title == object.systemVendor).map((it) => it.color);
                return <VerticalBarSeries
                  key={"series_" + i}
                  cluster={object.systemVendor}
                  color={vendor_color}
                  data={[{
                    y: object.total,
                    x: object.createMonth
                  }]}
                  onValueMouseOver={(val, info) => {
                    setCurrentValue(val);
                  }}
                />
              })}
  
              {data.filter((it) => it.resolvedFriendly == "NO").map((object, i) => {
                let vendor_color = "#3e4146"
                return <VerticalBarSeries
                  key={"series_" + i}
                  cluster={object.systemVendor}
                  color={vendor_color}
                  data={[{
                    y: object.total,
                    x: object.createMonth
                  }]}
                  onValueMouseOver={(val, info) => {
                    setCurrentValue(val);
                  }}
                />
              })}
  
              {currentValue.y &&
                <Hint value={currentValue}>
                  <div className="border-green-500 border-2 rounded bg-gray-100 p-2">
                    <p>{currentValue.y}</p>
                  </div>
                </Hint>
              }
  
            </FlexibleWidthXYPlot>
          </div>
        }
        
      </div>
  
      <div className="">
        <table className="table-auto mt-4 pl-8">
          <thead>
            <tr>
              <th></th>
              {
                data.filter((it) => it.resolvedFriendly == "YES")
                  .map((object, i) => {return object.createMonth})
                  .filter((item, i, ar) => ar.indexOf(item) === i)
                  .map((it) => {
                    return <th className="px-2">{it}</th>
                })
              }
            </tr>
          </thead>
          <tbody>
            {
              data.map((object, i) => {return object.systemVendor})
              .filter((item, i, ar) => ar.indexOf(item) === i)
              .map((it) => {
                let vendor_color = legend.filter((legend_item) => legend_item.title == it).map((it) => it.color);
                return <>
                <tr className="px-2 text-left">
                  <th style={{color: vendor_color}} >{it}</th>
                  {
                    data.filter((item, i, ar) => item.systemVendor === it && item.resolvedFriendly == "YES")
                    .map ((it) => {
                      return <td className="border-l border-r p-1 text-right">{it.total}</td>
                    })
                  }
                </tr>
                <tr className="px-2 text-left">
                  <th style={{color: vendor_color}}>{it} - Not Solved</th>
                  {
                    data.filter((item, i, ar) => item.systemVendor === it && item.resolvedFriendly == "NO")
                    .map ((it) => {
                      return <td className="border-l border-r p-1 text-right">{it.total}</td>
                    })
                  }
                </tr>
                </>
              })
  
            }
            
          </tbody>
        </table>
      </div>
      </>
    )
  }
  
  function NotSolved(props) {
    const [unSolved, setUnSolved] = useState([]);
    const [currentValue, setCurrentValue] = useState([]);
  
    useEffect(() => {
      const fetchReportData = async () => {
        reportService.dcrsApplications(props.startDate, props.endDate, props.handleError)
          .then((response) => {
            var data = response._embedded.dCRSApplicationsReports;
            var _solved = data.filter(it => it.resolvedFriendly == 'YES')
              .reduce((map, it) => {
                map[it.createMonth] = it.total;
                return map;
              }, {});
  
            var _unSolved = data.filter(it => it.resolvedFriendly != 'YES')
              .map(it => {
                let obj = {};
                obj['x'] = it.createMonth;
                obj['y'] = Math.round(it.total / _solved[it.createMonth] * 100);
                return obj;
              });
            setUnSolved(_unSolved);
          });
      }
      fetchReportData();
    }, [props.startDate, props.endDate]);
  
    return (
      <>
      <div className="mb-4">
        <h1 className="small_basa_heading text-center">
          <span className="block text-basa_blue-dark lg:inline">Percentage DCRS Proposals Solved Monthly</span>
        </h1>
        <div className="content-center mx-auto p-0">
          <FlexibleWidthXYPlot
            xType="ordinal"
            stackBy="y"
            height={300}
            margin={{ left: 85 }}
            className="relative"
            animation
          >
            <XAxis />
            <YAxis />
            <LineSeries
              style={{
                strokeLinejoin: 'round',
                strokeWidth: 4,
                fill: 'none'
              }}
              data={unSolved}
              onSeriesMouseOver={(val, info) => {
                setCurrentValue(val);
              }}
            />
  
            {currentValue.y &&
              <Hint value={currentValue}>
                <div className="border-green-500 border-2 rounded bg-gray-100 p-2">
                  <p>{currentValue.y}</p>
                </div>
              </Hint>
            }
          </FlexibleWidthXYPlot>
          {currentValue.y}
        </div>
      </div>
      <div>
      <table className="table-auto mt-4">
            <thead>
              <tr>
                <th></th>
                {unSolved.map((x, i) =>
                  <th className="px-2">{x.x}</th>
                              )
                }
              </tr>
            </thead>
            <tbody>
              <tr className="border-b-2 border-gray-300 text-basa_gray">
                <th className="proposal_text_color">Solved</th>
                {unSolved.map((x, i) =>
                    <td className="border-l border-r p-1 text-right">{x.y} %</td>
                                )
                }
              </tr>
            </tbody>
        </table>
      </div>
      </>
    )
  }
  
  function buildLegend(data) {
    let color_list = ['#4CC086', '#954557', '#3d6472', '#F69333', '#2F858C', "#6B586E", '#35A796', '#64C790', '#A8E37F', '#30505b'];
    let vendors = Array.from(new Set(data.flatMap((it) => it.systemVendor)));
    let legendMap = vendors.map((v, i) => {
      let color = color_list[i];
      return {
        title: v,
        color: color
      }
    });
    legendMap.push({ title: 'Unresolved', color: '#3e4146' });
    return legendMap;
  }

  export { MonthlyReports };