const React = require('react');

import version from '../../resources/version.json';
import logo from '../img/Logo_Y_Transparent.png';
import '../style.css'

class Footer extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  toggleBurgerMenu() {
    document.getElementById("mobile-menu").classList.toggle('hidden');
  }

  render() {
    return (
      <div className="fixed block bottom-0 mx-auto w-full pt-7 z-50 bg-white mb-0 pb-4" name="footer">
        <footer className="z-1 text-sm justify-center text-center w-full align-bottom text-gray-400 h-8 mb-4">
          Powered by Yellow Professional
          <img className="object-center h-4 w-auto mx-auto sm:h-6 pt-1" src={logo}></img>
          Version : {version.version} &nbsp; Created : {version.created}
        </footer>
      </div>
    )
  }
}
export { Footer };