const React = require('react');

import '../style.css'

import version from '../../resources/version.json';

function Modal(props) {
    // Note: There is no "reset" mechanism to undo the actions done by the actionButton, other than a page reload.
    if (undefined == props.invokeModalOff) {
        let error = new Error('Modal requires an invokeModalOff function.');
        console.log(error);
        throw error;
    }

    // Show/Hide based on props
    var displayHeader       = (undefined !== props.header);
    var displayEmail        = (undefined !== props.email);
    var displaySubject      = (undefined !== props.subject);
    var displayContent      = (undefined !== props.content);
    var displayTextArea     = (undefined !== props.textarea);
    var displayFooter       = (undefined !== props.footer);
    var displaySessionInfo  = (true      === props.showSessionInfo);
    var displayActionButton = (undefined !== props.actionButton &&
                               undefined !== props.invokeActionButton);

    // Set defaults
    let props_modalKey           = props.modalKey           ?? '';
    let props_header             = props.header             ?? '';
    let props_email              = getEmail(props.email);
    let props_subject            = props.subject            ?? '';
    let props_content            = props.content            ?? '';
    let props_textarea           = props.textarea           ?? '';
    let props_footer             = props.footer             ?? '';
    let props_actionButton       = props.actionButton       ?? '';
    let props_invokeActionButton = props.invokeActionButton ?? '';
    let props_invokeModalOff     = props.invokeModalOff     ?? '';

    document.addEventListener('keydown', function (event) {
        if (event.key === "Escape") {
            props_invokeModalOff();
        }
    });

    function getEmail(parmEmail) {
        if (parmEmail) { return parmEmail; }
        
        let userEmail = JSON.parse(localStorage.getItem('user'))?.username;
        if (userEmail) { return String(userEmail); }

        return "***Your email address";
    }

    function getSessionInfo() {
        return "User: " + String(JSON.parse(localStorage.getItem('user'))?.username)
             //+ "\nURL: "     + window.location.href
             + "\nVersion: " + version.version
             + "\nCreated: " + version.created;
    }

    return (
        <div id={props_modalKey} className="modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
            <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>

            <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">

                <div onClick={() => props_invokeModalOff()} className="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50">
                    <svg className="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                    </svg>
                    <span className="text-sm">(Esc)</span>
                </div>

                <div className="modal-content py-4 text-left px-6">
                    <div className="flex justify-between items-center">
                        {displayHeader &&
                            <h1 id={props_modalKey + '_header'} className="small_basa_heading">
                                <span className="block text-basa_blue-dark lg:inline">{props_header}</span>
                            </h1>
                        }

                        <div onClick={() => props_invokeModalOff()} className="modal-close cursor-pointer z-50">
                            <svg className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                            </svg>
                        </div>
                    </div>

                    {displayEmail &&
                        <div id={props_modalKey + '_email'} className="pt-1">
                            <label>Email:</label>
                            <input id={props_modalKey + '_email_input'} defaultValue={props_email} className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 w-11/12" />
                        </div>
                    }

                    {displaySubject &&
                        <div id={props_modalKey + '_subject'} className="pt-1">
                            <label>Subject:</label>
                            <input id={props_modalKey + '_subject_input'} defaultValue={props_subject} className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 w-11/12" />
                        </div>
                    }

                    {displayContent &&
                        <p class="whitespace-pre-wrap"  id={props_modalKey + '_content'}>{props_content}</p>
                    }

                    {displayTextArea &&
                        <textarea id={props_modalKey + '_textArea'} defaultValue={props_textarea}
                            className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 w-11/12"
                        ></textarea>
                    }

                    {displayFooter &&
                        <p id={props_modalKey + '_footer'} className="pre_wrap text-xs">{props_footer}</p>
                    }

                    {displaySessionInfo &&
                        <p id={props_modalKey + '_sessionInfo'} className="pre_wrap text-xs">{getSessionInfo()}</p>
                    }

                    <div className="flex justify-between">
                        {displayActionButton &&
                            <div className="flex justify-start pt-2">
                                <button id={props_modalKey + '_actionButton'} onClick={() => props_invokeActionButton()}
                                    className="basa_button"
                                >{props_actionButton}</button>
                            </div>
                        }

                        <div className="flex justify-end pt-2">
                            <button id={props_modalKey + '_closeButton'} onClick={() => props_invokeModalOff()}
                                className="basa_button"
                            >Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { Modal };