import { apiUrl } from '../helpers/urlHelper' ;
import { userService } from './userService';

export const fileService = {
    fetchLogFile,
    fetchRequestFile,
    fetchResponseFile,
    fetchAssessmentSummaryFile,
    fetchPaymentPlanFile,
    fetchRestructureProposalFile
};

function fetchLogFile(id) {
    const headers = userService.getAuthHeader();
    headers['Content-Type'] = 'application/json'

    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(`${apiUrl()}/api/log/${id}`, requestOptions);
}

function fetchRequestFile(id) {
    const headers = userService.getAuthHeader();
    headers['Content-Type'] = 'application/json'

    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(`${apiUrl()}/api/request/${id}`, requestOptions);
}

function fetchResponseFile(id) {
    const headers = userService.getAuthHeader();
    headers['Content-Type'] = 'application/json'

    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(`${apiUrl()}/api/response/${id}`, requestOptions);
}

function fetchAssessmentSummaryFile(id) {
    const headers = userService.getAuthHeader();
    headers['Content-Type'] = 'application/json'

    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(`${apiUrl()}/api/assesment_summary/${id}`, requestOptions);
}

function fetchPaymentPlanFile(id) {
    const headers = userService.getAuthHeader();
    headers['Content-Type'] = 'application/json'

    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(`${apiUrl()}/api/payment_plan/${id}`, requestOptions);
}

function fetchRestructureProposalFile(id) {
    const headers = userService.getAuthHeader();
    headers['Content-Type'] = 'application/json'

    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(`${apiUrl()}/api/restructure_proposal/${id}`, requestOptions);
}