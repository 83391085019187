export function toggleSpinner() {
  const it = document.getElementById("spinner");
  it.classList.toggle('opacity-0');
}

export function toggleSpinnerOn() {
  const it = document.getElementById("spinner");
  it.classList.remove('opacity-0');
}

export function toggleSpinnerOff() {
  const it = document.getElementById("spinner");
  it.classList.add('opacity-0');
}

export function isSpinnerOn() {
  const it = document.getElementById("spinner");
  return !(it.classList.contains('opacity-0'));
}
