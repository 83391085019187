import { apiUrl } from '../helpers/urlHelper' ;
import { userService } from './userService';

class ParameterService {
    async search(searchTerm, handleError) {

        const requestOptions = {
            headers: userService.getAuthHeader()
        }

        var result = await fetch(`${apiUrl()}/api/parameters/search/searchCurrentByName?name=${searchTerm}`, requestOptions)
            .then(response => response.json())
            .catch(error => handleError("Error", error));

        return result._embedded.parameters;
    }

    async fetchAll() {
        const requestOptions = {
            headers: userService.getAuthHeader()
        }

        var result = await fetch(`${apiUrl()}/api/parameters/search/fetchAllCurrent`, requestOptions)
            .then(response => response.json())
            .catch(error => handleError("Error", error));

        return result._embedded.parameters;
    }

    async fetchParameter(name) {
        const requestOptions = {
            headers: userService.getAuthHeader()
        }
        var result = await fetch(`${apiUrl()}/api/parameters/search/fetchCurrentParameter?name=${name}`, requestOptions)
            .then(response => response.json())
            .catch(error => handleError("Error", error));
        return result;
    }

    async create(parameter, handleError) {
        const headers = userService.getAuthHeader();
        headers['Content-Type'] = 'application/json'
    
        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(parameter)
        }
    
        var result = fetch(`${apiUrl()}/api/parameters`, requestOptions)
        .then(response => response.json())
        .catch(error => handleError("Error", error));
    
        return result;
    }

    async update(parameter) {
        
    }

    async fetchParameterHistory(name){
        const requestOptions = {
            headers: userService.getAuthHeader()
        }
        var result = await fetch(`${apiUrl()}/api/parameters?name=${name}&sort=toDate,desc`, requestOptions)
            .then(response => response.json())
            .catch(error => handleError("Error", error));

        return result;
    }
}

export {ParameterService}

