import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useParams,
  useRouteMatch
} from "react-router-dom";
import { useForm } from "react-hook-form";
import { CreditProviderService } from '../services/creditProviderService';
import { userService } from '../services/userService';
import '../style.css';
import Zoom from 'react-awesome-reveal';

function CreditProviders(props) {

  const authentication = props.authentication;
  const history = useHistory();
  const [creditProviders, setCreditProviders] = useState([]);
  const [name, setName] = useState('');
  let { path, url } = useRouteMatch();

  if (!authentication.authenticated || !authentication.permissions.includes("CreditProvider")) {
    Promise.resolve(userService.checkSession())
      .then((value) => {
        if (!value.authenticated) {
          props.addNotice("Your session has expired, please login again.");
          history.push('/');
        }})
  }

  useEffect(() => {
    if (name == '') {
      const fetchCreditProviders = async () => {
        var providers = await new CreditProviderService().all(props.handleError);
        setCreditProviders(providers._embedded.creditProviders);
      }
      fetchCreditProviders();
    }
  }, []);

  function searchName(searchTerm) {
    setName(searchTerm);
    Promise.resolve(new CreditProviderService().search(searchTerm, props.handleError))
      .then((value) =>
        setCreditProviders(value._embedded.creditProviders)
      )
  }

  return (
    <Switch>
      <Route exact path={path}>
        <Search creditProviders={creditProviders} setCreditProviders={setCreditProviders} searchName={searchName} name={name} path={path} url={url}></Search>
      </Route>
      <Route path={`${props.url}/:it/:id`} children={<CreditProvider handleError={props.handleError} />}></Route>
    </Switch >
  )
}

function CreditProvider(props) {
  let { id } = useParams();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [creditProvider, setCreditProvider] = useState([]);
  const [saved, setSaved] = useState(false);

  function onSubmit(cp) {
    if (typeof cp.id === 'undefined') {
      Promise.resolve(new CreditProviderService().create(cp, props.handleError))
        .then((value) => {
          setSaved(true);
          id = value.id;
          setCreditProvider(value);
          resetFields(value);
        })
    } else {
      Promise.resolve(new CreditProviderService().update(id, cp, props.handleError))
        .then((value) =>
          setSaved(true)
        )
    }
  }

  function resetFields(provider){
    reset({
      name: provider.name,
      registrationNumber: provider.registrationNumber,
      creditProviderType: provider.creditProviderType,
      vatNumber: provider.vatNumber,
      contactNumber: provider.contactNumber,
      contactEmail: provider.contactEmail,
      contactFax: provider.contactFax,
      onHold: provider.onHold,
      id: provider.id
    });
  }

  useEffect(() => {
    const fetchCreditProvider = async () => {
      if (id != 'new') {
        var provider = await new CreditProviderService().fetch(id, props.handleError);
        setCreditProvider(provider);
        resetFields(provider);
      }
    }
    fetchCreditProvider();
  },[]);

  return (
    <div className="flex-grow mx-auto p-5" >
      <h1 className="basa_heading">
        <span className="block text-basa_blue-dark xl:inline"> Credit Provider: {creditProvider.name}</span>
      </h1>

      <div className="w-2/3 mx-auto">
        {saved == true &&
          <Zoom>
            <div className="mx-auto bg-green-600 text-gray-200 rounded-md text-center items-center">
              <span>Credit Provider Saved</span>
            </div>
          </Zoom>
        }
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-3 pt-3">
          <div className="pt-3">
            <label>Name</label>
            {errors.name && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <input {...register("name", { required: true })} className="appearance-none rounded-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" placeholder="Name" />
          </div>

          <div className="pt-3">
            {errors.registrationNumber && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <label>NCR Registration Number</label>
            <input {...register("registrationNumber", { required: true })} className="appearance-none rounded-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" />
          </div>

          <div className="pt-3">
            {errors.vatNumber && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <label>VAT Number</label>
            <input {...register("vatNumber", { required: true })} className="appearance-none rounded-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" />
          </div>

          <div className="pt-3">
            {errors.contactNumber && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <label>Contact Number</label>
            <input {...register("contactNumber", { required: true })} className="appearance-none rounded-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" />
          </div>

          <div className="pt-3">
            {errors.contactEmail && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <label>Contact Email</label>
            <input {...register("contactEmail", { required: true })} className="appearance-none rounded-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" />
          </div>

          <div className="pt-3">
            <label>Contact Fax</label>
            <input {...register("contactFax", { required: false })} className="appearance-none rounded-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" />
          </div>

          <div className="pt-3">
            <label>Credit Provider Type</label><br></br>
            <select {...register("creditProviderType")} className="appearance-none rounded-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 w-1/2">
              <option value="INCEDENTAL">Incedental</option>
              <option value="REGISTERED">Registered</option>
            </select>
          </div>

          <div className="pt-3">
            <label>On Hold</label><br></br>
            <input {...register("onHold", { required: false })} type="checkbox" className="appearance-none rounded-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" />
          </div>
        </div>

        <div className="flex mt-2">
          <button type="submit" className="mr-1 group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-basa_blue hover:bg-basa_gray-light hover:text-basa_blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
            Submit
          </button>
          <Link to="/setup/CreditProviders" className="group relative justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-basa_blue hover:bg-basa_gray-light hover:text-basa_blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 mr-1">
            Back
          </Link>
        </div>
      </form>
    </div>
  )

}

function Search(props) {
  var creditProviders = props.creditProviders;
  var searchName = props.searchName;
  var name = props.name;
  return (
    <div className="flex-grow mx-auto pt-2" >
      <h1 className="text-l tracking-tight font-extrabold text-basa_gray sm:text-xl md:text-4xl text-center mt-3">
        <span className="block text-basa_blue-dark xl:inline">Credit Provider Setup</span>
      </h1>

      <div className="divide-y divide-smoke-light p-2">
        <div className="mx-auto p-2 w-2/3">
          <input type="search" className="pb-2 mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-basa_blue-light hover:border-gray-700 hover:bg-opacity-40" placeholder="Type search details here..." value={name} onChange={e => searchName(e.target.value)} />
        </div>
        <div className="pt-2 mx-auto w-2/3">
          {creditProviders.length > 0 &&
            <div className="mx-auto content-center">
              <table className="table mt-4 overflow-scroll">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>NCR Number</th>
                    <th>Contact Email</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {creditProviders.map((x, i) =>
                    <Row provider={x} key={i} path={props.path} url={props.url} />
                  )
                  }
                </tbody>
              </table>
            </div>
          }
          {creditProviders.length == 0 &&
            <div>
              <span className="text-lg text-red-800">No Credit Providers Found</span>
            </div>
          }

          <div className="flex my-2">
            <Link to="/setup/CreditProviders/new" className="group relative justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-basa_blue hover:bg-basa_gray-light hover:text-basa_blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 mr-1">
              New
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

function Row(props) {
  return (
    <tr className="border-b-2 border-gray-300 text-basa_gray hover:text-basa_blue-darker">
      <td className="p-1 px-4">{props.provider.name}</td>
      <td className="p-1 px-4">{props.provider.registrationNumber}</td>
      <td className="p-1 px-4">{props.provider.contactEmail}</td>
      <td className="p-1 px-4 ">
        <Link to={`${props.url}/${props.provider.id}`}>
          <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </Link>
      </td>
    </tr>
  )
}

export { CreditProviders };