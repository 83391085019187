import { apiUrl } from '../helpers/urlHelper' ;
import { userService } from './userService';


class CreditProviderService {

    async update(id, creditProvider, handleError) {
        const headers = userService.getAuthHeader();
        headers['Content-Type'] = 'application/json'

        const requestOptions = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(creditProvider)
        }

        var result = await fetch(`${apiUrl()}/api/creditProviders/${id}`, requestOptions)
            .then(response => response.json())
            .catch(error => handleError("Error", error));

        return result;
    }

    async create(creditProvider, handleError) {
        const headers = userService.getAuthHeader();
        headers['Content-Type'] = 'application/json'

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(creditProvider)
        }

        var result = await fetch(`${apiUrl()}/api/creditProviders`, requestOptions)
            .then(response => response.json())
            .catch(error => handleError("Error", error));

        return result;
    }

    async search(searchTerm, handleError) {

        const headers = userService.getAuthHeader();
        headers['Content-Type'] = 'application/json'

        const requestOptions = {
            method: 'GET',
            headers: headers
        }

        var result = await fetch(`${apiUrl()}/api/creditProviders/search/findByNameContainingIgnoreCase?name=${searchTerm}`, requestOptions)
            .then(response => response.json())
            .catch(error => handleError("Error", error));

        return result;
    }

    async userCreditProvider(id, handleError) {
        const headers = userService.getAuthHeader();
        headers['Content-Type'] = 'application/json'

        const requestOptions = {
            method: 'GET',
            headers: headers
        }
        var result = await fetch(`${apiUrl()}/api/users/${id}/creditProvider`, requestOptions)
            .then(response => {
                if(response.status == 200) {
                    return response.json();
                } else if (response.status == 404) {
                    return {};
                } else {

                }
                
            }).catch(error => handleError("Error", error));

        return result;
    }

    async all(handleError) {
        const headers = userService.getAuthHeader();
        headers['Content-Type'] = 'application/json'

        const requestOptions = {
            method: 'GET',
            headers: headers
        }

        var result = await fetch(`${apiUrl()}/api/creditProviders?size=100&sort=name`, requestOptions)
            .then(response => response.json())
            .catch(error => handleError("Error", error));

        return result;
    }

    async fetch(id, handleError) {
        const headers = userService.getAuthHeader();
        headers['Content-Type'] = 'application/json'

        const requestOptions = {
            method: 'GET',
            headers: headers
        }

        var result = await fetch(`${apiUrl()}/api/creditProviders/${id}`, requestOptions)
            .then(response => response.json())
            .catch(error => handleError("Error", error));

        return result;
    }
}

export { CreditProviderService }

