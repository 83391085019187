import { apiUrl } from '../helpers/urlHelper';
import { userService } from './userService';

export const popiActService = {
    setStateRetain
    , setStateUnRetain
    , runPopiActScheduler
};

function setStateRetain(dcrsId) {
    const headers = userService.getAuthHeader();
    headers['Content-Type'] = 'application/json'
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: {}
    };

    return fetch(`${apiUrl()}/api/dcrs/popiact/state/retain?id=${dcrsId}`, requestOptions);
}

function setStateUnRetain(dcrsId) {
    const headers = userService.getAuthHeader();
    headers['Content-Type'] = 'application/json'
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: {}
    };

    return fetch(`${apiUrl()}/api/dcrs/popiact/state/unretain?id=${dcrsId}`, requestOptions);
}

function runPopiActScheduler(date, callbackPrepare, callbackSuccess, callbackError) {
    if (!callbackPrepare()) {
        return;
    }

    const headers = userService.getAuthHeader();
    headers['Content-Type'] = 'application/json'
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: {}
    };

    return fetch(`${apiUrl()}/api/tools/runPopiActScheduler?date=${date}`, requestOptions)
        .then(response => {
            if (response.status !== 200) {
                throw new Error("Error. runPopiActScheduler response.status=" + response.status);
            }
            callbackSuccess();
        })
        .catch((error) => {
            callbackError();
        });
}
