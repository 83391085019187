import React, { useEffect, useState } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  useHistory,
  Route,
  Link,
} from "react-router-dom";
const ReactDOM = require('react-dom');

import './style.css';
import { Login } from './components/login.js';
import { RequestPasswordReset, ResetPassword } from './components/passwordReset.js';
import { Home } from './components/home.js';
import { Nav } from './components/nav.js';
import { About } from './components/about.js';
import { Reports } from './components/reports.js';
import { Setup } from './components/setup.js';
import { DCRS } from './components/dcrs.js';
import { Modal } from './components/modal.js';
import { noticeService } from './services/noticeService';
import { Tools } from './components/tools.js';
import { Footer } from './components/footer.js';
import { Ticket } from './components/ticket.js';

function App(props) {

  const [authentication, setAuthentication] = useState({});
  const [notifications, setNotifications] = useState([]);

  const history = useHistory();

  function handleError(errorMessage, error) {
    console.log(error);
    console.log(error.status);
    alert(errorMessage);
    history.push('/');
  }

  function addNotice(notices) {
    setNotifications(notices);
  }

  useEffect(() => {
    const fetchNotices = async () => {
      var notices = await noticeService.currentNotices(props.handleError);
      setNotifications(notices._embedded.notices.map(x => x));
    }
    fetchNotices();
  }, []);

  return (
    <div className="w-11/12">
      {notifications.map((x, i) =>
        <Notification key={`notice_${i}`} notice={x} />
      )
      }

      <Router basename="/app">
        <div className="relative bg-white mb-14 ">
          <div className="mx-auto pb-5">
            <div className="mx-auto lg:max-w-7xl pb-5">
              <Nav className="z-50 lg:max-w-7xl mx-auto" authentication={authentication} setAuthentication={setAuthentication} />
            </div>
            <Switch>
              <Route path="/login" >
                <div className="max-w-7xl mx-auto pb-5">
                  <Login className="z-10" setAuthentication={setAuthentication} />
                </div>
              </Route>
              <Route path="/request_password_reset/" >
                <div className="max-w-7xl mx-auto pb-5">
                  <RequestPasswordReset className="z-10" />
                </div>
              </Route>
              <Route path="/reset_password/:token" >
                <div className="max-w-7xl mx-auto pb-5">
                  <ResetPassword className="z-10" />
                </div>
              </Route>
              <Route path="/about">
                <div className="max-w-7xl mx-auto pb-5">
                  <About className="z-10" />
                </div>
              </Route>
              <Route path="/dcrs">
                <div className="w-5/6 lg:w-11/12 mx-auto pb-5">
                  <DCRS setAuthentication={setAuthentication} authentication={authentication} addNotice={addNotice} handleError={handleError} />
                </div>
              </Route>
              <Route path="/reports" className="overflow-scroll">
                <div className="max-w-7xl mx-auto pb-5">
                  <Reports setAuthentication={setAuthentication} authentication={authentication} handleError={handleError} />
                </div>
              </Route>
              <Route path="/setup" className="overflow-scroll">
                <Setup authentication={authentication} addNotice={addNotice} handleError={handleError} />
              </Route>
              <Route path="/tools">
                <div className="max-w-7xl mx-auto pb-5">
                  <Tools setAuthentication={setAuthentication} authentication={authentication} handleError={handleError}  />
                </div>
              </Route>
              <Route exact path="/">
                <div className="max-w-7xl mx-auto pb-5">
                  <Home className="max-w-7xl" />
                </div>
              </Route>
            </Switch>
          </div>
        </div>
      </Router>

      <div className="ticketFloating z-100 mt-4">
        <Ticket />
      </div>
      <Footer className="z-10 mt-4" />
      <Spinner></Spinner>
    </div>
  )
}

function Spinner() {
  return (
    <div id="spinner" className="pointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center z-50 opacity-0">
      <div className="absolute w-1/6 h-1/6 justify-center center text-center align-middle mt-auto content-center">
        <div className="loader"></div>
      </div>
    </div>
  )
}


function Notification(props) {
  var keyVal = `modal_${props.notice.id}`;

  function closeNotice(event, notice) {
    event.preventDefault();
    document.getElementById(notice).remove();
  }

  function modalOn() {
    const body = document.querySelector('body')
    const modal = document.getElementById(keyVal);
    modal.classList.remove('opacity-0')
    modal.classList.remove('pointer-events-none')
    body.classList.remove('modal-active')
  }

  function modalOff() {
    const body = document.querySelector('body')
    const modal = document.getElementById(keyVal);
    modal.classList.add('opacity-0')
    modal.classList.add('pointer-events-none')
    body.classList.add('modal-active')
  }

  return (
    <div>
      <div id={props.notice.id}>
        <div className="text-center py-2 ">
          <div className="p-2 bg-gray-700 items-center text-indigo-100 leading-none rounded-full inline-flex w-11/12 lg:w-5/12" role="alert">
            <span className="flex rounded-full bg-basa_blue-dark uppercase px-2 py-1 text-xs font-bold mr-3">Notice</span>
            <span className="font-semibold mr-2 text-left flex-auto">{props.notice.subject.substring(0, 19)}...</span>
            <a onClick={() => modalOn()} className="flex rounded-full bg-basa_blue-dark uppercase px-2 py-1 text-xs font-bold mr-3"> Open</a>
            <a href="" onClick={(event) => closeNotice(event, props.notice.id)} className="flex rounded-full bg-basa_blue-dark uppercase px-2 py-1 text-xs font-bold mr-3">X</a>
          </div>
        </div>
      </div>
      <Modal key={keyVal} modalKey={keyVal} header={props.notice.subject} content={props.notice.content} invokeModalOff={modalOff}></Modal>
    </div>
  )
}

export { App };

ReactDOM.render(
  <App />,
  document.getElementById('react')
)

