import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useParams,
  useRouteMatch
} from "react-router-dom";
import { useForm } from "react-hook-form";
import { ParameterService } from '../services/parameterService';
import { userService } from '../services/userService';
import Zoom from 'react-awesome-reveal';
import '../style.css';

function Settings(props) {

  const authentication = props.authentication;
  const history = useHistory();
  const [parameters, setParameters] = useState([]);
  const [parameter, setParameter] = useState('');
  let { path, url } = useRouteMatch();

  if (!authentication.authenticated || !authentication.roles.includes("Admin")) {
    Promise.resolve(userService.checkSession())
      .then((value) => {
        if (!value.authenticated) {
          props.addNotice("Your session has expired, please login again.");
          history.push('/');
        }
      })
  }

  useEffect(() => {
    if (parameter == '') {
      const fetchParameters = async () => {
        var parameters = await new ParameterService().fetchAll(props.handleError);
        setParameters(parameters);
      }
      fetchParameters();
    }
  }, []);

  function searchParameter(searchTerm) {
    setParameter(searchTerm);
    Promise.resolve(new ParameterService().search(searchTerm, props.handleError))
      .then((value) =>
        setParameters(value)
      )
  }

  return (
    <Switch>
      <Route exact path={path}>
        <Search parameters={parameters} setParameters={setParameters} searchParameter={searchParameter} parameter={parameter} path={path} url={url}></Search>
      </Route>
      <Route path={`${props.url}/:it/:id`} children={<Parameter handleError={props.handleError} />}></Route>
    </Switch >
  )
}

function Parameter(props) {
  let { id } = useParams();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [parameter, setParameter] = useState([]);
  const [parameterHistory, setParameterHistory] = useState({});
  const [saved, setSaved] = useState(false);
  const [show, setShow] = useState(false);

  function onSubmit(parameter) {
    Promise.resolve(new ParameterService().create(parameter, props.handleError))
      .then((value) => {
        setSaved(true);
        id = value.id;
        setParameter(value);
        resetFields(value);
        setShow(false)
      })
  }

  function resetFields(parameter) {
    reset({
      name: parameter.name,
      value: parameter.value,
      fromDate: parameter.fromDate,
      toDate: parameter.toDate
    });
  }

  useEffect(() => {
    const fetchParameter = async () => {
      if (id != 'new') {
        var parameter = await new ParameterService().fetchParameter(id, props.handleError);
        setParameter(parameter);
        resetFields(parameter);
      }
    }

    const fetchHistory = async () => {
      if (id != 'new') {
        var history = await new ParameterService().fetchParameterHistory(id, props.handleError)
        setParameterHistory(history);
      }
    }
    if (!show) {
      fetchHistory().then(() => fetchParameter()).then(() => setShow(true));
    }
  }, [show]);

  return (
    <div className="flex-grow mx-auto p-5" >
      <h1 className="basa_heading">
        <span className="block text-basa_blue-dark xl:inline"> Parameter :  {parameter.name}</span>
      </h1>

      <div className="w-2/3 mx-auto">
        {saved == true &&
          <Zoom>
            <div className="mx-auto bg-green-600 text-gray-200 rounded-md text-center items-center">
              <span>Parameter Saved</span>
            </div>
          </Zoom>
        }
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-3 pt-3">

          <div className="pt-3">
            <label>Name</label>
            {errors.name && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <input {...register("name", { required: true })} type="input" className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" placeholder="Name" />
          </div>

          <div className="pt-3 col-span-3">
            <label>Value</label>
            {errors.value && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <input {...register("value", { required: true })} className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" placeholder="Value" />
          </div>

          <div className="pt-3">
            <label>From</label><br></br>
            {errors.fromDate && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <input {...register("fromDate", { required: true })} type="date" className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" />
          </div>

          <div className="pt-3">
            <label>To</label><br></br>
            {errors.toDate && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <input {...register("toDate", { required: true })} type="date" className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" />
          </div>
          <div className="pt-3"></div>

        </div>

        <div className="flex mt-2">
          {!parameter.readOnly &&
            <button type="submit" disabled={parameter.readOnly} className="mr-1 group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-basa_blue hover:bg-basa_gray-light hover:text-basa_blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
              Submit
            </button>
          }
          <Link to="/setup/Settings" className="group relative justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-basa_blue hover:bg-basa_gray-light hover:text-basa_blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 mr-1">
            Back
          </Link>
        </div>
      </form>

      <div>

        <h2 className="basa_heading">
          <span className="block text-basa_blue-dark xl:inline"> Parameter History</span>
        </h2>

        <table className="table table-auto mt-4 overflow-scroll">
          <thead>
            <tr>
              <th>From Date</th>
              <th>To Date</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {show && id != 'new' &&
              parameterHistory._embedded.parameters.map((x, i) =>
                <tr>
                  <td className="p-1 px-4">{x.fromDate}</td>
                  <td className="p-1 px-4">{x.toDate}</td>
                  <td className="p-1 px-4">{x.value}</td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
    </div>

  )
}

function Search(props) {
  var parameters = props.parameters;
  var searchParameter = props.searchParameter;
  var parameter = props.parameter;
  return (
    <div className="flex-grow mx-auto pt-2" >
      <h1 className="text-l tracking-tight font-extrabold text-basa_gray sm:text-xl md:text-4xl text-center mt-3">
        <span className="block text-basa_blue-dark xl:inline">System Parameters</span>
      </h1>

      <div className="divide-y divide-smoke-light p-2">
        <div className="mx-auto p-2 w-2/3">
          <input type="search" className="pb-2 mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-basa_blue-light hover:border-gray-700 hover:bg-opacity-40" placeholder="Type search details here..." value={parameter} onChange={e => searchParameter(e.target.value)} />
        </div>
        <div className="pt-2 mx-auto w-2/3">
          {parameters.length > 0 &&
            <div className="mx-auto content-center">
              <table className="table mt-4 overflow-scroll">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Value</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {parameters.map((x, i) =>
                    <Row parameter={x} key={i} path={props.path} url={props.url} />
                  )
                  }
                </tbody>
              </table>
            </div>
          }
          {parameters.length == 0 &&
            <div>
              <span className="text-lg text-red-800">No Parameters Found</span>
            </div>
          }

          <div className="flex my-2 pb-4">
            <Link to="/setup/Settings/new" className="group relative justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-basa_blue hover:bg-basa_gray-light hover:text-basa_blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 mr-1">
              New
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

function Row(props) {
  return (
    <tr className="border-b-2 border-gray-300 text-basa_gray hover:text-basa_blue-darker">
      <td className="p-1 px-4">{props.parameter.name}</td>
      <td className="p-1 px-4">{props.parameter.value}</td>
      <td className="p-1 px-4 ">
        <Link to={`${props.url}/${props.parameter.name}`}>
          <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </Link>
      </td>
    </tr>
  )
}

export { Settings };