import React, { useState, useEffect, useRef } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useParams,
  useRouteMatch
} from 'react-router-dom';

import { MostRecentProposalsReport } from './mostRecentProposalReport';
import { CPInvoicingReport } from './cpInvoicingReport';
import { userService } from '../services/userService';
import '../style.css'

function ReportExtracts(props) {
  const authentication = props.authentication;
  const setAuthentication = props.setAuthentication;
  const [extracts, setExtracts] = useState([]);
  const history = useHistory();
  let { path, url } = useRouteMatch();
  if (!authentication.authenticated) {
    Promise.resolve(userService.checkSession())
      .then((value) => {
        setAuthentication(value);
        if (!value.authenticated) {
          history.push('/');
        }
      })
  } else if (!authentication.permissions.includes("Reports.Extract")) {
    history.push('/app/reports');
  }

  return (
    <div>
      <div className='pt-4'>
        <h1 className="text-xl tracking-tight font-extrabold text-basa_gray sm:text-5xl md:text-6xl text-center mt-3">
          <span className="block text-basa_blue-dark xl:inline">Report Extracts</span>
        </h1>

        <div className='divide-y divide-smoke-light p-2'>
          <div></div>
          <div className="mx-auto self-center p-4">
            <Menu url={url} authentication={props.authentication}></Menu>
          </div>
          <div></div>
        </div>
        
        <Switch>
          <Route path={`${path}/mostRecentProposals`}>
            <MostRecentProposalsReport authentication={props.authentication} setAuthentication={props.setAuthentication} addNotice={props.addNotice} handleError={props.handleError} url={url} />
          </Route>
          <Route path={`${path}/cpInvoicing`}>
            <CPInvoicingReport authentication={props.authentication} setAuthentication={props.setAuthentication} addNotice={props.addNotice} handleError={props.handleError} url={url} />
          </Route>
          <Route path={`${path}`}>
            <MostRecentProposalsReport authentication={props.authentication} setAuthentication={props.setAuthentication} addNotice={props.addNotice} handleError={props.handleError} url={url} />
          </Route>
        </Switch>
      </div>
    </div>
  )
}


function Menu(props) {
  return (
    <div className="flex justify-center">
      <MenuItem url={props.url} name="Most Recent Proposals" path="mostRecentProposals"></MenuItem>
      <MenuItem url={props.url} name="Credit Provider Invoicing" path="cpInvoicing"></MenuItem>
    </div>
  )
}

function MenuItem(props) {
  return (
    <Link to={`${props.url}/${props.path}`}>
      <div className="border-2 m-1 bg-gray-200 p-1 rounded-sm border-basa_gray text-basa_blue hover:text-basa_success-dark hover:bg-gray-100 hover:border-basa_success-dark max-w-xs ">
        <span className="">{props.name}</span>
      </div>
    </Link>
  )
}

export { ReportExtracts };