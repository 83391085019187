import React, { useState } from 'react';
import '../style.css'

import { userService } from '../services/userService';
import { popiActService } from '../services/popiActService';
import { toggleSpinnerOn, toggleSpinnerOff, isSpinnerOn } from '../helpers/spinner';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useParams,
  useRouteMatch
} from "react-router-dom";


function Popi(props) {
  const authentication = props.authentication;
  const setAuthentication = props.setAuthentication;
  let { path, url } = useRouteMatch();
  if (!authentication.authenticated) {
    Promise.resolve(userService.checkSession())
      .then((value) => {
        setAuthentication(value);
        if (!value.authenticated) {
          props.addNotice("Your session has expired, please login again.");
          history.push('/');
        }
      })
  } else if (!authentication.permissions.includes("Tools.Manage")) {
    props.addNotice("Insufficient privileges.");
    history.push('/');
  }

  return (
    <div>
      <PopiInternal authentication={props.authentication} addNotice={props.addNotice} handleError={props.handleError} url={url} />
    </div>
  )
}

function isAdmin(authentication) {
  return authentication.permissions?.includes("Admin");
}

function PopiInternal(props){

  const now = new Date();
  const [popiaDate, setPopiaDate] = useState(now.toISOString().split('T')[0]);

  const buttonRunPopiActSchedulerId = "buttonRunPopiActScheduler";

  function onClickRunPopiActScheduler() {
    try {
      popiActService.runPopiActScheduler(popiaDate, onRunPopiActSchedulerPrepare, onRunPopiActSchedulerSuccess, onRunPopiActSchedulerError);
    } catch (error) {
      console.log("onClickRunPopiActScheduler error=" + error);
    }
  }

  function onRunPopiActSchedulerPrepare() {
    let spanElement = document.getElementById(buttonRunPopiActSchedulerId);
    if (!spanElement) { return false; }

    if (isSpinnerOn()) { return false; }

    toggleSpinnerOn();
    spanElement.textContent = 'Busy...';
    spanElement.classList = '';
    spanElement.classList.add('basa_button_wait');
    return true;
  }

  function onRunPopiActSchedulerSuccess() {
    let spanElement = document.getElementById(buttonRunPopiActSchedulerId);
    if (spanElement) {
      spanElement.textContent = 'Success! Re-RUN?';
      spanElement.classList = '';
      spanElement.classList.add('basa_button_success_retry');
    }

    toggleSpinnerOff();
  }

  function onRunPopiActSchedulerError() {
    let spanElement = document.getElementById(buttonRunPopiActSchedulerId);
    if (spanElement) {

      spanElement.textContent = 'Error! Re-Run?';
      spanElement.classList = '';
      spanElement.classList.add('basa_button_error_retry');
    }

    toggleSpinnerOff();
  }

  return(
    <div className="flex-inline lg:flex-grow mx-auto text-sm">
        <div>
          <h1 className="basa_heading">
            <span className="block text-basa_blue-dark xl:inline">Popi Executor</span>
          </h1>
        </div>
        <div className="p-2">
          <div className="grid grid-cols-1 pt-1">
            <div className="pt-3">
              <label>Execution Date</label>
              <input key="popiaSchedulerDateParameter"
                value={popiaDate}
                onChange={e => setPopiaDate(e.target.value)}
                type="date"
                className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" />
            </div>
            <div className="pt-3">
              <span id="buttonRunPopiActScheduler" onClick={() => onClickRunPopiActScheduler()}
                hidden={!isAdmin(props.authentication)}
                className="basa_button"
              >RUN</span>
            </div>
          </div>
        </div>
      </div>
  )
  }

export { Popi };