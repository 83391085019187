import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { userService } from '../services/userService';
import '../style.css';
import bg from '../img/bg3.jpg';
import { useForm } from "react-hook-form";
import Zoom from 'react-awesome-reveal';


function ResetPassword(props) {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const history = useHistory();
  const [saved, setSaved] = useState(false);
  let { token } = useParams();

  useEffect(() => {
    reset({
      username: '',
      password: ''
    });
  }, []);

  function onSubmit(user) {
    user.token = token;
    userService.updatePassword(user);
    setSaved(true);
    setTimeout(function () {
      history.push("/");
    }, 5000);
  }

  return (
    <>
      <div>

      </div>
      <div className="z-10 md:p-8 flex ">

        <div className="z-10 max-w-md w-full lg:pt-20 float-left">
          <div>
            <h2 className="text-center text-4xl font-extrabold">
              Reset your password
            </h2>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">Email address</label>
                {errors.username && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
                <input {...register("username", { required: true })} type="email" placeholder="email" className="appearance-none rounded-none relative block w-full px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded-t-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" />
              </div>
            </div>

            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="password" className="sr-only">Password</label>
                {errors.password && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
                <input {...register("password", { required: true })} type="password" placeholder="password" className="appearance-none rounded-none relative block w-full px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded-t-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" />
              </div>
            </div>

            {saved == true &&
              <Zoom>
                <div className="mx-auto bg-green-600 text-gray-200 rounded-md text-center text-sm">
                  <span>Password reset. Please login with your new password.</span>
                </div>
              </Zoom>
            }

            <div className="bg pt-2">
              <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-basa_blue hover:bg-basa_gray-light hover:text-basa_blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <svg className="h-5 w-5 text-orange-500 group-hover:text-orange-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                  </svg>
                </span>
                Reset
              </button>
            </div>
          </form>
        </div>

        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <svg className="hidden lg:block lg:absolute left-0 inset-y-0 h-full w-64 text-white min-h-screen" fill="white" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
            <polygon points="0,0 100,0 50,100 0,100" />
          </svg>
          <img className="z-10 hidden lg:block w-full max-h-screen object-cover" src={bg} alt=""></img>
        </div>
      </div>
    </>
  )
}


function RequestPasswordReset(props) {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const history = useHistory();
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    reset({
      username: ''
    });
  }, []);

  function onSubmit(user) {
    userService.requestReset(user.username);
    setSaved(true);
    setTimeout(function () {
      history.push("/");
    }, 5000);
  }

  return (
    <>
      <div>

      </div>
      <div className="z-10 md:p-8 flex ">

        <div className="z-10 max-w-md w-full lg:pt-20 float-left">
          <div>
            <h2 className="text-center text-4xl font-extrabold">
              Reset your password
            </h2>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">Email address</label>
                {errors.subject && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
                <input {...register("username", { required: true })} type="email" placeholder="email" className="appearance-none rounded-none relative block w-full px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded-t-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" />
              </div>
            </div>

            {saved == true &&
              <Zoom>
                <div className="mx-auto bg-green-600 text-gray-200 rounded-md text-center text-sm">
                  <span>Password reset link has been emailed to the supplied email address.</span>
                </div>
              </Zoom>
            }

            <div className="bg pt-2">
              <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-basa_blue hover:bg-basa_gray-light hover:text-basa_blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <svg className="h-5 w-5 text-orange-500 group-hover:text-orange-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                  </svg>
                </span>
                Reset
              </button>
            </div>
          </form>
        </div>

        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <svg className="hidden lg:block lg:absolute left-0 inset-y-0 h-full w-64 text-white min-h-screen" fill="white" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
            <polygon points="0,0 100,0 50,100 0,100" />
          </svg>
          <img className="z-10 hidden lg:block w-full max-h-screen object-cover" src={bg} alt=""></img>
        </div>
      </div>
    </>
  )
}

export { RequestPasswordReset, ResetPassword };

