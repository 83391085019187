import React, { useState, useEffect } from 'react';
import { ProposalService } from '../services/proposalService';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useParams,
  useRouteMatch
} from "react-router-dom";
import Zoom from 'react-awesome-reveal';
import '../style.css'
import { userService } from '../services/userService';
import { fileService } from '../services/fileService';
import { useForm } from "react-hook-form";
import { AssessmentSummary } from './assessmentSummary';
import { RestructuringProposal } from './restructuringProposal';
import { PaymentPlan } from './paymentPlan';
import { formatCurrency } from '../helpers/numberHelper';
import { toggleSpinner, toggleSpinnerOn, toggleSpinnerOff } from '../helpers/spinner';
import { popiActService } from '../services/popiActService';
import { SimulationService } from '../services/simulationService';


function DCRS(props) {
  let { path, url } = useRouteMatch();

  const authentication = props.authentication;
  const setAuthentication = props.setAuthentication;

  const history = useHistory();
  const [idNumber, setIDNumber] = useState(() => "");
  const [searchResults, setSearchResults] = useState([]);

  function updateSearchResults(searchTerm) {
    setIDNumber(searchTerm);
    fetchSearchResults(searchTerm);
  }

  useEffect(() => {
    if (authentication.authenticated && authentication.roles.includes("Admin")) {
      updateSearchResults('');
    }
  }, []);

  async function fetchSearchResults(searchTerm) {
    setSearchResults([]);
    toggleSpinnerOn();
    if (authentication.roles.includes("Admin")) {
      new ProposalService().search(searchTerm, props.handleError).then(result => updateSearch(result)).catch(error => props.handleError("Error", error));
    } else {
      new ProposalService().searchResponseId(searchTerm, props.handleError).then(result => updateSearch(result)).catch(error => props.handleError("Error", error));
    }
  }

  function updateSearch(result) {
    setSearchResults(result)
    toggleSpinnerOff();
  }

  if (!authentication.authenticated) {
    Promise.resolve(userService.checkSession())
      .then((value) => {
        setAuthentication(value)
        if (!value.authenticated) {
          // props.addNotice("Your session has expired, please login again.");
          history.push('/');
        }
      }
      )
  } else if (!authentication.permissions.includes("CreditProvider")) {
    // props.addNotice("Insufficient privileges.");
    history.push('/');
  }

  return (
    <Switch>
      <Route exact path={path}>
        <div className="flex-1 pt-12 w-full lg:w-8/12 mx-auto" >
          <h1 className="text-l tracking-tight font-extrabold text-basa_gray sm:text-xl md:text-4xl text-center mt-3">
            <span className="block text-basa_blue-dark xl:inline">DCRS Case management</span>
          </h1>
          <input type="search" className="pb-2 mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-basa_blue-light hover:border-gray-700 hover:bg-opacity-40" placeholder="Reference Number" value={idNumber} onChange={e => updateSearchResults(e.target.value)} />

          {searchResults.length > 0 &&
            <div className="mx-auto overflow-auto lg:overflow-visible">
              <span className="text-lg">Search Results for {idNumber}</span>
              <table className="table-auto mt-4">
                <thead>
                  <tr>
                    <th>Case Reference</th>
                    <th>Id number</th>
                    <th>First name</th>
                    <th>Last name</th>
                    <th>Type</th>
                    <th>Resolved</th>
                  </tr>
                </thead>
                <tbody>
                  {searchResults.map((x, i) =>
                    <Row dcrs={x} key={i} path={path} url={url} />
                  )
                  }
                </tbody>
              </table>
            </div>
          }
          {searchResults.length == 0 && idNumber &&
            <div>
              <span className="text-lg text-red-800">No Search Results for {idNumber}</span>
            </div>
          }
        </div>
      </Route>
      <Route path={`${url}/:id/assessment_summary`} children={<AssessmentSummary handleError={props.handleError} setAuthentication={setAuthentication} authentication={authentication} />}></Route>
      <Route path={`${url}/:id/restructuring_proposal`} children={<RestructuringProposal handleError={props.handleError} setAuthentication={setAuthentication} authentication={authentication} />}></Route>
      <Route path={`${url}/:id/payment_plan`} children={<PaymentPlan handleError={props.handleError} setAuthentication={setAuthentication} authentication={authentication} />}></Route>
      <Route path={`${url}/:id`} children={<Case handleError={props.handleError} authentication={authentication} setAuthentication={setAuthentication} path={path} />}></Route>
    </Switch>
  )
}

function Row(props) {
  const history = useHistory();
  return (
    <tr className="border-b-2 border-gray-300 text-basa_gray hover:text-basa_blue-darker">
      <td className="p-1 px-4">{props.dcrs.response.responseId}</td>
      <td className="p-1 px-4">{props.dcrs.customer?.primaryCustomer.idNumber}</td>
      <td className="p-1 px-4">{props.dcrs.customer?.primaryCustomer.firstName}</td>
      <td className="p-1 px-4">{props.dcrs.customer?.primaryCustomer.surname}</td>
      <td className="p-1 px-4">{props.dcrs.session.requestType}</td>
      <td className="p-1 px-4">{props.dcrs.response.resolved}</td>
      <td className="p-1 px-4 " onClick={() => { history.push(`${props.url}/${props.dcrs.id}`) }}>
        <Link to={`${props.url}/${props.dcrs.id}`}>
          <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </Link>
      </td>
    </tr>
  )
}

function Case(props) {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  let { id } = useParams();
  const [popiActState, setPopiActState] = useState('')
  const [proposal, setProposal] = useState({});
  const [uniqueReference, setUniqueReference] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [proposalErrors, setProposalErrors] = useState([]);
  const [relatedProposals, setRelatedProposals] = useState([]);
  const [solved, setSolved] = useState(false);
  const authentication = props.authentication;
  const setAuthentication = props.setAuthentication;
  const history = useHistory(); 
  const [isUpdatedPopiActStateRetain, setIsUpdatedPopiActStateRetain] = useState(false);
  const [isUpdatedPopiActStateUnRetain, setIsUpdatedPopiActStateUnRetain] = useState(false);

  

  function isPopiActStateDepersonalised(proposal) {
    var isDisabled = (proposal?.popiActState == undefined ||
      proposal.popiActState == 'DEPERSONALISED');

    return isDisabled;
  }

  // Note: Set CSS disabled for Message Request and Response buttons if PopiActState is Depersonalised
  if (isPopiActStateDepersonalised(proposal)) {
    let buttonRequest = document.getElementById("buttonRequestMessage");
    let buttonResponse = document.getElementById("buttonResponseMessage");
    if (buttonRequest && buttonResponse) {
      buttonRequest.classList = '';
      buttonRequest.classList.add('basa_button_not_allowed');

      buttonResponse.classList = '';
      buttonResponse.classList.add('basa_button_not_allowed');
    }
  }

  function isHiddenPopiRetain(authentication, proposal) {
    if (!isAdmin(authentication)) { return true; }
    if (proposal?.popiActState == undefined) { return true; }

    var isHidden = (proposal.popiActState != 'DEFAULT' && proposal.popiActState != 'IDENTIFIED');
    return isHidden;
  }

  function isHiddenPopiUnRetain(authentication, proposal) {
    if (!isAdmin(authentication)) { return true; }
    if (proposal?.popiActState == undefined) { return true; }

    var isHidden = (proposal.popiActState != 'RETAIN');
    return isHidden;
  }

  function setPopiActProposalRetain(id) {
    toggleSpinnerOn();
    setIsUpdatedPopiActStateRetain(false);
    setIsUpdatedPopiActStateUnRetain(false);

    try {
      popiActService.setStateRetain(id).then(() => {
        setIsUpdatedPopiActStateRetain(true);
        setPopiActState('RETAIN');
        toggleSpinnerOff();  
      });  
    } catch (error) {
      console.log("error=" + error);
      toggleSpinnerOff();
    }
  }

  function setPopiActProposalUnRetain(id) {
    toggleSpinnerOn();
    setIsUpdatedPopiActStateRetain(false);
    setIsUpdatedPopiActStateUnRetain(false);

    try {
      popiActService.setStateUnRetain(id).then(() => {
        setIsUpdatedPopiActStateUnRetain(true);
        setPopiActState('DEFAULT');
        toggleSpinnerOff();
      });
    } catch (error) {
      console.log("error=" + error);
      toggleSpinnerOff();
    }
  }

  function buildSimulation(id)
  {
    toggleSpinnerOn();
    Promise.resolve(new SimulationService().build(id, props.handleError))
      .then((value) => {
        toggleSpinnerOff();
        history.push(`/tools/Simulator/${value.simulationRequest.id}`);
    })
  }

  if (!authentication.authenticated) {
    Promise.resolve(userService.checkSession())
      .then((value) => {
        setAuthentication(value);
        if (!value.authenticated) {
          // props.addNotice("Your session has expired, please login again.");
          history.push('/');
        }
      })
  } else if (!authentication.permissions.includes("CreditProvider")) {
    // props.addNotice("Insufficient privileges.");
    history.push('/');
  }

  useEffect(() => {
    toggleSpinnerOn();
    const fetchProposal = async () => {
      var response = await new ProposalService()
        .fetch(id, props.handleError)
        .then(result => {
          if(result.response.resolved == "ERR") {
            Promise.resolve(new ProposalService().errors(id, props.handleError))
            .then((value) => {
              setProposalErrors(value);
            })
          }
          return result;
      });

      
      new ProposalService()
        .fetchRelated(response.response.responseId, props.handleError)
        .then(result => {
          setRelatedProposals(result);
      });

      setProposal(response);
      setUniqueReference(response.session.uniqueReference);
      setAccounts(response.debtInfo.account);
      

      setSolved(response.response.resolved === "YES");

      if (response.customer.secondaryCustomer == null) {
        response.customer.secondaryCustomer = {}
      }

      reset({
        resolved: response.response.resolved,
        uniqueReference: response.response.responseId,
        requestType: response.session.requestType,
        dcName: response.debtCounselorDetails.dcName,
        dcncrNumber: response.debtCounselorDetails.dcncrNumber,
        dcEmailAddress: response.debtCounselorDetails.emailAddress,
        systemVendor: response.entityInfo.systemVendor,
        pdaName: response.pdaDetails.pdaName,
        pcName: response.customer.primaryCustomer.firstName,
        pcLastName: response.customer.primaryCustomer.surname,
        pcIdNumber: response.customer.primaryCustomer.idNumber,
        scName: response.customer.secondaryCustomer.firstName,
        scLastName: response.customer.secondaryCustomer.surname,
        createDate: response.createDate.split('T')[0],
        migrated: response.migrated,
        popiActState: response.popiActState
      });
    };

    fetchProposal();

    toggleSpinnerOff();
  }, [popiActState, id]);

  return (
    <div className="flex-inline lg:flex-grow mx-auto divide-y divide-smoke-light text-sm">
      <div>
        <h1 className="basa_heading">
          <span className="block text-basa_blue-dark xl:inline"> DCRS Case Details: {uniqueReference} </span>
        </h1>
      </div>
      <div className="p-2">
        <h1 className="small_basa_heading">
          <span className="block text-basa_blue-dark lg:inline"> Summary Details</span>
        </h1>
        <div className="grid grid-cols-1 lg:grid-cols-3 pt-1">
          <div className="pt-1">
            <label>Unique Reference</label>
            <input {...register("uniqueReference")} disabled={true} className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 w-11/12" />
          </div>

          <div className="pt-1">
            <label>Request Type</label>
            <input {...register("requestType")} disabled={true} className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 w-11/12" />
          </div>

          <div className="pt-1">
            <label>Resolved</label>
            <input {...register("resolved")} disabled={true} className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 w-11/12" />
          </div>

          <div className="pt-1">
            <label>Proposal Creation Date</label>
            <input {...register("createDate")} disabled={true} className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 w-11/12" />
          </div>

          <div className="pt-1">
            <label>Legacy Plan</label>
            <input {...register("migrated")} disabled={true} className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 w-11/12" />
          </div>

          <div className="pt-1">
            <label>POPI Status</label>
            <input {...register("popiActState")} disabled={true} className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 w-11/12" />
          </div>
        </div>
      </div>
      <div className="p-2">
        <h1 className="small_basa_heading">
          <span className="block text-basa_blue-dark lg:inline">Debt Counsellor Details</span>
        </h1>
        <div className="grid grid-cols-1 lg:grid-cols-4 pt-1">
          <div className="pt-1">
            <label>Name</label>
            <input {...register("dcName")} disabled={true} className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 w-11/12" />
          </div>
          <div className="pt-1">
            <label>NCR Number</label>
            <input {...register("dcncrNumber")} disabled={true} className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 w-11/12" />
          </div>
          <div className="pt-1">
            <label>Email Address</label>
            <input {...register("dcEmailAddress")} disabled={true} className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 w-11/12" />
          </div>
          <div className="pt-1">
            <label>System Vendor</label>
            <input {...register("systemVendor")} disabled={true} className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 w-11/12" />
          </div>
          <div className="pt-1">
            <label>PDA</label>
            <input {...register("pdaName")} disabled={true} className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 w-11/12" />
          </div>
        </div>
      </div>

      <div className="p-2">
        <h1 className="small_basa_heading">
          <span className="block text-basa_blue-dark lg:inline">Customer Details</span>
        </h1>

        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="border m-2 p-2 rounded">
            <h1 className="small_basa_heading">
              <span className="block text-basa_blue-dark lg:inline">Primary Customer</span>
            </h1>
            <div className="grid grid-cols-1 lg:grid-cols-2 pt-1">
              <div className="pt-1">
                <label>Name</label>
                <input {...register("pcName")} disabled={true} className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 w-11/12" />
              </div>
              <div className="pt-1">
                <label>Last Name</label>
                <input {...register("pcLastName")} disabled={true} className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 w-11/12" />
              </div>
              <div className="pt-1">
                <label>ID Number</label>
                <input {...register("pcIdNumber")} disabled={true} className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 w-11/12" />
              </div>
            </div>
          </div>
          <div className="border m-2 p-2 rounded">
            <h1 className="small_basa_heading">
              <span className="block text-basa_blue-dark lg:inline">Secondary Customer</span>
            </h1>
            <div className="grid grid-cols-1 lg:grid-cols-2 pt-1">
              <div className="pt-1">
                <label>Name</label>
                <input {...register("scName")} disabled={true} className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 w-11/12" />
              </div>
              <div className="pt-1">
                <label>Last Name</label>
                <input {...register("scLastName")} disabled={true} className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 w-11/12" />
              </div>
              <div className="pt-1">
                <label>ID Number</label>
                <input {...register("scIdNumber")} disabled={true} className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10 w-11/12" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-2">
        <h1 className="small_basa_heading">
          <span className="block text-basa_blue-dark lg:inline">Accounts</span>
        </h1>
        <div className="pt-1">
          <div className="mx-auto overflow-auto">
            <table className="table-auto mt-4">
              <thead>
                <tr>
                  <th>Credit Provider</th>
                  <th>Account Type</th>
                  <th>Account Reference</th>
                  <th>Open Date</th>
                  <th>Original Term</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                {accounts.map((account, i) =>
                  <tr key={`account_dcrs_${i}`} className="border-b-2 border-gray-300 text-basa_gray hover:text-basa_blue-darker">
                    <td className="p-1 px-4">{account.creditProviderName}</td>
                    <td className="p-1 px-4">{account.type}</td>
                    <td className="p-1 px-4">{account.accountReference}</td>
                    <td className="p-1 px-4">{account.openDate}</td>
                    <td className="p-1 px-4 text-right">{account.originalTerm}</td>
                    <td className="p-1 px-4 text-right">{formatCurrency(account.cobBalance)}</td>
                  </tr>
                )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="p-2">
        <h1 className="small_basa_heading">
          <span className="block text-basa_blue-dark lg:inline">Related Proposals</span>
        </h1>
        <div className="pt-1">
          <div className="mx-auto overflow-auto">
            {
            isPopiActStateDepersonalised(proposal) == false &&
            <table className="table-auto mt-4">
              <thead>
                <tr>
                  <th>Reference Number</th>
                  <th>Creation Date</th>
                  <th>Creation Time</th>
                  <th>Request Type</th>
                  <th>Solved</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {relatedProposals.sort((a, b) => new Date(a.createDate) - new Date(b.createDate)).map((r, i) =>
                  <tr key={`related_dcrs_${i}`} className="border-b-2 border-gray-300 text-basa_gray hover:text-basa_blue-darker">
                    <td className="p-1 px-4">{r.responseId}</td>
                    <td className="p-1 px-4">{r.createDate.split('T')[0]}</td>
                    <td className="p-1 px-4">{r.createDate.split('T')[1]}</td>
                    <td className="p-1 px-4">{r.requestType}</td>
                    <td className="p-1 px-4">{r.resolved}</td>
                    <td className="p-1 px-4 " >
                      <Link to={`${props.path}/${r.id}`} onClick={ ()=> window.scrollTo(0,0)}>
                        <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </Link>
                    </td>
                  </tr>
                )
                }
              </tbody>
            </table>
            }
            {
            isPopiActStateDepersonalised(proposal) == true &&
            <div className="mx-auto bg-basa_gray text-gray-200 rounded-md text-center py-1">
              <span>This proposal has been depersonalised</span>
            </div>
            }
          </div>
        </div>
      </div>

      {/* Errors */}
      <div>
      {proposal?.response?.resolved == "ERR" &&
        <div className="p-2">
          <h1 className="small_basa_heading">
            <span className="block text-basa_blue-dark lg:inline">Errors</span>
          </h1>
          <div className="grid grid-cols-1 lg:grid-cols-4 pt-1">
            {proposalErrors.map((r, i) =>
              <div className="pt-1">
                <span>{r.reason}</span>
              </div>
            )} 
          </div>
        </div>
        }
      </div>

      <div className="p-2 w-11/12 bg-red">
        <h1 className="small_basa_heading">
          <span className="text-basa_blue-dark">Actions</span>
        </h1>
        <div className="p-2 w-11/12">
          {
            isUpdatedPopiActStateRetain == true &&
            <Zoom>
              <div className="mx-auto bg-basa_gray text-gray-200 rounded-md text-center items-center py-1">
                <span>POPI Status changed to "RETAIN"</span>
              </div>
            </Zoom>
          }
          {
            isUpdatedPopiActStateUnRetain == true &&
            <Zoom>
              <div className="mx-auto bg-basa_gray text-gray-200 rounded-md text-center items-center py-1">
                <span>POPI Status changed to "DEFAULT"</span>
              </div>
            </Zoom>
          }
        </div>
        <div className="pt-1 flex flex-wrap">
          <div className="my-3">
            <Link to={`/dcrs/${id}/assessment_summary`} hidden={!solved}
              className="basa_button">Assessment Summary</Link>
          </div>
          <div className="my-3">
            <Link to={`/dcrs/${id}/restructuring_proposal`} hidden={!solved}
              className="basa_button">Restructuring Proposal</Link>
          </div>
          <div className="my-3">
            <Link to={`/dcrs/${id}/payment_plan`} hidden={!solved}
              className="basa_button">Payment Plan</Link>
          </div>
          <div className="my-3">
            <span id="buttonRequestMessage" onClick={() => downloadRequest(isPopiActStateDepersonalised, proposal, id)} hidden={!isAdmin(authentication) || proposal.migrated == true}
              className="basa_button">Request Message</span>
          </div>
          <div className="my-3">
            <span id="buttonResponseMessage" onClick={() => downloadResponse(isPopiActStateDepersonalised, proposal, id)} hidden={!isAdmin(authentication)}
              className="basa_button">Response Message</span>
          </div>
          <div className="my-3">
            <span onClick={() => downloadLog(proposal, id)} hidden={!isAdmin(authentication) || proposal.migrated == true }
              className="basa_button">Log File</span>
          </div>
          <div className="my-3">
            <span onClick={() => setPopiActProposalRetain(id)} hidden={isHiddenPopiRetain(authentication, proposal)}
              className="basa_button">POPI Retain</span>
          </div>
          <div className="my-3">
            <span onClick={() => setPopiActProposalUnRetain(id)} hidden={isHiddenPopiUnRetain(authentication, proposal)}
              className="basa_button">POPI Un-Retain</span>
          </div>
          <div className="my-3">
            <span onClick={() => buildSimulation(id)} hidden={!isAdmin(authentication)}
              className="basa_button">Load in simulator</span>
          </div>
          <div className="my-3">
            <Link to={`/dcrs`} className="basa_button">Back</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

function isAdmin(authentication) {
  return authentication.permissions?.includes("Admin");
}

function downloadLog(proposal, id) {
  fileService.fetchLogFile(id)
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `log_${proposal.session.uniqueReference}.csv`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
}

function downloadRequest(checkIfDisabled, proposal, id) {
  if (checkIfDisabled(proposal)) {
    return;
  }

  fileService.fetchRequestFile(id)
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `request_${proposal.session.uniqueReference}.xml`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
}

function downloadResponse(checkIfDisabled, proposal, id) {
  if (checkIfDisabled(proposal)) {
    return;
  }

  fileService.fetchResponseFile(id)
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `response_${proposal.session.uniqueReference}.xml`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
}

export { DCRS };
