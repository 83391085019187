import React, { useState } from 'react';
import '../style.css'

import { userService } from '../services/userService';
import { toggleSpinnerOn, toggleSpinnerOff, isSpinnerOn } from '../helpers/spinner';
import { Popi } from './popi';
import { Simulator } from './simulator';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useParams,
  useRouteMatch
} from "react-router-dom";

function Tools(props) {
  const authentication = props.authentication;
  const setAuthentication = props.setAuthentication;
  let { path, url } = useRouteMatch();
  if (!authentication.authenticated) {
    Promise.resolve(userService.checkSession())
      .then((value) => {
        setAuthentication(value);
        if (!value.authenticated) {
          props.addNotice("Your session has expired, please login again.");
          history.push('/');
        }
      })
  } else if (!authentication.permissions.includes("Tools.Manage")) {
    props.addNotice("Insufficient privileges.");
    history.push('/');
  }

  return (
    <div>
      <div className="mx-auto self-center">
          <Menu url={url} authentication={props.authentication}></Menu>
      </div>
      <div className="max-w-7xl justify-center mx-auto">
        <h1 className="text-xl tracking-tight font-extrabold text-basa_gray sm:text-5xl md:text-6xl text-center mt-3">
          <span className="block text-basa_blue-dark xl:inline"> Tools </span>
        </h1>
        <Switch>
          {/* <Route exact path={path}>
            <Simulator authentication={props.authentication} addNotice={props.addNotice} handleError={props.handleError} url={url} />
          </Route> */}
          <Route path={`${path}/popi`}>
              <Popi authentication={props.authentication} setAuthentication={props.setAuthentication} addNotice={props.addNotice} handleError={props.handleError} url={url} />
          </Route>
          <Route path={`${path}/simulator`}>
              <Simulator authentication={props.authentication} setAuthentication={props.setAuthentication} addNotice={props.addNotice} handleError={props.handleError} url={url} />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

function Menu(props) {
  return (
    <div className="flex justify-center">
      <MenuItem url={props.url} name="Simulator" path="simulator"></MenuItem>
      <MenuItem url={props.url} name="Popi" path="popi"></MenuItem>
    </div>
  )
}

function MenuItem(props) {
  return (
    <Link to={`${props.url}/${props.path}`}>
      <div className="border-2 m-1 bg-basa_blue p-1 rounded-sm border-basa_blue text-gray-200 hover:text-basa_blue-darker hover:bg-gray-100 hover:border-gray-100 max-w-xs ">
        <span className="">{props.name}</span>
      </div>
    </Link>
  )
}

function isAdmin(authentication) {
  return authentication.permissions?.includes("Admin");
}

export { Tools };