import React, { useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useParams,
    useRouteMatch
} from "react-router-dom";
import { userService } from '../services/userService';
import { ProposalService } from '../services/proposalService';
import { Account } from './account';
import { fileService } from '../services/fileService';
import '../style.css'
import { toggleSpinner } from '../helpers/spinner';
import { formatCurrency } from '../helpers/numberHelper';

function RestructuringProposal(props) {
    let { path, url } = useRouteMatch();
    let { id } = useParams();
    const [proposal, setProposal] = useState({});
    const [show, setShow] = useState(false);
    const authentication = props.authentication;
    const setAuthentication = props.setAuthentication;
    const [totalDebt, setTotalDebt] = useState(0);
    const [totalMonthlyCommitment, setTotalMonthlyCommitment] = useState(0);
    const [accounts, setAccounts] = useState([]);

    if (!authentication.authenticated) {
        Promise.resolve(userService.checkSession())
            .then((value) => {
                setAuthentication(value)
                if (!value.authenticated) {
                    props.addNotice("Your session has expired, please login again.");
                    history.push('/');
                }
            }
            )
    } else if (!authentication.permissions.includes("CreditProvider")) {
        props.addNotice("Insufficient privileges.");
        history.push('/');
    }

    useEffect(() => {
        const fetchProposal = async () => {
            var response = await new ProposalService().fetchRestructuringProposal(id, props.handleError).then((result) => {toggleSpinner(); return result});
            setProposal(response);
            setTotalDebt(calculateTotalDebt(response));
            setTotalMonthlyCommitment(calculateTotalMonthlyCommitment(response));
            setAccounts(response.debtInfo.account);
            setShow(true);
        }
        toggleSpinner();
        fetchProposal();
    }, []);

    function calculateTotalDebt(response) {
        return response.debtInfo.account.map((account) => account.originalBalance).reduce(add, 0);
    }

    function calculateTotalMonthlyCommitment(response) {
        var num = response.debtInfo.account.map((account) => account.deductionAmount).reduce(add, 0);
        return Math.round(num * 100) / 100;
    }

    function add(accumulator, a) {
        return accumulator + a;
    }

    return (
        <>
            <Switch>
                <Route exact path={path}>
                    {show &&
                        <div className="flex-grow mx-auto divide-y divide-smoke-light p-2 text-sm">
                            <div>
                                <h1 className="basa_heading">
                                    <span className="block text-basa_blue-dark xl:inline">Debt Restructuring Proposal</span>
                                </h1>
                            </div>
                            <div className="grid grid-cols-3">
                                <div>
                                    <div className="p-2">
                                        <h1 className="small_basa_heading">
                                            <span className="block text-basa_blue-dark lg:inline">Consumer (Applicant) Details</span>
                                        </h1>
                                    </div>
                                    <div className="p-2">
                                        <table className="table-auto mt-4 overflow-scroll">
                                            <tbody>
                                                <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                    <td className="p-1 px-4"><strong>Name:</strong></td>
                                                    <td className="p-1 px-4">{proposal.customer.primaryCustomer.firstName} {proposal.customer.primaryCustomer.surname}</td>
                                                </tr>
                                                <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                    <td className="p-1 px-4"><strong>ID Number:</strong></td>
                                                    <td className="p-1 px-4">{proposal.customer.primaryCustomer.idNumber}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div>
                                    <div className="p-2">
                                        <h1 className="small_basa_heading">
                                            <span className="block text-basa_blue-dark lg:inline">Secondary Consumer (Applicant) Details</span>
                                        </h1>
                                    </div>
                                    <div className="p-2">
                                        <table className="table-auto mt-4 overflow-scroll">
                                            <tbody>
                                                <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                    <td className="p-1 px-4"><strong>Name:</strong></td>
                                                    <td className="p-1 px-4">{proposal.customer.secondaryCustomer?.firstName} {proposal.customer?.secondaryCustomer?.surname}</td>
                                                </tr>
                                                <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                    <td className="p-1 px-4"><strong>ID Number:</strong></td>
                                                    <td className="p-1 px-4">{proposal.customer.secondaryCustomer?.idNumber}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div>
                                    <div className="p-2">
                                        <h1 className="small_basa_heading">
                                            <span className="block text-basa_blue-dark lg:inline">Enquiry Details</span>
                                        </h1>
                                    </div>
                                    <div className="p-2">
                                        <table className="table-auto mt-4 overflow-scroll">
                                            <tbody>
                                                <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                    <td className="p-1 px-4"><strong>DC Reference Number:</strong></td>
                                                    <td className="p-1 px-4">{proposal.session.uniqueReference}</td>
                                                </tr>
                                                <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                    <td className="p-1 px-4"><strong>Request Type:</strong></td>
                                                    <td className="p-1 px-4">{proposal.session.requestType}</td>
                                                </tr>
                                                <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                    <td className="p-1 px-4"><strong>Date:</strong></td>
                                                    <td className="p-1 px-4">{proposal.createDate.split('T')[0]}</td>
                                                    <td className="p-1 px-4"><strong>Time:</strong></td>
                                                    <td className="p-1 px-4">{proposal.createDate.split('T')[1]}</td>
                                                </tr>
                                                <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                    <td className="p-1 px-4"><strong>Legacy Plan:</strong></td>
                                                    <td className="p-1 px-4">{`${proposal.migrated}`}</td>
                                                </tr>
                                                <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                    <td className="p-1 px-4"><strong>Rule Set Used:</strong></td>
                                                    <td className="p-1 px-4">{proposal.session.version}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-3">
                                <div>
                                    <div className="p-2">
                                        <h1 className="small_basa_heading">
                                            <span className="block text-basa_blue-dark lg:inline">Debt Counsellor</span>
                                        </h1>
                                    </div>
                                    <div className="p-2">
                                        <table className="table-auto mt-4 overflow-scroll">
                                            <tbody>
                                                <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                    <td className="p-1 px-4"><strong>Name:</strong></td>
                                                    <td className="p-1 px-4">{proposal.debtCounselorDetails.dcName}</td>
                                                </tr>
                                                <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                    <td className="p-1 px-4"><strong>NCR Number:</strong></td>
                                                    <td className="p-1 px-4">{proposal.debtCounselorDetails.dcncrNumber}</td>
                                                </tr>
                                                <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                    <td className="p-1 px-4"><strong>Telephone Number:</strong></td>
                                                    <td className="p-1 px-4">{proposal.debtCounselorDetails.telAreaCode} {proposal.debtCounselorDetails.telNumber}</td>
                                                </tr>
                                                <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                    <td className="p-1 px-4"><strong>Fax Number:</strong></td>
                                                    <td className="p-1 px-4">{proposal.debtCounselorDetails.faxAreaCode} {proposal.debtCounselorDetails.faxNumber}</td>
                                                </tr>
                                                <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                    <td className="p-1 px-4"><strong>Email Address:</strong></td>
                                                    <td className="p-1 px-4">{proposal.debtCounselorDetails.emailAddress}</td>
                                                </tr>
                                                <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                    <td className="p-1 px-4 align-text-top"><strong>Postal Address:</strong></td>
                                                    <td className="p-1 px-4">
                                                        {proposal.debtCounselorDetails.dcAddressLine1}
                                                        <br />
                                                        {proposal.debtCounselorDetails.dcAddressLine2}
                                                        <br />
                                                        {proposal.debtCounselorDetails.dcAddressLine3}
                                                        <br />
                                                        {proposal.debtCounselorDetails.dcPostalCode}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div>
                                    <div className="p-2">
                                        <h1 className="small_basa_heading">
                                            <span className="block text-basa_blue-dark lg:inline">Debt Counsellor Motivation</span>
                                        </h1>
                                    </div>
                                    <div className="p-2">
                                        <textarea readOnly={true} rows="5" value={proposal.debtCounselorDetails.dcMotivation} className="w-11/12 appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" />
                                    </div>
                                </div>
                                <div>
                                    <div className="p-2">
                                        <h1 className="small_basa_heading">
                                            <span className="block text-basa_blue-dark lg:inline">PDA Details</span>
                                        </h1>
                                    </div>
                                    <div className="p-2">
                                        <table className="table-auto mt-4 overflow-scroll">
                                            <tbody>
                                                <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                    <td className="p-1 px-4"><strong>Name:</strong></td>
                                                    <td className="p-1 px-4">{proposal.pdaDetails.pdaName}</td>
                                                </tr>
                                                <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                    <td className="p-1 px-4"><strong>Code:</strong></td>
                                                    <td className="p-1 px-4">{proposal.pdaDetails.pdaCode}</td>
                                                </tr>
                                                <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                    <td className="p-1 px-4"><strong>Email Address:</strong></td>
                                                    <td className="p-1 px-4">{proposal.pdaDetails.emailAddress}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-3">
                                <div className="col-span-3">
                                    <div className="p-2">
                                        <h1 className="small_basa_heading">
                                            <span className="block text-basa_blue-dark lg:inline">Debt Summary</span>
                                        </h1>
                                    </div>
                                    <div className="p-2">
                                        <table className="table-auto mt-4 overflow-scroll">
                                            <tbody>
                                                <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                    <td className="p-1 px-4"><strong>Total Debt:</strong></td>
                                                    <td className="p-1 px-4 text-right">{formatCurrency(totalDebt)}</td>

                                                    <td className="p-1 px-4"><strong>1st Collection Date:</strong></td>
                                                    <td className="p-1 px-4 text-right">{proposal.debtInfo.firstCollectionDate}</td>
                                                </tr>
                                                <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                    <td className="p-1 px-4"><strong>Total Montly Commitment:</strong></td>
                                                    <td className="p-1 px-4 text-right">{formatCurrency(totalMonthlyCommitment)}</td>

                                                    <td className="p-1 px-4"><strong>Estimated Date Of Distribution To Preferrential Creditors:</strong></td>
                                                    <td className="p-1 px-4 text-right">{proposal.debtInfo.firstDistributionDate}</td>
                                                </tr>
                                                <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                    <td className="p-1 px-4"><strong>Affordability After CLI, DC &amp; PDA Fees :</strong></td>
                                                    <td className="p-1 px-4 text-right">{formatCurrency(proposal.generalInfo.affordability.assessmentSummary.grossDebtAffordability)}</td>

                                                    <td className="p-1 px-4"><strong>Estimated Date Of Distribution To Concurrent Creditors:</strong></td>
                                                    <td className="p-1 px-4 text-right">{proposal.debtInfo.firstCPDistributionDate}</td>
                                                </tr>
                                                <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                    <td className="p-1 px-4"><strong>Over Indebtness %:</strong></td>
                                                    <td className="p-1 px-4 text-right">{Math.round(totalMonthlyCommitment / proposal.generalInfo.affordability.assessmentSummary.grossDebtAffordability * 100) / 100} </td>

                                                    <td className="p-1 px-4"><strong>Delay In Distributions :</strong></td>
                                                    <td className="p-1 px-4 text-right">{proposal.debtInfo.interimPeriod}</td>
                                                </tr>
                                                <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                    <td className="p-1 px-4"><strong>Method of Payment :</strong></td>
                                                    <td className="p-1 px-4 text-right">{proposal.debtInfo.methodOfPayment} </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-3">
                                <div className="col-span-3">
                                    <div className="p-2">
                                        <h1 className="small_basa_heading">
                                            <span className="block text-basa_blue-dark lg:inline">Accounts</span>
                                        </h1>
                                    </div>
                                    <div className="p-2">
                                        <table className="table-fixed overflow-scroll">
                                            <thead>
                                                <tr>
                                                    <th className="w-40 px-1">Credit Provider</th>
                                                    <th className="w-40 px-1">Account Reference</th>
                                                    <th className="w-6 px-1">Type</th>
                                                    <th className="w-16 px-1">Balance</th>
                                                    <th className="w-6 px-1">Balance Source</th>
                                                    <th className="w-28 px-1">Date of Balance</th>
                                                    <th className="w-10 px-1">Interest Rate</th>
                                                    <th className="w-10 px-1">Monthly Fees &amp; Premiums</th>
                                                    <th className="w-10 px-1">Repayment Amount</th>
                                                    <th className="w-10 px-1">Repayment Term</th>
                                                    <th className="w-10 px-1">Remaining Term</th>
                                                    <th className="w-10 px-1">Expired Term</th>
                                                    <th className="w-32 px-1 bg-gray-600 text-white">Proposed Fixed Rate For Debt Review Period</th>
                                                    <th className="w-8 px-1 bg-gray-600 text-white">Proposed Extended Term</th>
                                                    <th className="w-8 px-1 bg-gray-600 text-white">Estimated Opening Balance</th>
                                                    <th className="w-8 px-1 bg-gray-600 text-white">Proposed Monthly Fees &amp; Premiums</th>
                                                    <th className="w-32 px-1 bg-gray-600 text-white">Proposed Fixed Payment (Excluding Fees &amp; Premiums)</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {accounts.map((account, i) =>
                                                    <tr key={`account_dcrs_${i}_proposal`} className="border-b-2 border-gray-300 text-basa_gray">
                                                        <td>{account.creditProviderName}</td>
                                                        <td>{account.accountReference}</td>
                                                        <td className="border-l border-r p-1 text-center">{account.type}</td>
                                                        <td className="border-l border-r p-1 text-right">{formatCurrency(account.cobBalance)}</td>
                                                        <td className="border-l border-r p-1 text-center" >{account.balanceSource}</td>
                                                        <td className="border-l border-r p-1 text-center">{account.cobDate}</td>
                                                        <td className="border-l border-r p-1 text-right">{account.currentRate} %</td>
                                                        <td className="border-l border-r p-1 text-right">{formatCurrency(account.monthlyAdminFees + account.monthlyPremiums)}</td>
                                                        <td className="border-l border-r p-1 text-right">{formatCurrency(account.deductionAmount)}</td>
                                                        <td className="border-l border-r p-1 text-right">{account.originalTerm}</td>
                                                        <td className="border-l border-r p-1 text-right">{account.originalTerm - account.restructuredDebt.expiredTerm}</td>
                                                        <td className="border-l border-r p-1 text-right">{account.restructuredDebt.expiredTerm}</td>
                                                        <td className="border-l border-r border-gray-300 p-1 text-right bg-gray-600 text-white">{account.restructuredDebt.revisedRateLegacy} %</td>
                                                        <td className="border-l border-r border-gray-300 p-1 text-right bg-gray-600 text-white">{account.restructuredDebt.term}</td>
                                                        <td className="border-l border-r border-gray-300 p-1 text-right bg-gray-600 text-white">{formatCurrency(account.restructuredDebt.balance)}</td>
                                                        <td className="border-l border-r border-gray-300 p-1 text-right bg-gray-600 text-white">{formatCurrency(account.monthlyPremiums)}</td>
                                                        <td className="border-l border-r border-gray-300 p-1 text-right bg-gray-600 text-white">{formatCurrency(account.restructuredDebt.paymentObligation)}</td>

                                                        <td className="p-1 px-4 ">
                                                            <Link to={`${url}/account/${account.id}`}>
                                                                <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                </svg>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="p-2">
                                <h1 className="small_basa_heading">
                                    <span className="block text-basa_blue-dark lg:inline">Actions</span>
                                </h1>
                                <div className="pt-1 flex">
                                    <div className="flex my-2">
                                        <Link to={`/dcrs/${id}/assessment_summary`} className="basa_button">Assessment Summary</Link>
                                    </div>
                                    <div className="flex my-2">
                                        <Link to={`/dcrs/${id}/payment_plan`} className="basa_button">Payment Plan</Link>
                                    </div>
                                    <div className="flex my-2">
                                        <span onClick={() => downloadRestructureProposal(proposal, id)} className="basa_button">
                                            Download
                                        </span>
                                    </div>
                                    <div className="flex my-2">
                                        <Link to={`/dcrs/${id}`} className="basa_button">
                                            Back
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </Route>
                <Route path={`${url}/account/:id`} children={<Account handleError={props.handleError} proposal={proposal} setAuthentication={setAuthentication} authentication={authentication} />}></Route>
            </Switch>
        </>
    )
}

function downloadRestructureProposal(proposal, id) {
    toggleSpinner();
    fileService.fetchRestructureProposalFile(id)
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `restructure_proposal_${proposal.session.uniqueReference}.pdf`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
            toggleSpinner();
        });
        
}

export { RestructuringProposal };