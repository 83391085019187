const React = require('react');

import '../style.css'

function About(props) {
    
    return (
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                <div className="sm:text-center">
                    <h1 className="text-4xl tracking-tight font-extrabold text-basa_gray sm:text-5xl md:text-6xl">
                        <span className="block text-basa_blue-dark xl:inline"> DCRS</span>
                        <span className="block xl:inline"> - Debt Counselling Rules System</span>
                    </h1>
                    <p className="mt-3 text-base text-gray-500 sm:text-lg sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                    Debt Counselling Rules System (or 'DCRS') is a joint initiative between Banks and Debt Counselling System Providers to house approved debt restructuring rules for debt review under the National Credit Act and serve as a platform for credit providers to validate debt counselling proposals generated through the approved rules. 
                    </p>
                </div>
            </main>
        </div>
    )
    
}

export { About };