import React, { useState } from 'react';
import { useHistory, Link, } from 'react-router-dom';
import { userService } from '../services/userService';
import '../style.css';
import bg from '../img/bg3.jpg';
import Zoom from 'react-awesome-reveal';


function Login(props, setAuthentication) {

  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [invalidPassword,    setInvalidPassword]    = useState(false);
  const [loginFailedToFetch, setLoginFailedToFetch] = useState(false);
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setInvalidPassword(false);
      setLoginFailedToFetch(false);

      var user = await userService.login(username, password, props.handleError);
      if (!user) {
        setInvalidPassword(true);
      } else {
        props.setAuthentication(user);
        if (user.authenticated) {
          if (user.permissions.includes('DCRS.Proposal')) {
            history.push('/dcrs');
          } else {
            history.push('/reports');
          }
        }
      }
    } catch (error) {
      if (error) {
        setLoginFailedToFetch(true);
      } else {
        setInvalidPassword(true);
      }
    }
  }

  return (
    <div className="z-10 p-8 lg:flex text-4xl">
      <div className="z-10 lg:max-w-md w-full lg:pt-20 float-left">
        <div>
          <h2 className="text-center text-4xl font-extrabold">
            Sign in to your account
          </h2>
        </div>
        <form className="mt-8" onSubmit={handleSubmit}>
          <input type="hidden" name="remember" value="true"></input>

          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">Email address</label>
              <input id="email-address" name="username" value={username} onChange={e => setUserName(e.target.value)} type="email" autoComplete="email" required className="appearance-none rounded-none relative block w-full px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded-t-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" placeholder="Email address" autoFocus></input>
            </div>
            <div>
              <label htmlFor="password" className="sr-only">Password</label>
              <input id="password" name="password" value={password} onChange={e => setPassword(e.target.value)} type="password" autoComplete="current-password" required className="appearance-none rounded-none relative block w-full px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" placeholder="Password"></input>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="text-sm">
              <Link to="/request_password_reset" className="font-medium text-basa_blue hover:text-basa_blue-light">
                Forgot your password?
              </Link>
            </div>
          </div>

          {invalidPassword == true &&
          <div className="p-2">
            <Zoom>
              <div className="mx-auto bg-basa_error-light text-basa_error-dark rounded-md text-center text-sm">
                <span>Invalid password. Please try again.</span>
              </div>
            </Zoom>
          </div>
          }
          {loginFailedToFetch == true &&
          <div className="p-2">
            <Zoom>
              <div className="mx-auto bg-basa_error-light text-basa_error-dark rounded-md text-center text-sm">
                <span>Login failed. Could not fetch user credentials.</span>
              </div>
            </Zoom>
          </div>
          }

          <div className="bg">
            <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-basa_blue hover:bg-basa_gray-light hover:text-basa_blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <svg className="h-5 w-5 text-orange-500 group-hover:text-orange-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                </svg>
              </span>
              Sign in
            </button>
          </div>
        </form>
      </div>

      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <svg className="hidden lg:block lg:absolute left-0 inset-y-0 h-full w-64 text-white min-h-screen" fill="white" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
          <polygon points="0,0 100,0 50,100 0,100" />
        </svg>
        <img className="z-10 hidden lg:block w-full max-h-screen object-cover" src={bg} alt=""></img>
      </div>

    </div>
  )
}

export { Login };

