import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useParams,
  useRouteMatch
} from "react-router-dom";
import { useForm } from "react-hook-form";
import { noticeService } from '../services/noticeService';
import { userService } from '../services/userService';
import Zoom from 'react-awesome-reveal';
import '../style.css';

function Notices(props) {

  const authentication = props.authentication;
  const history = useHistory();
  const [notices, setNotices] = useState([]);
  const [content, setContent] = useState('');
  let { path, url } = useRouteMatch();

  if (!authentication.authenticated || !authentication.roles.includes("Admin")) {
    Promise.resolve(userService.checkSession())
      .then((value) => {
        if (!value.authenticated) {
          props.addNotice("Your session has expired, please login again.");
          history.push('/');
        }})
  }

  useEffect(() => {
    if (content == '') {
      const fetchNotices = async () => {
        var notices = await noticeService.getAll(props.handleError);
        setNotices(notices._embedded.notices);
      }
      fetchNotices();
    }
  }, []);

  function searchContent(searchTerm) {
    setContent(searchTerm);
    Promise.resolve(noticeService.search(searchTerm, props.handleError))
      .then((value) =>
        setNotices(value._embedded.notices)
      )
  }

  return (
    <Switch>
      <Route exact path={path}>
        <Search notices={notices} setNotices={setNotices} searchContent={searchContent} content={content} path={path} url={url}></Search>
      </Route>
      <Route path={`${props.url}/:it/:id`} children={<Notice handleError={props.handleError} />}></Route>
    </Switch >
  )
}

function Notice(props) {
  let { id } = useParams();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [notice, setNotice] = useState([]);
  const [saved, setSaved] = useState(false);

  function onSubmit(notice) {
    if (typeof notice.id === 'undefined') {
      Promise.resolve(noticeService.create(notice, props.handleError))
        .then((value) => {
          setSaved(true);
          id = value.id;
          setNotice(value);
          resetFields(value);
        })
    } else {
      Promise.resolve(noticeService.update(id, notice, props.handleError))
        .then((value) =>
          setSaved(true)
        )
    }
  }

  function resetFields(notice){
    reset({
      subject: notice.subject,
      content: notice.content,
      enabled: notice.enabled,
      showOnHomeScreen: notice.showOnHomeScreen,
      fromDate: notice.fromDate,
      toDate: notice.toDate,
      id: notice.id
    });
  }

  useEffect(() => {
    const fetchNotice = async () => {
      if (id != 'new') {
        var notice = await noticeService.fetchNotice(id, props.handleError);
        setNotice(notice);
        resetFields(notice);
      }
    }
    fetchNotice();
  },[]);

  return (
    <div className="flex-grow mx-auto p-5" >
      <h1 className="basa_heading">
        <span className="block text-basa_blue-dark xl:inline"> Notice: {notice.id}</span>
      </h1>

      <div className="w-2/3 mx-auto">
        {saved == true &&
          <Zoom>
            <div className="mx-auto bg-green-600 text-gray-200 rounded-md text-center items-center">
              <span>Notice Saved</span>
            </div>
          </Zoom>
        }
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-3 pt-3">

          <div className="pt-3">
            <label>Subject</label>
            {errors.subject && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <input {...register("subject", { required: true })} type="input" className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" placeholder="Subject" />
          </div>

          <div className="pt-3 col-span-3">
            <label>Content</label>
            {errors.content && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <textarea {...register("content", { required: true })} className="w-full appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" placeholder="Content" />
          </div>

          <div className="pt-3">
            <label>From</label><br></br>
            {errors.fromDate && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <input {...register("fromDate", { required: true })} type="date" className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" />
          </div>

          <div className="pt-3">
            <label>To</label><br></br>
            {errors.toDate && <span className="text-red-900 text-xs block"><i><strong>This field is required</strong></i></span>}
            <input {...register("toDate", { required: true })} type="date" className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" />
          </div>
          <div className="pt-3"></div>

          <div className="pt-3">
            <label>Enabled</label><br></br>
            <input {...register("enabled", { required: false })} type="checkbox" className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" />
          </div>

          <div className="pt-3">
            <label>Show On Home Screen</label><br></br>
            <input {...register("showOnHomeScreen", { required: false })} type="checkbox" className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10" />
          </div>

        </div>

        <div className="flex mt-2">
          <button type="submit" className="mr-1 group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-basa_blue hover:bg-basa_gray-light hover:text-basa_blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
            Submit
          </button>
          <Link to="/setup/Notices" className="group relative justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-basa_blue hover:bg-basa_gray-light hover:text-basa_blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 mr-1">
            Back
          </Link>
        </div>
      </form>
    </div>
  )

}

function Search(props) {
  var notices = props.notices;
  var searchContent = props.searchContent;
  var content = props.content;
  return (
    <div className="flex-grow mx-auto pt-2" >
      <h1 className="text-l tracking-tight font-extrabold text-basa_gray sm:text-xl md:text-4xl text-center mt-3">
        <span className="block text-basa_blue-dark xl:inline">Notice Board</span>
      </h1>

      <div className="divide-y divide-smoke-light p-2">
        <div className="mx-auto p-2 w-2/3">
          <input type="search" className="pb-2 mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-basa_blue-light hover:border-gray-700 hover:bg-opacity-40" placeholder="Type search details here..." value={content} onChange={e => searchContent(e.target.value)} />
        </div>
        <div className="pt-2 mx-auto w-2/3">
          {notices.length > 0 &&
            <div className="mx-auto content-center">
              <table className="table mt-4 overflow-scroll">
                <thead>
                  <tr>
                    <th>Subject</th>
                    <th>Enabled</th>
                    <th>Home Screen</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {notices.map((x, i) =>
                    <Row notice={x} key={i} path={props.path} url={props.url} />
                  )
                  }
                </tbody>
              </table>
            </div>
          }
          {notices.length == 0 &&
            <div>
              <span className="text-lg text-red-800">No Notices Found</span>
            </div>
          }

          <div className="flex my-2">
            <Link to="/setup/Notices/new" className="group relative justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-basa_blue hover:bg-basa_gray-light hover:text-basa_blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 mr-1">
              New
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

function Row(props) {
  return (
    <tr className="border-b-2 border-gray-300 text-basa_gray hover:text-basa_blue-darker">
      <td className="p-1 px-4">{props.notice.subject.substring(0,20)}...</td>
      <td className="p-1 px-4">{props.notice.enabled ? '✓' : 'X'}</td>
      <td className="p-1 px-4">{props.notice.showOnHomeScreen ? '✓' : 'X'}</td>
      <td className="p-1 px-4 ">
        <Link to={`${props.url}/${props.notice.id}`}>
          <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </Link>
      </td>
    </tr>
  )
}

export { Notices };