import React, { useState, useEffect } from 'react';
import { reportService } from '../services/reportService';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  BarSeries,
  DiscreteColorLegend,
  Hint,
  LabelSeries,
  LineSeries,
  ChartLabel,
  FlexibleWidthXYPlot
} from 'react-vis';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useParams,
  useRouteMatch
} from "react-router-dom";

import { userService } from '../services/userService';
import '../style.css';

function UsageReports(props) {
  const now = new Date();
  const yearAgo = new Date(new Date().setFullYear(now.getFullYear() - 1));
  const fourYearAgo = new Date(new Date().setFullYear(now.getFullYear() - 4));
  const authentication = props.authentication;
  const setAuthentication = props.setAuthentication;
  const history = useHistory();
  const [startDate, setStartDate] = useState(yearAgo.toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(now.toISOString().split('T')[0]);

  const [staticStartDate, setStaticStartDate] = useState(fourYearAgo.toISOString().split('T')[0]);
  const [staticEndDate, setStaticEndDate] = useState(now.toISOString().split('T')[0]);

  if (!authentication.authenticated) {
    Promise.resolve(userService.checkSession())
      .then((value) => {
        setAuthentication(value)
        if (!value.authenticated) {
          history.push('/');
        }
      });
  } else if (!authentication.permissions.includes("Reports.Usage")) {
    history.push('/app/reports');
  }

  return (
    <div>
      <div className="grid grid-cols-3 pt-3">
        <div className="pt-3">
          <label>From</label><br />
          <input
            key="report_start_date"
            value={startDate}
            onChange={e => setStartDate(e.target.value)}
            type="date"
            className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10"
          />
        </div>
        <div className="pt-3">
          <label>To</label><br />
          <input
            key="report_end_date"
            value={endDate}
            onChange={e => setEndDate(e.target.value)}
            type="date"
            className="appearance-none relative block px-2 py-1 border border-gray-300 placeholder-basa_blue-light text-basa_blue rounded rounded-b-md focus:outline-none focus:ring-basa_blue-light focus:border-basa_blue-light focus:z-10"
          />
        </div>
        <div className="pt-3"></div>
      </div>
      <div className="text-xs">
      <div className="flex-1 pt-16 w-9/12">
          <NumberOfProposals handleError={props.handleError} startDate={staticStartDate} endDate={staticEndDate} />
        </div>
      <div className="flex-1 pt-16 w-9/12">
          <AVGAffordability handleError={props.handleError} startDate={startDate} endDate={endDate} />
        </div>
      <div className="flex-1 pt-16 w-9/12">
          <AVGAffordabilityPercentage handleError={props.handleError} startDate={startDate} endDate={endDate} />
        </div>
      <div className="flex-1 pt-16 w-9/12">
          <AvgTotalIncomeVsAvgLegalFees handleError={props.handleError} startDate={startDate} endDate={endDate} />
        </div>
      <div className="flex-1 pt-16 w-9/12">
          <AvgTotalIncomeVsAvgLegalFeesPercentage handleError={props.handleError} startDate={startDate} endDate={endDate} />
        </div>
      <div className="flex-1 pt-16 w-9/12">
          <PercentageDI handleError={props.handleError} startDate={startDate} endDate={endDate} />
        </div>
      </div>
    </div>
  );
}

function PercentageDI(props) {
  const [DI, setDI] = useState([]);
  const [notDI, setNotDI] = useState([]);
  const [currentValue, setCurrentValue] = useState([]);

  useEffect(() => {
    const fetchReportData = async () => {
      reportService.usageReportingStats(props.startDate, props.endDate, props.handleError)
        .then((response) => {
          var data = response._embedded.usageReportingStatsReports;
          var _DI = data.filter(it => it.reportType === 'DI')
            .map(it => {
              var fullValue = data.filter(t => t.reportType !== 'DI').filter(dit => dit.miMonth === it.miMonth);
              let obj = {};
              obj['x'] = it.miMonth;
              obj['y'] = Math.round((it.latestProposalCount / fullValue[0].latestProposalCount) * 100);
              return obj;
            });
          setDI(_DI);

          var _notDI = data.filter(it => it.reportType !== 'DI')
            .map(it => {
              var diValue = data.filter(t => t.reportType === 'DI').filter(dit => dit.miMonth === it.miMonth);
              let obj = {};
              obj['x'] = it.miMonth;
              obj['y'] = Math.round(((it.latestProposalCount - diValue[0].latestProposalCount) / it.latestProposalCount) * 100);
              return obj;
            });
          setNotDI(_notDI);
        });
    }
    fetchReportData();
  }, [props.startDate, props.endDate]);

  return (
    <>
      <div>
        <h1 className="small_basa_heading text-center">
          <span className="block text-basa_blue-dark lg:inline">Percentage DR Cases Eligible for DI</span>
        </h1>
        <div className="content-center mx-auto p-0">
          <FlexibleWidthXYPlot
            xType="ordinal"
            stackBy="y"
            height={300}
            margin={{ left: 85 }}
            className="relative"
            animation
          >
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis />
            <YAxis />
            <VerticalBarSeries
              color="#374152"
              data={DI}
              onValueMouseOver={(val, info) => {
                setCurrentValue(val);
              }}
            />
            <VerticalBarSeries
              color="#64C790"
              data={notDI}
              onValueMouseOver={(val, info) => {
                setCurrentValue(val);
              }}
            />
            {currentValue.y && (
              <Hint value={currentValue}>
                <div className="border-green-500 border-2 rounded bg-gray-100 p-2">
                  <p>{currentValue.y}</p>
                </div>
              </Hint>
            )}
          </FlexibleWidthXYPlot>
        </div>
      </div>

      <div className="pl-16">
        <table className="table-auto mt-4">
          <thead>
            <tr>
              <th></th>
              {DI.map((x, i) => (
                <th className="px-2">{x.x}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className="border-b-2 border-gray-300 text-basa_gray">
              <th className={`proposal_text_color text-left`} style={{ color: '#374152' }}>DI</th>
              {DI.map((x, i) => (
                <td className="border-l border-r p-1 text-right">{x.y} %</td>
              ))}
            </tr>
            <tr className="border-b-2 border-gray-300 text-basa_gray">
              <th className={`prepare_text_color text-left`} style={{ color: '#64C790' }}>Non - DI</th>
              {notDI.map((x, i) => (
                <td className="border-l border-r p-1 text-right">{x.y} %</td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

function AvgTotalIncomeVsAvgLegalFeesPercentage(props) {
  const [income, setIncome] = useState([]);
  const [legal, setLegal] = useState([]);
  const [currentValue, setCurrentValue] = useState([]);

  useEffect(() => {
    const fetchReportData = async () => {
      reportService.usageReportingStats(props.startDate, props.endDate, props.handleError)
        .then((response) => {
          var data = response._embedded.usageReportingStatsReports;
          var _income = data.filter(it => it.reportType === 'FULL')
            .map(it => {
              let obj = {};
              obj['x'] = it.miMonth;
              obj['y'] = Math.round(calculatePercentage(it.averageNetIncome + it.averageAffordability, it.averageNetIncome + it.averageAffordability + it.averageLegalFee));
              return obj;
            });
          setIncome(_income);

          var _legal = data.filter(it => it.reportType === 'FULL')
            .map(it => {
              let obj = {};
              obj['x'] = it.miMonth;
              obj['y'] = Math.round(calculatePercentage(it.averageLegalFee, it.averageNetIncome + it.averageAffordability + it.averageLegalFee));
              return obj;
            });
          setLegal(_legal);
        });
    }
    fetchReportData();
  }, [props.startDate, props.endDate]);

  return (
    <>
      <div>
        <h1 className="small_basa_heading text-center">
          <span className="block text-basa_blue-dark lg:inline">Average Legal-Fees vs Average Total income per month as Percentage</span>
        </h1>
        <div className="content-center mx-auto p-0">
          <FlexibleWidthXYPlot
            xType="ordinal"
            stackBy="y"
            height={300}
            margin={{ left: 85 }}
            className="relative"
            animation
          >
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis />
            <YAxis />
            <VerticalBarSeries
              color="#374152"
              data={legal}
              onValueMouseOver={(val, info) => {
                setCurrentValue(val);
              }}
            />
            <VerticalBarSeries
              color="#64C790"
              data={income}
              onValueMouseOver={(val, info) => {
                setCurrentValue(val);
              }}
            />
            {currentValue.y && (
              <Hint value={currentValue}>
                <div className="border-green-500 border-2 rounded bg-gray-100 p-2">
                  <p>{currentValue.y}</p>
                </div>
              </Hint>
            )}
          </FlexibleWidthXYPlot>
        </div>
      </div>

      <div className="pl-16">
        <table className="table-auto mt-4">
          <thead>
            <tr>
              <th></th>
              {income.map((x, i) => (
                <th className="px-2">{x.x}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className="border-b-2 border-gray-300 text-basa_gray">
              <th className={`proposal_text_color text-left`} style={{ color: '#374152' }}>Average Legal Fees</th>
              {legal.map((x, i) => (
                <td className="border-l border-r p-1 text-right">{x.y} %</td>
              ))}
            </tr>
            <tr className="border-b-2 border-gray-300 text-basa_gray">
              <th className={`prepare_text_color text-left`} style={{ color: '#64C790' }}>Average Total Income</th>
              {income.map((x, i) => (
                <td className="border-l border-r p-1 text-right">{x.y} %</td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

function AvgTotalIncomeVsAvgLegalFees(props) {
  const [income, setIncome] = useState([]);
  const [legal, setLegal] = useState([]);
  const [currentValue, setCurrentValue] = useState([]);

  useEffect(() => {
    const fetchReportData = async () => {
      reportService.usageReportingStats(props.startDate, props.endDate, props.handleError)
        .then((response) => {
          var data = response._embedded.usageReportingStatsReports;
          var _income = data.filter(it => it.reportType === 'FULL')
            .map(it => {
              let obj = {};
              obj['x'] = it.miMonth;
              obj['y'] = it.averageNetIncome + it.averageAffordability;
              return obj;
            });
          setIncome(_income);

          var _legal = data.filter(it => it.reportType == 'FULL')
            .map(it => {
              let obj = {};
              obj['x'] = it.miMonth;
              obj['y'] = it.averageLegalFee;
              return obj;
            });
          setLegal(_legal);
        });
    }
    fetchReportData();
  }, [props.startDate, props.endDate]);

  return (
    <>
      <div>
        <h1 className="small_basa_heading text-center">
          <span className="block text-basa_blue-dark lg:inline">Average Legal-Fees vs Average Total income per month</span>
        </h1>
        <div className="content-center mx-auto p-0">
          <FlexibleWidthXYPlot
            xType="ordinal"
            //stackBy="y"
            height={300}
            margin={{ left: 85 }}
            className="relative"
            animation
          >
           
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis />
            <YAxis />
            <VerticalBarSeries
              color="#374152"
              data={legal}
              onValueMouseOver={(val, info) => {
                setCurrentValue(val);
              }}
            />
            <VerticalBarSeries
              color="#64C790"
              data={income}
              onValueMouseOver={(val, info) => {
                setCurrentValue(val);
              }}
            />
            {currentValue.y &&
              <Hint value={currentValue}>
                <div className="border-green-500 border-2 rounded bg-gray-100 p-2">
                  <p>{currentValue.y}</p>
                </div>
              </Hint>
            }
          </FlexibleWidthXYPlot>
        </div>
      </div>
      
      <div className="pl-16">
        <table className="table-auto mt-4">
            <thead>
              <tr>
                <th></th>
                {income.map((x, i) =>
                  <th className="px-2">{x.x}</th>
                              )
                }
              </tr>
            </thead>
            <tbody>
              <tr className="border-b-2 border-gray-300 text-basa_gray">
                <th className={`proposal_text_color text-left`} style={{ color: '#374152' }}>Average Legal Fees</th>
                {legal.map((x, i) =>
                    <td className="border-l border-r p-1 text-right">{x.y}</td>
                                )
                }
              </tr>
              <tr className="border-b-2 border-gray-300 text-basa_gray">
                <th className={`prepare_text_color text-left`} style={{ color: '#64C790' }}>Average Total Income</th>
                {income.map((x, i) =>
                    <td className="border-l border-r p-1 text-right">{x.y}</td>
                                )
                }
              </tr>
            </tbody>
        </table>
      </div>
      </>
  );
}

function AVGAffordabilityPercentage(props) {
  const [afford, setAfford] = useState([]);
  const [income, setIncome] = useState([]);
  const [currentValue, setCurrentValue] = useState([]);

  useEffect(() => {
    const fetchReportData = async () => {
      reportService.usageReportingStats(props.startDate, props.endDate, props.handleError)
        .then((response) => {
          var data = response._embedded.usageReportingStatsReports;
          var _afford = data.filter(it => it.reportType === 'FULL')
            .map(it => {
              let obj = {};
              obj['x'] = it.miMonth;
              obj['y'] = Math.round(calculatePercentage(it.averageAffordability, it.averageAffordability + it.averageNetIncome));
              return obj;
            });
          setAfford(_afford);

          var _income = data.filter(it => it.reportType === 'FULL')
            .map(it => {
              let obj = {};
              obj['x'] = it.miMonth;
              obj['y'] = Math.round(calculatePercentage(it.averageNetIncome, it.averageAffordability + it.averageNetIncome));
              return obj;
            });
          setIncome(_income);
        });
    }
    fetchReportData();
  }, [props.startDate, props.endDate]);

  return (
    <>
      <div>
        <h1 className="small_basa_heading text-center">
          <span className="block text-basa_blue-dark lg:inline">Average Affordability as portion of Average Total Income per month as Percentage</span>
        </h1>
        <div className="content-center mx-auto p-0">
          <FlexibleWidthXYPlot
            xType="ordinal"
            stackBy="y"
            height={300}
            margin={{ left: 85 }}
            className="relative"
            animation
          >
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis />
            <YAxis />
            <VerticalBarSeries
              color="#374152"
              data={afford}
              onValueMouseOver={(val, info) => {
                setCurrentValue(val);
              }}
            />
            <VerticalBarSeries
              color="#64C790"
              data={income}
              onValueMouseOver={(val, info) => {
                setCurrentValue(val);
              }}
            />
            {currentValue.y && (
              <Hint value={currentValue}>
                <div className="border-green-500 border-2 rounded bg-gray-100 p-2">
                  <p>{currentValue.y}</p>
                </div>
              </Hint>
            )}
          </FlexibleWidthXYPlot>
        </div>
      </div>

      <div className="pl-16">
        <table className="table-auto mt-4">
          <thead>
            <tr>
              <th></th>
              {afford.map((x, i) => (
                <th className="px-2">{x.x}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className="border-b-2 border-gray-300 text-basa_gray">
              <th className={`proposal_text_color text-left`} style={{ color: '#374152' }}>Average Affordability</th>
              {afford.map((x, i) => (
                <td className="border-l border-r p-1 text-right">{x.y} %</td>
              ))}
            </tr>
            <tr className="border-b-2 border-gray-300 text-basa_gray">
              <th className={`prepare_text_color text-left`} style={{ color: '#64C790' }}>Average Net Income</th>
              {income.map((x, i) => (
                <td className="border-l border-r p-1 text-right">{x.y} %</td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

function AVGAffordability(props) {
  const [afford, setAfford] = useState([]);
  const [netIncome, setNetIncome] = useState([]);
  const [currentValue, setCurrentValue] = useState([]);

  useEffect(() => {
    const fetchReportData = async () => {
      reportService.usageReportingStats(props.startDate, props.endDate, props.handleError)
        .then((response) => {
          var data = response._embedded.usageReportingStatsReports;
          var _afford = data.filter(it => it.reportType === 'FULL')
            .map(it => {
              let obj = {};
              obj['x'] = it.miMonth;
              obj['y'] = it.averageAffordability;
              return obj;
            });
          setAfford(_afford);

          var _netIncome = data.filter(it => it.reportType === 'FULL')
            .map(it => {
              let obj = {};
              obj['x'] = it.miMonth;
              obj['y'] = it.averageNetIncome;
              return obj;
            });
          setNetIncome(_netIncome);
        });
    }
    fetchReportData();
  }, [props.startDate, props.endDate]);

  return (
    <>
      <div>
        <h1 className="small_basa_heading text-center">
          <span className="block text-basa_blue-dark lg:inline">Average Affordability as portion of Average Total Income per month</span>
        </h1>
        <div className="content-center mx-auto p-0">
          <FlexibleWidthXYPlot
            xType="ordinal"
            stackBy="y"
            height={300}
            margin={{ left: 85 }}
            className="relative"
            animation
          >
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis />
            <YAxis />
            <VerticalBarSeries
              color="#374152"
              data={afford}
              onValueMouseOver={(val, info) => {
                setCurrentValue(val);
              }}
            />
            <VerticalBarSeries
              color="#64C790"
              data={netIncome}
              onValueMouseOver={(val, info) => {
                setCurrentValue(val);
              }}
            />
            {currentValue.y && (
              <Hint value={currentValue}>
                <div className="border-green-500 border-2 rounded bg-gray-100 p-2">
                  <p>{currentValue.y}</p>
                </div>
              </Hint>
            )}
          </FlexibleWidthXYPlot>
        </div>
      </div>

      <div className="pl-16">
        <table className="table-auto mt-4">
          <thead>
            <tr>
              <th></th>
              {afford.map((x, i) => (
                <th className="px-2">{x.x}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className="border-b-2 border-gray-300 text-basa_gray">
              <th className={`proposal_text_color text-left`} style={{ color: '#374152' }}>Average Affordability</th>
              {afford.map((x, i) => (
                <td className="border-l border-r p-1 text-right">{x.y}</td>
              ))}
            </tr>
            <tr className="border-b-2 border-gray-300 text-basa_gray">
              <th className={`prepare_text_color text-left`} style={{ color: '#64C790' }}>Average Net Income</th>
              {netIncome.map((x, i) => (
                <td className="border-l border-r p-1 text-right">{x.y}</td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

function NumberOfProposals(props) {
  const [yearOne, setYearOne] = useState([]);
  const [yearTwo, setYearTwo] = useState([]);
  const [yearThree, setYearThree] = useState([]);
  const [yearFour, setYearFour] = useState([]);
  const [currentValue, setCurrentValue] = useState([]);

  const now = new Date();

  useEffect(() => {
    const fetchReportData = async () => {
      reportService.usageReportingStats(props.startDate, props.endDate, props.handleError)
        .then((response) => {
          var data = response._embedded.usageReportingStatsReports;
          var _yearOne = data.filter(it => it.reportType === 'FULL' && it.miMonth.slice(0, 4) == now.getFullYear())
            .map(it => {
              let obj = {};
              obj['x'] = determineMonth(it.miMonth.slice(5, 7));
              obj['y'] = it.latestProposalCount;
              return obj;
            });
            setYearOne(_yearOne);

            var _yearTwo = data.filter(it => it.reportType === 'FULL' && it.miMonth.slice(0, 4) == now.getFullYear() - 1)
            .map(it => {
              let obj = {};
              obj['x'] = determineMonth(it.miMonth.slice(5, 7));
              obj['y'] = it.latestProposalCount;
              return obj;
            });
            setYearTwo(_yearTwo);

            var _yearThree = data.filter(it => it.reportType === 'FULL' && it.miMonth.slice(0, 4) == now.getFullYear() - 2)
            .map(it => {
              let obj = {};
              obj['x'] = determineMonth(it.miMonth.slice(5, 7));
              obj['y'] = it.latestProposalCount;
              return obj;
            });
            setYearThree(_yearThree);

            var _yearFour = data.filter(it => it.reportType === 'FULL' && it.miMonth.slice(0, 4) == now.getFullYear() - 3)
            .map(it => {
              let obj = {};
              obj['x'] = determineMonth(it.miMonth.slice(5, 7));
              obj['y'] = it.latestProposalCount;
              return obj;
            });
            setYearFour(_yearFour);
        });
    }
    fetchReportData();
  }, [props.staticStartDate, props.staticEndDate]);

  return (
    <>
      <div>
        <h1 className="small_basa_heading text-center">
          <span className="block text-basa_blue-dark lg:inline">DCRS Usage: Number Of Proposals on DCRS Average Monthly Year on Year</span>
        </h1>
        <div className="content-center mx-auto p-0">
          <FlexibleWidthXYPlot
            xType="ordinal"
            //stackBy="y"
            height={300}
            margin={{ left: 85 }}
            className="relative"
            animation
          >
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis />
            <YAxis />
            <VerticalBarSeries
              color="#954557"
              data={yearOne}
              onValueMouseOver={(val, info) => {
                setCurrentValue(val);
              }}
            />
            <VerticalBarSeries
              color="#f69333"
              data={yearTwo}
              onValueMouseOver={(val, info) => {
                setCurrentValue(val);
              }}
            />
            <VerticalBarSeries
              color="#374152"
              data={yearThree}
              onValueMouseOver={(val, info) => {
                setCurrentValue(val);
              }}
            />
            <VerticalBarSeries
              color="#64C790"
              data={yearFour}
              onValueMouseOver={(val, info) => {
                setCurrentValue(val);
              }}
            />
            {currentValue.y && (
              <Hint value={currentValue}>
                <div className="border-green-500 border-2 rounded bg-gray-100 p-2">
                  <p>{currentValue.y}</p>
                </div>
              </Hint>
            )}
          </FlexibleWidthXYPlot>
        </div>
      </div>

      <div className="pl-16">
        <table className="table-auto mt-4">
        <colgroup>
            {/* Set equal width for each column */}
            <col style={{ width: `${100 / yearTwo.length}%` }} />
            {yearTwo.map((x, i) => (
              <col key={i} style={{ width: `${100 / yearTwo.length}%` }} />
            ))}
          </colgroup>
          <thead>
            <tr>
              <th></th>
              {yearTwo.map((x, i) => (
                <th className="px-2">{x.x}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className="border-b-2 border-gray-300 text-basa_gray">
              <th className={"proposal_text_color text-left"} style={{color: '#954557'}}>{now.getFullYear()}</th>
              {yearOne.map((x, i) => (
                <td className="border-l border-r p-1 text-right">{x.y}</td>
              ))}
            </tr>
            <tr className="border-b-2 border-gray-300 text-basa_gray">
              <th className={"prepare_text_color text-left"} style={{color: '#f69333'}}>{now.getFullYear() - 1}</th>
              {yearTwo.map((x, i) => (
                <td className="border-l border-r p-1 text-right">{x.y}</td>
              ))}
            </tr>
            <tr className="border-b-2 border-gray-300 text-basa_gray">
              <th className="confirm_text_color text-left">{now.getFullYear() - 2}</th>
              {yearThree.map((x, i) => (
                <td className="border-l border-r p-1 text-right">{x.y}</td>
              ))}
            </tr>
            <tr className="border-b-2 border-gray-300 text-basa_gray">
              <th className={`proposal_text_color text-left`} style={{ color: '#64C790' }}>{now.getFullYear() - 3}</th>
              {yearFour.map((x, i) => (
                <td className="border-l border-r p-1 text-right">{x.y}</td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

function determineMonth(data) {
  let month_list = ['January', 'February', 'March', 'April', 'May', "June", 'July', 'August', 'September', 'October', 'November', 'December'];
  let month_number_list = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  let monthIndex = month_number_list.indexOf(data);

  return month_list[monthIndex];
}

function calculatePercentage(value, total) {
  return ((value / total) * 100).toFixed(2);
}

export { UsageReports };
