const React = require('react');
const ReactDOM = require('react-dom');

import bg from '../img/bg.jpg';
import '../style.css'

class Home extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div className="relative z-10 pb-8 bg-white lg:w-1/2">
          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-tight font-extrabold text-basa_gray sm:text-5xl lg:text-6xl">
                <span className="block text-basa_blue-dark xl:inline">DCRS</span>
                <span className="block xl:inline text-basa_gray"> - Debt Counselling Rules System</span>
              </h1>
              <p className="mt-3 text-base text-basa_gray sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                Debt Counselling Rules System (or 'DCRS') is a joint initiative between Banks and Debt Counselling System Providers to house approved debt restructuring rules for debt review under the National Credit Act and serve as a platform for credit providers to validate debt counselling proposals generated through the approved rules.
              </p>
              <div className="mt-5 sm:mt-8 flex justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <a href="/app/login" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-basa_blue-dark hover:bg-basa_blue-light lg:py-4 lg:text-lg lg:px-10">
                    Sign In
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>

        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 ">
          <svg className="hidden lg:block lg:absolute left-0 inset-y-0 h-full w-64 text-white min-h-screen" fill="white" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
            <polygon points="0,0 100,0 50,100 0,100" />
          </svg>
          <img className="z-10 hidden lg:block max-h-screen w-full object-cover" src={bg} alt=""></img>
        </div>
      </div>
    )
  }
}

export { Home };