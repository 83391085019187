import { apiUrl } from '../helpers/urlHelper' ;
import { userService } from './userService';

class SimulationService {

    async search(searchTerm, mineOnly, handleError) {

        const headers = userService.getAuthHeader();
        headers['Content-Type'] = 'application/json'

        const requestOptions = {
            method: 'GET',
            headers: headers
        }

        var result = await fetch(`${apiUrl()}/api/simulationRequests/search?searchTerm=${searchTerm}&mineOnly=${mineOnly}`, requestOptions)
            .then(response => response.json())
            .catch(error => handleError("Error", error));

        return result;
    }

    async fetch(id, handleError) {
        const headers = userService.getAuthHeader();
        headers['Content-Type'] = 'application/json'

        const requestOptions = {
            method: 'GET',
            headers: headers
        }

        var result = await fetch(`${apiUrl()}/api/simulationRequests/${id}`, requestOptions)
            .then(response => response.json())
            .catch(error => handleError("Error", error));

        return result;
    }

    async delete(id, handleError) {
        const headers = userService.getAuthHeader();
        headers['Content-Type'] = 'application/json'

        const requestOptions = {
            method: 'DELETE',
            headers: headers
        }

        var result = await fetch(`${apiUrl()}/api/simulationRequests/${id}`, requestOptions)
            .catch(error => handleError("Error", error));

        return result;
    }


    async create(simulationRequest, handleError) {
        const headers = userService.getAuthHeader();
        headers['Content-Type'] = 'application/json'

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(simulationRequest)
        }

        var result = await fetch(`${apiUrl()}/api/simulationRequests`, requestOptions)
            .then(response => response.json())
            .catch(error => handleError("Error", error));

        return result;
    }

    async simulate(id, handleError) {
        const headers = userService.getAuthHeader();
        headers['Content-Type'] = 'application/json'

        const requestOptions = {
            method: 'POST',
            headers: headers,
        }

        var result = await fetch(`${apiUrl()}/api/simulationRequests/${id}/simulate`, requestOptions)
            .then(response => response.json())
            .catch(error => handleError("Error", error));

        return result;
    }

    async build(id, handleError) {
        const headers = userService.getAuthHeader();
        headers['Content-Type'] = 'application/json'

        const requestOptions = {
            method: 'POST',
            headers: headers,
        }

        var result = await fetch(`${apiUrl()}/api/simulationRequests/${id}/build`, requestOptions)
            .then(response => response.json())
            .catch(error => handleError("Error", error));

        return result;
    }

    async update(id, simulationRequest, handleError) {
        const headers = userService.getAuthHeader();
        headers['Content-Type'] = 'application/json'

        const requestOptions = {
            method: 'PATCH',
            headers: headers,
            body: JSON.stringify(simulationRequest)
        }

        var result = await fetch(`${apiUrl()}/api/simulationRequests/${id}`, requestOptions)
            .then(response => response.json())
            .catch(error => handleError("Error", error));

        return result;
    }

    async dcrses(id, handleError) {
        const headers = userService.getAuthHeader();
        headers['Content-Type'] = 'application/json'

        const requestOptions = {
            method: 'GET',
            headers: headers            
        }

        var result = await fetch(`${apiUrl()}/api/simulationRequests/${id}/dcrses?projection=DCRSLightProjection`, requestOptions)
            .then(response => response.json())
            .catch(error => handleError("Error", error));

        return result;
    }
}

export { SimulationService }

