import config from 'config';

export function apiUrl() {
  if (config.environment != 'production') {
    return config.apiUrl;
  } else {
    return window.location.origin;
  }
}

export function ticketApiUrl() {
  return config.ticketApiUrl;
}
