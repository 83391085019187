import { apiUrl } from '../helpers/urlHelper' ;
import { userService } from './userService';

export const roleService = {
    getAll,
    create,
    userRoles
};

function getAll() {
    const headers = userService.getAuthHeader();
    headers['Content-Type'] = 'application/json'

    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(`${apiUrl()}/api/roles`, requestOptions)
            .then(response => response.json())
            .catch(error => handleError("Error", error));
}


function create(role) {
    const headers = userService.getAuthHeader();
    headers['Content-Type'] = 'application/json'

    const requestOptions = {
        method : 'POST',
        headers: headers,
        body: JSON.stringify(user)
    }

    var result = fetch(`${apiUrl()}/api/roles`, requestOptions)
        .then(response => response.json())
        .catch(error => handleError("Error", error));

    return result;
}

function userRoles(userId) {
    const headers = userService.getAuthHeader();
    headers['Content-Type'] = 'application/json'

    const requestOptions = {
        method : 'GET',
        headers: headers
    }

    var result = fetch(`${apiUrl()}/api/roles/search/findByUsersId?id=${userId}`, requestOptions)
        .then(response => response.json())
        .catch(error => handleError("Error", error));

    return result;
}