import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useHistory
} from "react-router-dom";

import { userService } from '../services/userService';
import '../style.css'
import { MonthlyReports } from './monthlyReports';
import { UsageReports } from './usageReports';
import { ReportExtracts } from './reportExtracts';
import { canViewReports } from '../helpers/reportsHelper';

function Reports(props) {
  const authentication = props.authentication;
  const setAuthentication = props.setAuthentication;
  let { path, url } = useRouteMatch();

  if (!authentication.authenticated) {
    Promise.resolve(userService.checkSession())
      .then((value) => {
        setAuthentication(value)
        if (!value.authenticated) {          
          history.push('/');
        }
      }
      )
  } else if (!canViewReports(authentication.permissions)) {
    history.push('/');
  }

  return (
    <div>
      <div className="mx-auto self-center">
          <Menu url={url} authentication={props.authentication}></Menu>
      </div>
      <div>
        <Switch>
          <Route path={`${path}/monthlyReports`}>
              <MonthlyReports authentication={props.authentication} setAuthentication={props.setAuthentication} addNotice={props.addNotice} handleError={props.handleError} url={url} />
          </Route>
          <Route path={`${path}/usageReports`}>
              <UsageReports authentication={props.authentication} setAuthentication={props.setAuthentication} addNotice={props.addNotice} handleError={props.handleError} url={url} />
          </Route>
          <Route path={`${path}/reportExtracts`}>
              <ReportExtracts authentication={props.authentication} setAuthentication={props.setAuthentication} addNotice={props.addNotice} handleError={props.handleError} url={url} />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

function Menu(props) {
  let authentication = props.authentication;
  return (
    <div className="flex justify-center">
      {authentication.authenticated == "true" && authentication.permissions.includes('Reports.Monthly') &&
        <MenuItem url={props.url} name="Monthly Reports" path="monthlyReports"></MenuItem>
      }
      {authentication.authenticated == "true" && authentication.permissions.includes('Reports.Usage') &&
        <MenuItem url={props.url} name="Usage Reports" path="usageReports"></MenuItem>
      }
      {authentication.authenticated == "true" && authentication.permissions.includes('Reports.Extract') &&
        <MenuItem url={props.url} name="Report Extracts" path="reportExtracts/mostRecentProposals"></MenuItem>
      }
    </div>
  )
}

function MenuItem(props) {
  return (
    <Link to={`${props.url}/${props.path}`}>
      <div className="border-2 m-1 bg-basa_blue p-1 rounded-sm border-basa_blue text-gray-200 hover:text-basa_blue-darker hover:bg-gray-100 hover:border-gray-100 max-w-xs ">
        <span className="">{props.name}</span>
      </div>
    </Link>
  )
}

export { Reports };