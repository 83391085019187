const React = require('react');

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useParams,
    useRouteMatch
} from "react-router-dom";

import { Settings } from './settings.js'
import { CreditProviders } from "./creditProvider.js";
import { Users } from "./user.js";
import { Notices } from "./notice.js";

import '../style.css'


function Setup(props) {

    let { path, url } = useRouteMatch();
    return (
        <div>
            <div className="divide-y divide-fuchsia-300">
                <div></div>
                <div className="mx-auto self-center">
                    <Menu url={url} authentication={props.authentication}></Menu>
                </div>
                <div className="max-w-7xl justify-center mx-auto">
                    <Switch>
                        <Route exact path={path}>
                            <h1 className="text-xl tracking-tight font-extrabold text-basa_gray sm:text-5xl md:text-6xl text-center mt-3">
                                <span className="block text-basa_blue-dark xl:inline">Setup</span>
                            </h1>
                            {props.authentication.authenticated == "true" && props.authentication.permissions.includes('Settings') &&
                                <Settings authentication={props.authentication} addNotice={props.addNotice} handleError={props.handleError} url={url} />
                            }
                        </Route>
                        <Route path={`${path}/Settings`}>
                            <Settings authentication={props.authentication} addNotice={props.addNotice} handleError={props.handleError} url={url} />
                        </Route>
                        <Route path={`${path}/CreditProviders`}>
                            <CreditProviders authentication={props.authentication} addNotice={props.addNotice} handleError={props.handleError} url={url} />
                        </Route>
                        <Route path={`${path}/Users`}>
                            <Users authentication={props.authentication} addNotice={props.addNotice} handleError={props.handleError} url={url} />
                        </Route>
                        <Route path={`${path}/Notices`}>
                            <Notices authentication={props.authentication} addNotice={props.addNotice} handleError={props.handleError} url={url} />
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    )
}

function Menu(props) {
    return (
        <div className="flex justify-center">
            {props.authentication.authenticated == "true" && props.authentication.permissions.includes('User.Manage') &&
              <MenuItem url={props.url} name="User Management" path="Users"></MenuItem>
            }

            {props.authentication.authenticated == "true" && props.authentication.permissions.includes('CreditProvider.Manage') &&
              <MenuItem url={props.url} name="Credit Provider Management" path="CreditProviders"></MenuItem>
            }
            
            {props.authentication.authenticated == "true" && props.authentication.permissions.includes('Notice') &&
              <MenuItem url={props.url} name="Notice Board" path="Notices"></MenuItem>
            }

            {props.authentication.authenticated == "true" && props.authentication.permissions.includes('Settings') &&
              <MenuItem url={props.url} name="System Parameters" path="Settings"></MenuItem>
            }
        </div>
    )
}

function MenuItem(props) {
    return (
        <Link to={`${props.url}/${props.path}`}>
            <div className="border-2 m-1 bg-basa_blue p-1 rounded-sm border-basa_blue text-gray-200 hover:text-basa_blue-darker hover:bg-gray-100 hover:border-gray-100 max-w-xs ">
                <span className="">{props.name}</span>
            </div>
        </Link>
    )
}

export { Setup };