import React, { useState, useEffect } from 'react';
import { ProposalService } from '../services/proposalService';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useParams,
    useRouteMatch
} from "react-router-dom";
import '../style.css'
import { userService } from '../services/userService';
import { formatCurrency } from '../helpers/numberHelper';
import { fileService } from '../services/fileService';
import { toggleSpinner } from '../helpers/spinner';

function AssessmentSummary(props) {
    let { id } = useParams();
    const [proposal, setProposal] = useState({});
    const [show, setShow] = useState(false);
    const authentication = props.authentication;
    const setAuthentication = props.setAuthentication;
    const [totalIncome, setTotalIncome] = useState(0);
    const [nettIncome, setNettIncome] = useState(0);
    const [otherIncome, setOtherIncome] = useState(0);
    const [totalGross, setTotalGross] = useState(0);

    if (!authentication.authenticated) {
        Promise.resolve(userService.checkSession())
            .then((value) => {
                setAuthentication(value)
                if (!value.authenticated) {
                    props.addNotice("Your session has expired, please login again.");
                    history.push('/');
                }
            }
            )
    } else if (!authentication.permissions.includes("CreditProvider")) {
        props.addNotice("Insufficient privileges.");
        history.push('/');
    }

    useEffect(() => {
        const fetchProposal = async () => {
            var response = await new ProposalService().fetchAssessment(id, props.handleError).then((result) => {toggleSpinner(); return result;});
            setProposal(response);
            setShow(true);
            var _total = response.generalInfo.affordability.primaryCustomer.income.totalIncome.original;
            if (response.generalInfo.affordability.secondaryCustomer != null) {
                _total += response.generalInfo.affordability.secondaryCustomer.income.totalIncome.original;
            }
            setTotalIncome(_total);

            var _totalNet = response.generalInfo.affordability.primaryCustomer.income.nett.original;
            if (response.generalInfo.affordability.secondaryCustomer != null) {
                _totalNet += response.generalInfo.affordability.secondaryCustomer.income.nett.original;
            }
            setNettIncome(_totalNet);

            var _other = response.generalInfo.affordability.primaryCustomer.income.otherIncome.original;
            if (response.generalInfo.affordability.secondaryCustomer != null) {
                _other += response.generalInfo.affordability.secondaryCustomer.income.otherIncome.original;
            }
            setOtherIncome(_other);

            var _gross = response.generalInfo.affordability.primaryCustomer.income.gross.original;
            if (response.generalInfo.affordability.secondaryCustomer != null) {
                _gross += response.generalInfo.affordability.secondaryCustomer.income.gross.original;
            }
            setTotalGross(_gross);
        }
        toggleSpinner();
        fetchProposal();
    }, []);

    function percentageOfTotalIncome(amount) {
        return Math.round(amount / totalIncome * 10000) / 100
    }

    return (
        <>
            {show &&
                <div className="flex-grow mx-auto divide-y divide-smoke-light p-2 text-sm">
                    <div>
                        <h1 className="basa_heading">
                            <span className="block text-basa_blue-dark xl:inline">Assessment summary</span>
                        </h1>
                    </div>

                    <div className="grid grid-cols-3">
                        <div>
                            <div className="p-2">
                                <h1 className="small_basa_heading">
                                    <span className="block text-basa_blue-dark lg:inline">Debt Counsellor</span>
                                </h1>
                            </div>
                            <div className="p-2">
                                <table className="table-auto mt-4 overflow-scroll">
                                    <tbody>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2"><strong>Name:</strong></td>
                                            <td className="p-1 px-2">{proposal.debtCounselorDetails.dcName}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2"><strong>NCR Number:</strong></td>
                                            <td className="p-1 px-2">{proposal.debtCounselorDetails.dcncrNumber}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div>
                            <div className="p-2">
                                <h1 className="small_basa_heading">
                                    <span className="block text-basa_blue-dark lg:inline">Consumer (Applicant) Details</span>
                                </h1>
                            </div>
                            <div className="p-2">
                                <table className="table-auto mt-4 overflow-scroll">
                                    <tbody>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2"><strong>Name:</strong></td>
                                            <td className="p-1 px-2">{proposal.customer.primaryCustomer.firstName} {proposal.customer.primaryCustomer.surname}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2"><strong>ID Number:</strong></td>
                                            <td className="p-1 px-2">{proposal.customer.primaryCustomer.idNumber}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div>
                            <div className="p-2">
                                <h1 className="small_basa_heading">
                                    <span className="block text-basa_blue-dark lg:inline">Enquiry Details</span>
                                </h1>
                            </div>
                            <div className="p-2">
                                <table className="table-auto mt-4 overflow-scroll">
                                    <tbody>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2"><strong>DC Reference Number:</strong></td>
                                            <td className="p-1 px-2">{proposal.session.uniqueReference}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2"><strong>Request Type:</strong></td>
                                            <td className="p-1 px-2">{proposal.session.requestType}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2"><strong>Date:</strong></td>
                                            <td className="p-1 px-2">{proposal.createDate.split('T')[0]}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2"><strong>Time:</strong></td>
                                            <td className="p-1 px-2">{proposal.createDate.split('T')[1]}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2"><strong>Legacy Plan:</strong></td>
                                            <td className="p-1 px-2">{`${proposal.migrated}`}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="p-2">
                        <h1 className="small_basa_heading">
                            <span className="block text-basa_blue-dark lg:inline">Payslip deductions</span>
                        </h1>
                        <div className="grid grid-cols-3 pt-2">
                            <div>
                                <strong>Primary Customer</strong>
                                <table className="table-auto mt-4 overflow-scroll">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Original</th>
                                            <th className="pl-1">Revised</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Pension Fund</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.expenses.payslipDeductions.pensionFund.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.expenses.payslipDeductions.pensionFund.revised)}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Medical Aid</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.expenses.payslipDeductions.medicalAid.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.expenses.payslipDeductions.medicalAid.revised)}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Loans</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.expenses.payslipDeductions.loans.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.expenses.payslipDeductions.loans.revised)}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Union Subscription</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.expenses.payslipDeductions.unionSubscription.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.expenses.payslipDeductions.unionSubscription.revised)}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Insurance</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.expenses.payslipDeductions.insurance.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.expenses.payslipDeductions.insurance.revised)}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">UIF</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.expenses.payslipDeductions.uif.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.expenses.payslipDeductions.uif.revised)}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Group Life</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.expenses.payslipDeductions.groupLife.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.expenses.payslipDeductions.groupLife.revised)}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Garnishees / Admin Order</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.expenses.payslipDeductions.garnisheeAdminOrder.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.expenses.payslipDeductions.garnisheeAdminOrder.revised)}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Funeral Policy</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.expenses.payslipDeductions.funeralPolicy.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.expenses.payslipDeductions.funeralPolicy.revised)}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Other</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.expenses.payslipDeductions.other.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.expenses.payslipDeductions.other.revised)}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Tax (SITE &amp; PAYE)</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.expenses.payslipDeductions.tax.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.expenses.payslipDeductions.tax.revised)}</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Nett Pay</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.income.nett.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.income.nett.revised)}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Other Income</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.income.otherIncome.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.income.otherIncome.revised)}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2"><strong>Total Income</strong></td>
                                            <td className="p-1 px-2">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.income.totalIncome.original)}</td>
                                            <td className="p-1 px-2">{formatCurrency(proposal.generalInfo.affordability.primaryCustomer?.income.totalIncome.revised)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div>
                                <strong>Secondary Customer</strong>
                                {proposal.generalInfo.affordability.secondaryCustomer != null &&
                                    <table className="table-auto mt-4 overflow-scroll">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Original</th>
                                                <th className="pl-1">Revised</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                <td className="p-1 px-2">Pension Fund</td>
                                                <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.secondaryCustomer?.expenses?.payslipDeductions?.pensionFund?.original)}</td>
                                                <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.secondaryCustomer?.expenses?.payslipDeductions?.pensionFund?.revised)}</td>
                                            </tr>
                                            <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                <td className="p-1 px-2">Medical Aid</td>
                                                <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.secondaryCustomer.expenses.payslipDeductions.medicalAid.original)}</td>
                                                <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.secondaryCustomer.expenses.payslipDeductions.medicalAid.revised)}</td>
                                            </tr>
                                            <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                <td className="p-1 px-2">Loans</td>
                                                <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.secondaryCustomer.expenses.payslipDeductions.loans.original)}</td>
                                                <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.secondaryCustomer.expenses.payslipDeductions.loans.revised)}</td>
                                            </tr>
                                            <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                <td className="p-1 px-2">Union Subscription</td>
                                                <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.secondaryCustomer.expenses.payslipDeductions.unionSubscription.original)}</td>
                                                <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.secondaryCustomer.expenses.payslipDeductions.unionSubscription.revised)}</td>
                                            </tr>
                                            <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                <td className="p-1 px-2">Insurance</td>
                                                <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.secondaryCustomer.expenses.payslipDeductions.insurance.original)}</td>
                                                <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.secondaryCustomer.expenses.payslipDeductions.insurance.revised)}</td>
                                            </tr>
                                            <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                <td className="p-1 px-2">UIF</td>
                                                <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.secondaryCustomer.expenses.payslipDeductions.uif.original)}</td>
                                                <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.secondaryCustomer.expenses.payslipDeductions.uif.revised)}</td>
                                            </tr>
                                            <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                <td className="p-1 px-2">Group Life</td>
                                                <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.secondaryCustomer.expenses.payslipDeductions.groupLife.original)}</td>
                                                <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.secondaryCustomer.expenses.payslipDeductions.groupLife.revised)}</td>
                                            </tr>
                                            <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                <td className="p-1 px-2">Garnishees / Admin Order</td>
                                                <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.secondaryCustomer.expenses.payslipDeductions.garnisheeAdminOrder.original)}</td>
                                                <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.secondaryCustomer.expenses.payslipDeductions.garnisheeAdminOrder.revised)}</td>
                                            </tr>
                                            <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                <td className="p-1 px-2">Funeral Policy</td>
                                                <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.secondaryCustomer.expenses.payslipDeductions.funeralPolicy.original)}</td>
                                                <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.secondaryCustomer.expenses.payslipDeductions.funeralPolicy.revised)}</td>
                                            </tr>
                                            <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                <td className="p-1 px-2">Other</td>
                                                <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.secondaryCustomer.expenses.payslipDeductions.other.original)}</td>
                                                <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.secondaryCustomer.expenses.payslipDeductions.other.revised)}</td>
                                            </tr>
                                            <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                <td className="p-1 px-2">Tax(SITE &amp; PAYE)</td>
                                                <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.secondaryCustomer.expenses.payslipDeductions.tax.original)}</td>
                                                <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.secondaryCustomer.expenses.payslipDeductions.tax.revised)}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                <td className="p-1 px-2">Nett Pay</td>
                                                <td className="p-1 px-2 text-right">{proposal.generalInfo.affordability.secondaryCustomer.income.nett.original}</td>
                                                <td className="p-1 px-2 text-right">{proposal.generalInfo.affordability.secondaryCustomer.income.nett.revised}</td>
                                            </tr>
                                            <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                <td className="p-1 px-2">Other Income</td>
                                                <td className="p-1 px-2 text-right">{proposal.generalInfo.affordability.secondaryCustomer.income.otherIncome.original}</td>
                                                <td className="p-1 px-2 text-right">{proposal.generalInfo.affordability.secondaryCustomer.income.otherIncome.revised}</td>
                                            </tr>
                                            <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                <td className="p-1 px-2"><strong>Total Income</strong></td>
                                                <td className="p-1 px-2 text-right">{proposal.generalInfo.affordability.secondaryCustomer.income.totalIncome.original}</td>
                                                <td className="p-1 px-2 text-right">{proposal.generalInfo.affordability.secondaryCustomer.income.totalIncome.revised}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                }

                            </div>

                            <div>
                                <strong>Summary</strong>
                                <table className="table-auto mt-4 overflow-scroll">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th className="pl-1">Amount</th>
                                            <th className="pl-1">Percentage of Gross</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Gross Income:</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(totalGross)}</td>
                                            <td className="p-1 px-2 text-right">100.00 %</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Statutory Deductions:</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.assessmentSummary.statutoryDeductions)}</td>
                                            <td className="p-1 px-2 text-right">{percentageOfTotalIncome(proposal.generalInfo.affordability.assessmentSummary.statutoryDeductions)} %</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Employer Deductions:</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.assessmentSummary.employerDeductions)}</td>
                                            <td className="p-1 px-2 text-right">{percentageOfTotalIncome(proposal.generalInfo.affordability.assessmentSummary.employerDeductions)} %</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2"><strong>Nett Income:</strong></td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(nettIncome)}</td>
                                            <td className="p-1 px-2 text-right">{percentageOfTotalIncome(nettIncome)} %</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Other Income:</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(otherIncome)}</td>
                                            <td className="p-1 px-2 text-right">{percentageOfTotalIncome(otherIncome)} %</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2"><strong>Total Disposable Income:</strong></td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(nettIncome + otherIncome)}</td>
                                            <td className="p-1 px-2 text-right">{percentageOfTotalIncome(nettIncome + otherIncome)} %</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Financial Services:</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability?.financialServiceExpenses?.total.original)}</td>
                                            <td className="p-1 px-2 text-right">{percentageOfTotalIncome(proposal.generalInfo.affordability?.financialServiceExpenses?.total.original)} %</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2"><strong>Available Amount:</strong></td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.assessmentSummary.availableAmount)}</td>
                                            <td className="p-1 px-2 text-right">{percentageOfTotalIncome(proposal.generalInfo.affordability.assessmentSummary.availableAmount)} %</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Contingency:</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.assessmentSummary.contingency)}</td>
                                            <td className="p-1 px-2 text-right">{percentageOfTotalIncome(proposal.generalInfo.affordability.assessmentSummary.contingency)} %</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2"><strong>Gross Collectable:</strong></td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.assessmentSummary.grossCollectable)}</td>
                                            <td className="p-1 px-2 text-right">{percentageOfTotalIncome(proposal.generalInfo.affordability.assessmentSummary.grossCollectable)} %</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">DC Aftercare Costs:</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.feesInfo.afterCareFeePerMonth1 + proposal.generalInfo.feesInfo.afterCareFeePerMonth2)}</td>
                                            <td className="p-1 px-2 text-right">{percentageOfTotalIncome(proposal.generalInfo.feesInfo.afterCareFeePerMonth1 + proposal.generalInfo.feesInfo.afterCareFeePerMonth2)} %</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">PDA Fees:</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.assessmentSummary.pdaFees)}</td>
                                            <td className="p-1 px-2 text-right">{percentageOfTotalIncome(proposal.generalInfo.affordability.assessmentSummary.pdaFees)} %</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2"><strong>Gross Debt Affordability:</strong></td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.assessmentSummary.grossDebtAffordability)}</td>
                                            <td className="p-1 px-2 text-right">{percentageOfTotalIncome(proposal.generalInfo.affordability.assessmentSummary.grossDebtAffordability)} %</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Credit Agreement Linked Insurances:</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.assessmentSummary.creditAgreementInsurance)}</td>
                                            <td className="p-1 px-2 text-right">{percentageOfTotalIncome(proposal.generalInfo.affordability.assessmentSummary.creditAgreementInsurance)} %</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2"><strong>Nett Debt Affordability Amount:</strong></td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.assessmentSummary.affordabilityAmount)}</td>
                                            <td className="p-1 px-2 text-right">{percentageOfTotalIncome(proposal.generalInfo.affordability.assessmentSummary.affordabilityAmount)} %</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="grid sm:grid-cols-1 xl:grid-cols-3 p-2">
                        <div className="xl:col-span-2">
                            <div className="p-2">
                                <h1 className="small_basa_heading">
                                    <span className="block text-basa_blue-dark lg:inline">Expenditure</span>
                                </h1>
                            </div>
                            <div className="p-2">
                                <table className="table-auto mt-4">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Original</th>
                                            <th>Revised</th>
                                            <th></th>
                                            <th>Original</th>
                                            <th>Revised</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Domestic</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability?.householdExpenses?.domestic.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability?.householdExpenses?.domestic.revised)}</td>
                                            <td className="p-1 px-2">Education</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability?.householdExpenses?.educationalFees.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability?.householdExpenses?.educationalFees.revised)}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Entertainment</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability?.householdExpenses?.entertainment.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability?.householdExpenses?.entertainment.revised)}</td>
                                            <td className="p-1 px-2">Family Expenses</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability?.householdExpenses?.familyExpenses.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability?.householdExpenses?.familyExpenses.revised)}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Groceries</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability?.householdExpenses?.groceries.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability?.householdExpenses?.groceries.revised)}</td>
                                            <td className="p-1 px-2">Home Ownership Costs/Rent</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability?.householdExpenses?.mortgageRent.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability?.householdExpenses?.mortgageRent.revised)}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Petrol / Travel</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability?.householdExpenses?.petrolTravel.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability?.householdExpenses?.petrolTravel.revised)}</td>
                                            <td className="p-1 px-2">Security</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability?.householdExpenses?.security.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability?.householdExpenses?.security.revised)}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Telephone</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability?.householdExpenses?.telephone.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability?.householdExpenses?.telephone.revised)}</td>
                                            <td className="p-1 px-2">Water &amp; Electricity</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability?.householdExpenses?.waterElectricity.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability?.householdExpenses?.waterElectricity.revised)}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Other</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability?.householdExpenses?.other.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability?.householdExpenses?.other.revised)}</td>
                                            <td className="p-1 px-2"></td>
                                            <td className="p-1 px-2 text-right"></td>
                                            <td className="p-1 px-2 text-right"></td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2"></td>
                                            <td className="p-1 px-2 text-right"></td>
                                            <td className="p-1 px-2 text-right"></td>
                                            <td className="p-1 px-2"><strong>Total Household</strong></td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability?.householdExpenses?.total.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability?.householdExpenses?.total.revised)}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div>
                            <div className="p-2">
                                <h1 className="small_basa_heading">
                                    <span className="block text-basa_blue-dark lg:inline">Financial Services</span>
                                </h1>
                            </div>
                            <div className="p-2">
                                <table className="table-auto mt-4 overflow-scroll">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th className="pl-1">Original</th>
                                            <th className="pl-1">Revised</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Assurance:</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.financialServiceExpenses?.assurance.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.financialServiceExpenses?.assurance.revised)}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Insurance:</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.financialServiceExpenses?.insurance?.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.financialServiceExpenses?.insurance?.revised)}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Medical Expenses:</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.financialServiceExpenses?.medicalExpense.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.financialServiceExpenses?.medicalExpense.revised)}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Credit Life Insurance:</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.financialServiceExpenses?.creditInsurance?.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.financialServiceExpenses?.creditInsurance?.revised)}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Pension &amp; Risk Benefits:</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.financialServiceExpenses?.pension.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.financialServiceExpenses?.pension.revised)}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Other Financial Services</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.financialServiceExpenses?.other.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.financialServiceExpenses?.other.revised)}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2"><strong>Total</strong></td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.financialServiceExpenses?.total.original)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.affordability.financialServiceExpenses?.total.revised)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-2">
                        <div>
                            <div className="p-2">
                                <h1 className="small_basa_heading">
                                    <span className="block text-basa_blue-dark lg:inline">Once Off Fees</span>
                                </h1>
                            </div>
                            <div className="p-2">
                                <table className="table-auto mt-4 overflow-scroll">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th className="pl-1">Paid</th>
                                            <th className="pl-1">Unpaid</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Debt Counsellor Fees:</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.feesInfo.upfrontDcFeesAlreadyPaid)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.feesInfo.upfrontDcFeesNotYetPaid)}</td>
                                        </tr>
                                        <tr className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                            <td className="p-1 px-2">Legal Fees:</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.feesInfo.upfrontLegalFeePaid)}</td>
                                            <td className="p-1 px-2 text-right">{formatCurrency(proposal.generalInfo.feesInfo.upfrontLegalFeeNotYetPaid)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div>
                            <div className="p-2">
                                <h1 className="small_basa_heading">
                                    <span className="block text-basa_blue-dark lg:inline">Assets</span>
                                </h1>
                            </div>
                            <div className="p-2">
                                <table className="table-auto mt-4 overflow-scroll">
                                    <thead>
                                        <tr>
                                            <th >Asset Category</th>
                                            <th className="pl-1">Asset Description</th>
                                            <th className="pl-1">Status</th>
                                            <th className="pl-1">Nominal Value</th>
                                            <th className="pl-1">Credit Provider</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    
                                        {proposal.generalInfo.assets !=null && proposal.generalInfo.assets.asset != null && proposal.generalInfo.assets.asset.map((x, i) =>
                                            <tr key={`asset_${i}`} className="border-gray-300 text-basa_gray hover:text-basa_blue-darker hover:bg-basa_gray-light">
                                                <td className="p-1 px-2 text-right">{x.encumbered}</td>
                                                <td className="p-1 px-2">{x.description}</td>
                                                <td className="p-1 px-2">{x.status}</td>
                                                <td className="p-1 px-2 text-right">{formatCurrency(x.nominalValue)}</td>
                                                <td className="p-1 px-2">{x.creditProvider}</td>
                                            </tr>
                                        )
                                        }
                                    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="p-2">
                        <h1 className="small_basa_heading">
                            <span className="block text-basa_blue-dark lg:inline">Actions</span>
                        </h1>
                        <div className="pt-1 flex">
                            <div className="flex my-2">
                                <Link to={`/dcrs/${id}/restructuring_proposal`} className="basa_button">Restructuring Proposal</Link>
                            </div>
                            <div className="flex my-2">
                                <Link to={`/dcrs/${id}/payment_plan`} className="basa_button">Payment Plan</Link>
                            </div>
                            <div className="flex my-2">
                                <span onClick={() => downloadSummary(proposal, id)} className="basa_button">
                                    Download
                                </span>
                            </div>
                            <div className="flex my-2">
                                <Link to={`/dcrs/${id}`} className="basa_button">
                                    Back
                                </Link>
                            </div>

                        </div>
                    </div>
                </div>
            }
        </>
    )
}

function downloadSummary(proposal, id) {
    toggleSpinner();
    fileService.fetchAssessmentSummaryFile(id)
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `assessment_summary_${proposal.session.uniqueReference}.pdf`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
            toggleSpinner();
        });
}

export { AssessmentSummary };