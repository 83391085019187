import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { canViewReports } from '../helpers/reportsHelper';

import {
  Link
} from "react-router-dom";

import dcrs from '../img/logos.jpg';
import manual from '../img/20220810_DCRSMOD_UserInterface_Manual_00_09.pdf';
import { userService } from '../services/userService';
import '../style.css'

function Nav(props) {

  var authentication = props.authentication;
  var setAuthentication = props.setAuthentication;

  const history = useHistory();

  function toggleBurgerMenu(e) {
    document.getElementById("mobile-menu").classList.toggle('hidden');
  }

  function logout(e) {
    e.preventDefault();
    setAuthentication({});
    userService.logout();
    history.push('/');
  }
  
  return (
    <div className="z-50 w-full">
      <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
        <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start" aria-label="Global">
          <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
            <div className="flex items-center justify-between w-full lg:w-auto">
              <a href="/">
                <img className="h-10 lg:h-6 w-auto max-h-96" src={dcrs}></img>
              </a>
              <div className="-mr-2 flex items-center lg:hidden">
                <button type="button" onClick={toggleBurgerMenu} className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500" aria-expanded="false">
                  <span className="sr-only">Open main menu</span>
                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="hidden lg:block lg:ml-10 lg:pr-4 lg:space-x-8 z-50">
            <Link to="/" className="z-50 font-medium text-basa_gray hover:text-basa_blue">Home</Link>
            <Link to="/about" className="z-50 font-medium text-basa_gray hover:text-basa_blue">About</Link>
            <a href={manual} target="_blank" className="z-50 font-medium text-basa_gray hover:text-basa_blue">Help</a>
            
            {authentication.authenticated != "true" &&
              <Link to="/login" className="font-medium text-basa_gray hover:text-basa_blue">Log in</Link>
            }
            {authentication.authenticated == "true" && authentication.permissions.includes('DCRS.Proposal') &&
              <Link to="/dcrs" className="font-medium text-basa_gray hover:text-basa_blue">DCRS Case Management</Link>
            }
            {authentication.authenticated == "true" && (authentication.permissions.includes('Settings') || authentication.permissions.includes('User.Manage')) &&
              <Link to="/setup" className="font-medium text-basa_gray hover:text-basa_blue">Setup</Link>
            }
            {authentication.authenticated == "true" && canViewReports(authentication.permissions) &&
              <Link to="/reports/usageReports" className="font-medium text-basa_gray hover:text-basa_blue">Reports</Link>
            }
            {authentication.authenticated == "true" && authentication.permissions.includes('Tools.Manage') &&
              <Link to="/tools/simulator" className="font-medium text-basa_gray hover:text-basa_blue">Tools</Link>
            }
            {authentication.authenticated == "true" &&
              <Link to="/home" className="font-medium text-basa_gray hover:text-basa_blue" onClick={logout} >Logout</Link>
            }
          </div>
        </nav>
      </div>
      {/* Mobile */}
      <div className="z-50 absolute top-0 inset-x-0 p-2 transition transform origin-top-right hidden" id="mobile-menu">
        <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="px-5 pt-4 flex items-center justify-between">
            <div>
              <img className="h-16 w-auto" src={dcrs} alt=""></img>
            </div>
            <div className="-mr-2">
              <button type="button" onClick={toggleBurgerMenu} className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500">
                <span className="sr-only">Close main menu</span>
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
          <div className="px-2 pt-2 pb-3 space-y-1">
            <Link to="/" onClick={toggleBurgerMenu} className="block px-2 py-1 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Home</Link>
            <Link to="/about" onClick={toggleBurgerMenu} className="block px-2 py-1 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">About</Link>

            {authentication.authenticated == "true" && authentication.permissions.includes('DCRS.Proposal') &&
              <Link to="/dcrs" className="block px-2 py-1 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">DCRS Case Management</Link>
            }
            {authentication.authenticated == "true" && authentication.permissions.includes('Settings') &&
              <Link to="/setup" className="block px-2 py-1 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Setup</Link>
            }
            {authentication.authenticated == "true" && authentication.permissions.includes('Tools.Manage') &&
              <Link to="/tools" className="block px-2 py-1 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Tools</Link>
            }

            {authentication.authenticated == "true" &&  
              <Link to="/home" className="block px-2 py-1 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50" onClick={logout} >Logout</Link>
            }

          </div>
          <Link to="/login" onClick={toggleBurgerMenu} className="block w-full px-5 py-3 text-center font-medium text-orange-600 bg-gray-50 hover:bg-gray-100">
            Log in
          </Link>
        </div>
      </div>
    </div>
  );
}

export { Nav };
