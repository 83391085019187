import { apiUrl } from '../helpers/urlHelper' ;
import { userService } from './userService';

export const reportService = {
    applicationsAtAccountLevel,
    dcrsApplications,
    dcSystemVendor,
    usageReportingStats,
    extractRequestsMostRecentProposals,
    downloadExtractMostRecentProposals,
    requestExtractMostRecentProposals,
    extractRequestsCPInvoicing,
    downloadExtractCPInvoicing,
    requestExtractCPInvoicing,
    requestExtractCPInvoicingEnhanced
};

function applicationsAtAccountLevel(startDate, endDate, handleError) {
    const headers = userService.getAuthHeader();
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(`${apiUrl()}/api/applicationsAtAccountLevelReports/search/findByCreateMonthBetween?startDate=${startDate}&endDate=${endDate}&sort=createMonth`, requestOptions)
    .then(response => response.json())
    .catch(error => handleError("Error", error));
}

function dcrsApplications(startDate, endDate, handleError) {
    const headers = userService.getAuthHeader();
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(`${apiUrl()}/api/dCRSApplicationsReports/search/findByCreateMonthBetween?startDate=${startDate}&endDate=${endDate}&sort=createMonth`, requestOptions)
    .then(response => response.json())
    .catch(error => handleError("Error", error));
}

function dcSystemVendor(startDate, endDate, handleError) {
    const headers = userService.getAuthHeader();
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(`${apiUrl()}/api/dCSystemVendorReports/search/findByCreateMonthBetween?startDate=${startDate}&endDate=${endDate}&sort=createMonth`, requestOptions)
    .then(response => response.json())
    .catch(error => handleError("Error", error));
}

function usageReportingStats(startDate, endDate, handleError) {
    const headers = userService.getAuthHeader();
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(`${apiUrl()}/api/usageReportingStatsReports/search/findByMiMonthBetween?startDate=${startDate}&endDate=${endDate}&sort=miMonth`, requestOptions)
    .then(response => response.json())
    .catch(error => handleError("Error", error));
}

function extractRequestsMostRecentProposals(handleError) {
    const headers = userService.getAuthHeader();
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(`${apiUrl()}/api/extractRequests/search/findByRequestTypeAndExtractResultIsNotNull?requestType=MostRecentProposal`, requestOptions)
    .then(response => response.json())
    .catch(error => {
        alert("Error");
        console.error(error);
    });
}

function downloadExtractMostRecentProposals(id, handleError) {
    const headers = userService.getAuthHeader();
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(`${apiUrl()}/api/extractRequest/mostRecentProposals/${id}`, requestOptions)
    .catch(error => {
        alert("Error");
        console.error(error);
    });
}


function requestExtractMostRecentProposals(month, handleError) {
    const headers = userService.getAuthHeader();
    headers['Content-Type'] = 'application/json'

    const requestOptions = {
        method: 'POST',
        headers: headers,
    }

    var result = fetch(`${apiUrl()}/api/extractRequest/mostRecentProposals/${month}-01`, requestOptions)
        .then(response => response.json())
        .catch(error => handleError("Error", error));

    return result;
}

function extractRequestsCPInvoicing(handleError) {
    const headers = userService.getAuthHeader();
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(`${apiUrl()}/api/extractRequests/search/findByRequestTypeAndExtractResultIsNotNull?requestType=CPInvoicing`, requestOptions)
    .then(response => response.json())
    .catch(error => {
        alert("Error");
        console.error(error);
    });
}

function downloadExtractCPInvoicing(id, handleError) {
    const headers = userService.getAuthHeader();
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(`${apiUrl()}/api/extractRequest/mostRecentProposals/${id}`, requestOptions)
    .catch(error => {
        alert("Error");
        console.error(error);
    });
}

function requestExtractCPInvoicing(handleError) {
    const headers = userService.getAuthHeader();
    headers['Content-Type'] = 'application/json'

    const requestOptions = {
        method: 'POST',
        headers: headers,
    }

    var result = fetch(`${apiUrl()}/api/extractRequest/cpInvoicing`, requestOptions)
        .then(response => response.json())
        .catch(error => handleError("Error", error));

    return result;
}

function requestExtractCPInvoicingEnhanced(handleError) {
    const headers = userService.getAuthHeader();
    headers['Content-Type'] = 'application/json'

    const requestOptions = {
        method: 'POST',
        headers: headers,
    }

    var result = fetch(`${apiUrl()}/api/extractRequest/cpInvoicingEnhanced`, requestOptions)
        .then(response => response.json())
        .catch(error => handleError("Error", error));

    return result;
}